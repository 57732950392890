import React, { useMemo } from 'react';
import { SingleTabSubViewJson } from '../../../utils/constants/ReportTabsType';
import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { PLOT_TITLES } from '../../../utils/constants/ReportTabs';
import TitleWithHorizontalDivider from '../../atom/TitleWithHorizontalDivider/TitleWithHorizontalDivider';

/**
 *
 * @param {React.PropsWithChildren<ISubViewBarStacked>} props
 * @return {JSX.Element}
 * @constructor
 */
const SubViewBarStacked: React.FC<ISubViewBarStacked> = ({data, targetGroupName, pythonElementName}) => {

  const labels = useMemo(() => {
    if (data.data.length === 0) return [];
    const dataKeys = Object.keys(data.data[0]);
    return dataKeys
      .filter(item => !["Target name", "Type"].includes(item))  // extract only values
  }, [data.data]);

  const datasets = useMemo(() => {
    if (data.data.length === 0) return [];
    // check if the dataset has a line with key "Target name". If yes, we can filter rows to take only `targetGroupName`
    // selected values, otherwise do not apply the filter
    let linesToTake = data.data
      .filter(line => line["Target name"] === targetGroupName);
    if (linesToTake.length === 0) linesToTake = data.data;
    let datasets = [];
    for (let r of linesToTake) {
      let datasetName = "";
      let datasetValues = [];
      for (let key in r) {
        const value = r[key];
        if (key === "Type") datasetName = value as string;
        else if (key === "Target name") continue;
        else datasetValues.push(value)
      }
      let color = data.label_metadata[datasetName]?.color;
      if (!color) {
        color = `#${Math.floor(Math.random()*0xFFFFFF).toString(16).padStart(6, '0')}`;
        console.error(`I've picked up the color cause no color were set for ${datasetName} inside ${targetGroupName}`);
      }
      datasets.push({
        label: datasetName,
        data: datasetValues,
        backgroundColor: color
      })
    }
    return datasets;
  }, [data.data, targetGroupName, labels]);


  // Format your data to match the structure expected by Chart.js
  const chartData = {
    labels: labels, // assuming this is an array of dates
    datasets: datasets // assuming this is an array of datasets
  };

  return (
    <Box>
      {
        PLOT_TITLES[pythonElementName] && PLOT_TITLES[pythonElementName].title ?
          <Box mb={1}>
            <TitleWithHorizontalDivider name={PLOT_TITLES[pythonElementName].title as string}/>
          </Box> : ""
      }
      <Bar data={chartData}
            options={{
              responsive: true,
              interaction: {
                mode: 'index',
                intersect: false,
              },
              scales: {
                x: {
                  stacked: true,
                  title: {
                    display: !!PLOT_TITLES[pythonElementName].axis_x,
                    text: PLOT_TITLES[pythonElementName].axis_x as string,
                    font: {
                      size: 16
                    }
                  }
                },
                y: {
                  stacked: true,
                  title: {
                    display: !!PLOT_TITLES[pythonElementName].axis_y,
                    text: PLOT_TITLES[pythonElementName].axis_y as string,
                    font: {
                      size: 16
                    }
                  }
                },
              },
              plugins: {
                legend: {
                  position: 'right',
                  reverse: true,
                  maxWidth: 290,
                  labels: {
                    font: {
                      size: 14,
                    },
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    padding: 10,
                    boxWidth: 15,

                  },
                },
                zoom: {
                  zoom: {
                    wheel: {
                      enabled: true,
                    },
                    pinch: {
                      enabled: true,
                    },
                    mode: "x"
                  },
                },
              },
            }}
      />
    </Box>
  );
};

export interface ISubViewBarStacked {
  data: SingleTabSubViewJson;
  pythonElementName: string;  // represent the python_element name that identifies uniquely a plot/table among all dashboards - like in element_config.json
  targetGroupName?: string | undefined;
}

export default SubViewBarStacked;
