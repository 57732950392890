import React, { useCallback, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ControlPointOutlined } from '@mui/icons-material';
import CommonPageBaseLayout from '../../organism/Common.PageBaseLayout/Common.PageBaseLayout';
import UserManagementTeamUserTable from '../../organism/UserManagement.TeamUserTable/UserManagement.TeamUserTable';
import UserManagementAddUserDialog from '../../organism/UserManagement.AddUserDialog/UserManagement.AddUserDialog';
import { useLazyQuery } from '@apollo/client';
import { TeamQueries } from '../../../utils/graphql/queries/team';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../context/AuthContext';

/**
 *
 * @param {React.PropsWithChildren<IUserManagement>} props
 * @return {JSX.Element}
 * @constructor
 */
const UserManagement: React.FC<IUserManagement> = (props) => {
  const [openAddUserDialog, setOpenAddUserDialog] = useState<boolean>(false);
  const { authToken } = useAuthContext();
  const { idTeam } = useParams();

  // reload the user once we add a new user and close the dialog, or when we delete an existing user
  const [getUserLazy] = useLazyQuery(TeamQueries.GET_TEAM_USERS);

  // close the add user dialog and reload user list
  const closeAddUserDialog = useCallback(async () => {
    setOpenAddUserDialog(false);
    await getUserLazy({
      variables: {
        idTeam: idTeam as string
      },
      fetchPolicy: 'network-only',
      context: generateClientContextWithHeaders(authToken)
    });
  }, [getUserLazy, setOpenAddUserDialog, idTeam, authToken]);

  return (
    <CommonPageBaseLayout>
      <Typography variant={"h1"} fontWeight={600}>
        Users management
      </Typography>
      <Typography variant={"subtitle1"} mt={1}>
        See the list of users. You can add a new user through the <strong>Add User</strong> button or delete a user by selecting the <strong>Delete</strong> button in the table on the corresponding row.
      </Typography>

      {/* row of filter / new report button*/}
      <Box display={"flex"} justifyContent={"flex-end"} mt={3}>
        <Button variant={"contained"}
                onClick={() => setOpenAddUserDialog(true)}
                startIcon={<ControlPointOutlined/>}
        >
          Add User
        </Button>
      </Box>

      {/* Table section */}
      <Box mt={4}>
        <UserManagementTeamUserTable/>
      </Box>

      {/*  Dialog to add a new user */}
      <UserManagementAddUserDialog open={openAddUserDialog} onClose={closeAddUserDialog}/>
    </CommonPageBaseLayout>
  );
};

export interface IUserManagement {

}

export default UserManagement;
