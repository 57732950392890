import React, { useMemo } from 'react';
import { SingleTabSubViewJson } from '../../../utils/constants/ReportTabsType';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { PLOT_TITLES } from '../../../utils/constants/ReportTabs';
import TitleWithHorizontalDivider from '../../atom/TitleWithHorizontalDivider/TitleWithHorizontalDivider';

/**
 * Renders a SubView of type Table
 * @param {React.PropsWithChildren<ISubViewTable>} props
 * @return {JSX.Element}
 * @constructor
 */
const SubViewTable: React.FC<ISubViewTable> = (props) => {

  const headers = useMemo(() => {
    if (props.data.data.length === 0) return [];
    else return Object.keys(props.data.data[0]);
  }, [props.data]);

  return (
    <Box>
      {
        PLOT_TITLES[props.pythonElementName] && PLOT_TITLES[props.pythonElementName].title ?
          <Box mb={1}>
            <TitleWithHorizontalDivider name={PLOT_TITLES[props.pythonElementName].title as string}/>
          </Box> : ""
      }
      <TableContainer>
        <Table size="small" sx={{mb:'10px'}} >
          <TableHead style={{backgroundColor: '#ffffff', height: '45px', borderBottom: '2px solid #ffffff' }}>
            <TableRow>
              {
                headers.map( (h, pos) =>
                  <TableCell key={h}
                             sx={{whiteSpace: "nowrap"}}
                             align={pos === 0 ? "left" : 'right'}>
                    <strong>{h}</strong>
                  </TableCell>
                )
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              // filter the data based on the `targetGroupName` passed. If it's not passed, we show all the rows (except the A3+ default target - never shown)
              props.data.data
                .filter(r => props.targetGroupName ? r['Target name'] === props.targetGroupName : true)
                .map((dataRow, posRow) => {
                  return (
                    <TableRow sx={{  borderTop: '2px solid #f2f2f2', borderBottom: '2px solid #f2f2f2', backgroundColor: "#f2f2f2" }} key={posRow}>
                      {
                        Object.keys(dataRow).map( (k, posCol) => (
                          <TableCell key={posCol}
                                     sx={{
                                       whiteSpace: "nowrap",
                                       backgroundColor: posRow % 2 === 1 ? "white" : "#f2f2f2"
                                     }}
                                     align={posCol === 0 ? "left" : 'right'}>
                            {dataRow[k]}
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  );
                })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export interface ISubViewTable {
  data: SingleTabSubViewJson;
  pythonElementName: string;  // represent the python_element name that identifies uniquely a plot/table among all dashboards - like in element_config.json
  targetGroupName?: string | undefined;
}

export default SubViewTable;
