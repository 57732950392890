import React from 'react';
import { Report } from '../../../utils/graphql/generatedTS/graphql';
import SingleRowReportPreview from '../Dashboard.ReportTable/SingleRowReportPreview';
import { Box } from '@mui/material';

/**
 *
 * @param {React.PropsWithChildren<IReportTabInfo>} props
 * @return {JSX.Element}
 * @constructor
 */
const ReportTabInfo: React.FC<IReportTabInfo> = (props) => {
  return (
    <Box>
      <SingleRowReportPreview report={props.report}/>
    </Box>
  );
};

export interface IReportTabInfo {
  report: Report
}

export default ReportTabInfo;
