import React from 'react';
import {Box, Button, Grid, Typography} from '@mui/material';
import HomeLoginBoard from '../../organism/Home.LoginBoard/Home.LoginBoard';
import {useLocation, useNavigate} from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import HomeResetPasswordRequest from '../../organism/Home.ResetPasswordReuest/HomeResetPasswordRequest';
import HomeChangePassword from '../../organism/Home.ChangePassword/Home.ChangePassword';

/**
 *
 * @param {React.PropsWithChildren<IHome>} props
 * @return {JSX.Element}
 * @constructor
 */
const Home: React.FC<IHome> = (props) => {
  const location = useLocation();

  /* Only for dev purposes - uncomment when need to edit the change password window*/
  /*const navigate = useNavigate(); // Initialize navigate function

    // Function to handle button click
    const handleChangePasswordClick = () => {
        navigate(RouteKey.ChangePassword); // Navigate to ChangePassword page
    };*/

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                backgroundImage: "url(/imgs/spoton_gradient_background.svg)",
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',

            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '80%',
                    marginRight: '5%'
                }}
            >
                {location.pathname === RouteKey.Home && <HomeLoginBoard />}
                {location.pathname === RouteKey.ResetPasswordRequest.toLowerCase() && <HomeResetPasswordRequest />}
                {location.pathname === RouteKey.ChangePassword.toLowerCase() && <HomeChangePassword />}

                {/*<Button
                    variant="contained"
                    sx={{
                        marginTop: '20px',
                        borderRadius: '20px',
                        fontWeight: 'bold',
                        width: '100%' // Full width button, can adjust as needed
                    }}
                    onClick={handleChangePasswordClick} // Set click handler
                >
                    Go to Change Password
                </Button>*/}

            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    component="img"
                    alt={"spoton-logo"}
                    src="/imgs/spoton_full_logo.svg"
                    sx={{
                        width: '100%', // Responsive width
                        marginRight: '15%',
                        //maxWidth: '950px', // Max width for large screens
                    }}
                />
                <Typography
                    variant={"h1"}
                    sx={{
                        marginTop: '20px',
                        color: 'white',
                        fontSize: '14px',
                        position: 'absolute',
                        bottom: '20px',
                        /*textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline',
                        },*/
                    }}

                    /*Uncomment this once we want to link to our website*/
                    /* component="a"
                    href="https://www.databreeders.com/"
                    target="_blank"
                    rel="noopener noreferrer"*/
                >
                    Powered by dataBreeders
                </Typography>
            </Box>
        </Box>
    );
};

export interface IHome {

}

export default Home;
