import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box, Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { TeamUser } from '../../../utils/graphql/generatedTS/graphql';
import { useQuery } from '@apollo/client';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import SingleUserRow from './SingleUserRow';
import { TeamQueries } from '../../../utils/graphql/queries/team';
import {FileDownloadOutlined} from "@mui/icons-material";
import GradientCircularProgress from "../Dashboard.ReportTable/GradientCircularProgress";

/**
 *
 * @param {React.PropsWithChildren<IUserManagementTeamUserTable>} props
 * @return {JSX.Element}
 * @constructor
 */
const UserManagementTeamUserTable: React.FC<IUserManagementTeamUserTable> = (props) => {

  const { authenticatedIdUser, authToken } = useAuthContext();
  const [retrieveError, setRetrieveError] = useState<string>('');
  const { idTeam } = useParams();

  const { data: usersList, loading, error } = useQuery(TeamQueries.GET_TEAM_USERS, {
    variables: {
      idTeam: idTeam as string
    },
    fetchPolicy: 'network-only',
    context: generateClientContextWithHeaders(authToken),
    skip: !authenticatedIdUser
  });

  // set the text of the error returned by backend
  useEffect(() => {
    if (!!error?.message) setRetrieveError(error?.message);
  }, [error]);

  const userListSorted: TeamUser[] = useMemo(() => {
    if (!usersList?.getTeamUsers) return [];
    let teamUsers: TeamUser[] = [...usersList.getTeamUsers] as TeamUser[];
    return teamUsers.sort((a, b) => (a?.lastName >= b?.lastName) ? 1 : -1);
  }, [usersList?.getTeamUsers]);


  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };
  const handleExportCSV = () => {
    if (!usersList?.getTeamUsers) return;

    const csvContent = [
      ['Name', 'Surname', 'Email', 'Creation Date', 'Role'],
      ...usersList.getTeamUsers.map((user: any) => [
        user.firstName,
        user.lastName,
        user.email,
        formatDate(user.addedAt),
        user.role
      ])
    ]
        .map(row => row.map(field => `"${field}"`).join(','))
        .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'users_list.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-start" >
        <Button
            variant="outlined"
            sx={{mb:5, mt:-8, borderRadius: '20px'}}
            onClick={handleExportCSV}
            startIcon={<FileDownloadOutlined />}
        >
          Export users list
        </Button>
      </Box>
      {
        loading ?
          <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"} marginTop={4}>
            <GradientCircularProgress/>
          </Box>
          :
          !!error ?
            <Box mt={2}><Alert severity="error">{retrieveError}</Alert></Box>
            :
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Surname</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Creation date</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userListSorted.map((user) => (
                    <SingleUserRow key={user?.idUser} user={user as TeamUser}/>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
      }
    </>
  );
};

export interface IUserManagementTeamUserTable {

}

export default UserManagementTeamUserTable;
