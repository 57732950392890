import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import SingleRow from './SingleRow';
import { Report } from '../../../utils/graphql/generatedTS/graphql';
import { useParams } from 'react-router-dom';
import { useGetDashboardReportList } from '../../../hook/useGetDashboardReportList';
import { NavigateBeforeRounded, NavigateNextRounded } from '@mui/icons-material';
import GradientCircularProgress from "./GradientCircularProgress";

/**
 *
 * @param {React.PropsWithChildren<IDashboardReportTable>} props
 * @return {JSX.Element}
 * @constructor
 */
const DashboardReportTable: React.FC<IDashboardReportTable> = (props) => {

  const [retrieveError, setRetrieveError] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const { idTeam } = useParams();
  const { reportList,
    loading,
    error,
    loadNext,
    loadPrevious,
    canLoadPrevious,
    canLoadNext
  } = useGetDashboardReportList({
    idTeam: idTeam
  });

  // set the text of the error returned by backend
  useEffect(() => {
    if (!!error?.message) setRetrieveError(error?.message);
  }, [error]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      {
        loading ?
          <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"} marginTop={4}>
            <GradientCircularProgress/>
          </Box>
          :
          !!error ?
            <Box mt={2}><Alert severity="error">{retrieveError}</Alert></Box>
            :
            <>
              <TableContainer >
                <Table aria-label="collapsible table">
                  <TableHead style={{backgroundColor: '#eaeaea', height: '50px', borderBottom: '6px solid #f8f9fa' }}>
                    <TableRow >
                      <TableCell />
                      <TableCell style={{fontSize: '14px', fontWeight: 'bold', color: '#324158'}}>Campaign's Name</TableCell>
                      <TableCell style={{fontSize: '14px', fontWeight: 'bold', color: '#324158' }}>Last Run Date</TableCell>
                      <TableCell style={{fontSize: '14px', fontWeight: 'bold', color: '#324158' }}>User</TableCell>
                      <TableCell style={{fontSize: '14px', fontWeight: 'bold', color: '#324158' }}>Status</TableCell>
                      <TableCell style={{fontSize: '14px', fontWeight: 'bold', color: '#324158' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportList?.map((report) => (
                      <SingleRow key={report?.idReport} report={report as Report}/>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} mt={4} pr={2}>
                <Button variant={"contained"}
                        size={"small"}
                        startIcon={<NavigateBeforeRounded/>}
                        onClick={loadPrevious}
                        disabled={!canLoadPrevious}
                >

                </Button>
                <Button variant={"contained"}
                        size={"small"}
                        endIcon={<NavigateNextRounded/>}
                        sx={{ml: 1}}
                        onClick={loadNext}
                        disabled={!canLoadNext}
                >

                </Button>
              </Box>
            </>
      }
    </>
  );
};

export interface IDashboardReportTable {

}

export default DashboardReportTable;

