import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { UserMutations } from '../../../utils/graphql/mutations/user';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useNavigate } from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import { Box, Button, TextField, Typography } from '@mui/material';
import { StringParam, useQueryParam } from 'use-query-params';
import { useDebounce } from 'react-use';

/**
 *
 * @param {React.PropsWithChildren<IHomeChangePassword>} props
 * @return {JSX.Element}
 * @constructor
 */
const HomeChangePassword: React.FC<IHomeChangePassword> = (props) => {
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const [recoveryToken, setRecoveryToken] = useQueryParam('tokenRecovery', StringParam);
  const navigate = useNavigate();

  const [] = useDebounce(
    () => {
      if (password && passwordConfirm) {
        setPasswordError(password !== passwordConfirm ? "Passwords do not match" : "");
      } else {
        setPasswordError("");
      }
    },
    500,
    [passwordConfirm]
  );

  const [addUserPasswordMutation, {loading, error}] = useMutation(UserMutations.ADD_USER_PASSWORD, {
    context: generateClientContextWithHeaders(),
  })

  const setNewPassword = async () => {
    if (password && passwordConfirm && password === passwordConfirm && typeof recoveryToken === "string") {
      await addUserPasswordMutation({
        variables: {
          addUserPasswordInput: {
            password: password,
            tokenRecovery: recoveryToken,
          }
        }
      });
      enqueueSnackbar("Password reset!", {variant: 'success'});
      navigate(RouteKey.Home);
    }
  };

  return (
    <Box width={"80%"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Typography variant={"h1"} color={"white"}>
        Change password
      </Typography>
      <Typography variant={"h1"} fontSize={15} color={"white"} mt={1}>
        Please set a new password.
      </Typography>

      <TextField label="New Password"
                 variant="outlined"
                 size={"small"}
                 type={"password"}
                 value={password}
                 onChange={(e) => setPassword(e.target.value)}
                 sx={{
                     minWidth: 295,
                     mt: 4,
                     input: { color: 'white' },
                     label: { color: 'white' },
                     '& .MuiInputLabel-root': {
                         color: 'white',
                         '&.Mui-focused': {
                             color: 'white',
                         },
                     },
                     '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                             borderColor: 'white',
                             borderRadius: '10px',
                         },
                         '&:hover fieldset': {
                             borderColor: 'white',
                         },
                         '&.Mui-focused fieldset': {
                             borderColor: 'white',
                         },
                     }
                 }}
      />

      <TextField label="Confirm Password"
                 variant="outlined"
                 size={"small"}
                 type={"password"}
                 value={passwordConfirm}
                 error={!!passwordError}
                 helperText={passwordError}
                 onChange={(e) => setPasswordConfirm(e.target.value)}
                 sx={{
                     minWidth: 295,
                     mt: 2,
                     input: { color: 'white' },
                     label: { color: 'white' },
                     '& .MuiInputLabel-root': {
                         color: 'white',
                         '&.Mui-focused': {
                             color: 'white',
                         },
                     },
                     '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                             borderColor: 'white',
                             borderRadius: '10px',
                         },
                         '&:hover fieldset': {
                             borderColor: 'white',
                         },
                         '&.Mui-focused fieldset': {
                             borderColor: 'white',
                         },
                     }
                 }}
      />

        <Button variant={"contained"}
                sx={{width: 295, mt: 4, backgroundColor: 'white', color: '#8A5FFD',
                    '&:hover': {
                        backgroundColor: '#5EE0E6',
                        color: 'white'
                    },
                }}
                style={{borderRadius:"20px", fontWeight:"bold"}} onClick={setNewPassword}>
        Change Password
      </Button>

    </Box>
  );
};

export interface IHomeChangePassword {

}

export default HomeChangePassword;
