import { Box, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { TeamUser } from '../../../utils/graphql/generatedTS/graphql';
import { AccountCircle, CheckRounded } from '@mui/icons-material';
import { useHoverDirty } from 'react-use';

/**
 *
 * @param {React.PropsWithChildren<IShareReportDialogSingleUser>} props
 * @return {JSX.Element}
 * @constructor
 */
const ShareReportDialogSingleUser: React.FC<IShareReportDialogSingleUser> = (props) => {
  const ref = React.useRef(null);
  const isHovering = useHoverDirty(ref);

  /**
   * Decide if the user should be selected or not
   */
  const selected = useMemo(() => props.selected || props.isReportCreator,
    [props.selected, props.isReportCreator]);

  /**
   * Do nothing if the clicked item is the report reactor row, otherwise trigger the onClick function
   */
  const onClick = useCallback(() => {
    if (props.isReportCreator) return;
    else props.onClick();
  }, [props.isReportCreator, props.onClick]);

  return (
    <Box display={"flex"} paddingY={1} paddingX={1} ref={ref}
         alignItems={"center"}
         onClick={onClick}
         sx={{
           cursor: props.isReportCreator ? undefined : "pointer",
           backgroundColor: isHovering && !props.isReportCreator ? "#f2f2f2" : undefined,
           borderRadius: 2,
           opacity: props.isReportCreator ? 0.6 : 1
         }}>
      <AccountCircle color={"secondary"} sx={{fontSize: 24}}/>
      <Typography variant={"body1"} ml={1} fontWeight={selected ? "bold" : undefined}>
        {props.user.firstName} {props.user.lastName}
      </Typography>
      {
        selected ?
          <CheckRounded color={"success"} sx={{fontSize: 18, ml: 0.5}}/> : ""
      }
    </Box>
  );
};

export interface IShareReportDialogSingleUser {
  user: TeamUser;
  onClick: () => void;
  selected?: boolean;
  isReportCreator: boolean; // true if the user is also the creator of the report
}

export default ShareReportDialogSingleUser;
