import { Box, Button, Dialog, DialogContent, DialogContentText, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Delete, QueryBuilderRounded } from '@mui/icons-material';
import { AddReportInput } from '../../../utils/graphql/generatedTS/graphql';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { theme } from '../../../GlobalStyles';
import { useNewReportContext } from '../../../context/NewReportContext';
import { format, parse } from 'date-fns';

/**
 *
 * @param {React.PropsWithChildren<IScheduleReportDialog>} props
 * @return {JSX.Element}
 * @constructor
 */
const ScheduleReportDialog: React.FC<IScheduleReportDialog> = (props) => {
  const [repeatUntilDate, setRepeatUntilDate] = useState<Date | null>(null);
  const [runEverydayAt, setRunEverydayAt] = useState<Date | null>(null);
  const newReport = useNewReportContext();

  useEffect(() => {
    // TODO - check if the edit works
    if (newReport.newReportInput?.schedule) {
      setRepeatUntilDate(parse(newReport.newReportInput.schedule.runEverydayUntil, 'yyyy-MM-dd', new Date()));
      setRunEverydayAt(parse(newReport.newReportInput.schedule.atHour, 'HH:mm', new Date()));
    }
  }, [newReport]);

  const schedule = useCallback(() => {
    if (!repeatUntilDate || !runEverydayAt) return;
    newReport.setSchedule({
      runEverydayUntil: format(repeatUntilDate, 'yyyy-MM-dd'),
      atHour: format(runEverydayAt, 'HH:mm'),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    props.onClose();
  }, [repeatUntilDate, runEverydayAt, newReport, props.onClose]);

  const removeSchedule = useCallback(() => {
    setRepeatUntilDate(null);
    setRunEverydayAt(null);
    newReport.setSchedule(undefined);
    props.onClose();
  }, [newReport, props.onClose]);

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={"xs"} fullWidth>
      <DialogContent>
        <DialogContentText>
          You are scheduling the report to be run each day until the End Date
        </DialogContentText>

        <Typography variant={"body1"} sx={{mt: 2, mb: 0.5}}>
          Run everyday until
        </Typography>
        <DatePicker format={"dd/MM/yyyy"}
                    onChange={(value: Date | null) => setRepeatUntilDate(value)}
                    value={repeatUntilDate}
                    minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                    slotProps={{
                      textField: {
                        size: "small",
                        sx: {width: 200},
                      }
                    }}
        />

        <Typography variant={"body1"} sx={{mt: 2, mb: 0.5}}>
          Run everyday at
        </Typography>
        <TimePicker format={"HH:mm"}
                    ampm={false}
                    onChange={(value: Date | null) => setRunEverydayAt(value)}
                    value={runEverydayAt}
                    slotProps={{
                      textField: {
                        size: "small",
                        sx: {width: 200},
                      }
                    }}
        />

        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} mt={3}>
          <Button startIcon={<QueryBuilderRounded/>}
                  variant={"contained"}
                  disabled={!runEverydayAt || !repeatUntilDate}
                  onClick={schedule}
                  style={{ borderRadius: '10px', fontWeight: 'bold', fontSize: '14px'}}
                  sx={{color: theme.palette.text.primary, backgroundColor: "#FFC107", ':hover': {backgroundColor: "#FFC107"}}}>
            Schedule
          </Button>
          <Button startIcon={<Delete/>}
                  variant={"contained"}
                  onClick={removeSchedule}
                  color={"error"}
                  sx={{ borderRadius: '10px', fontWeight: 'bold', fontSize: '14px', marginLeft: 2}}>
            Remove
          </Button>
        </Box>

      </DialogContent>

    </Dialog>
  );
};

export interface IScheduleReportDialog {
  report: AddReportInput;
  open: boolean;
  onClose: () => void;
}

export default ScheduleReportDialog;
