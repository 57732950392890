import React from 'react';
import { Report } from '../../../utils/graphql/generatedTS/graphql';

import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import SingleRowReportPreview from "../Dashboard.ReportTable/SingleRowReportPreview";
import ReportTabInfo, {IReportTabInfo} from "../Report.TabInfo/Report.TabInfo";

/**
 *
 * @param {React.PropsWithChildren<IReportTabInfo>} props
 * @return {JSX.Element}
 * @constructor
 */

const data = [
    { type: 'Contacts', definition: 'The number of individuals watching an advertisment for its entire duration' },
    { type: 'TV', definition: 'Ads on linear TV watched on TV sets, either live or recorded and played back' },
    { type: 'Streaming Services', definition: 'VOD viewed on broadcaster players (and potentially other streaming services) plus linear channels streamed on devices other than TV sets' },
    { type: 'Online Video', definition: 'Video ads streamed alongside video content viewed on websites' },
    { type: 'TRP', definition: 'TRP (Target Rating Point) The number of contacts in a given target group diveded by the size of the target group multiplied by 100' },
    { type: '30sec equivalent', definition: 'For campaigns that include spot length that are longer or less than 30 seconds the report is recalculated to the equivalent of 30 sec spots' },
];

const ReportTabGlossary: React.FC<IReportTabGlossary> = (props) => {
    return (
        <>
            <Typography variant="h4" marginBottom={2}>Definitions</Typography>
            <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead sx={{ backgroundColor: '#ffffff', }}>
                        <TableRow>
                            <TableCell>
                                <Typography fontWeight="bold">Type</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography fontWeight="bold">Definition</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'inherit',
                                }}
                            >
                                <TableCell sx={{fontWeight:"bold"}}>{row.type}</TableCell>
                                <TableCell>{row.definition}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </>
    );
};

export interface IReportTabGlossary {
    report: Report
}

export default ReportTabGlossary;