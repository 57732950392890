import { useQuery } from '@apollo/client';
import { ReportQueries } from '../utils/graphql/queries/report';
import { generateClientContextWithHeaders } from '../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../context/AuthContext';
import { useReportFileCacheContext } from '../context/ReportFileCacheContext';
import { useEffect, useMemo } from 'react';
import { SingleTabSubViewJson } from '../utils/constants/ReportTabsType';
import axios from 'axios';

export type UseGetPostprocessDataType = {
  idPostProcess: string;
  idReport: string;
}

export const useGetPostprocessData = ({idPostProcess, idReport}: UseGetPostprocessDataType): SingleTabSubViewJson | undefined => {
  const {authToken} = useAuthContext();
  const {setSingleFileCache, cachedContent} = useReportFileCacheContext();

  const {data: fileUrlQuery} = useQuery(ReportQueries.REPORT_POSTPROCESS_FILE, {
    variables: {
      idReport,
      idPostProcess: idPostProcess + ".json",
    },
    fetchPolicy: 'network-only',
    context: generateClientContextWithHeaders(authToken),
    skip: !authToken || !idReport || !idPostProcess || !!cachedContent[idPostProcess],
  });

  const fileUrl: string | undefined = useMemo(() => {
    return fileUrlQuery?.getReportPostprocessFile;
  }, [fileUrlQuery]);

  useEffect(() => {
    if (fileUrl && !cachedContent[idPostProcess]) {
      axios.get(fileUrl)
        .then(response => {
          setSingleFileCache(idPostProcess, response.data as SingleTabSubViewJson);
        })
        .catch(error => {
          setSingleFileCache(idPostProcess, {
            data: [{Total: 0, Type: "File Not Found"}],
            label_metadata: { },
            zoom: null
          });
          console.error('Error fetching data: ', error);
        });
    }
  }, [fileUrl]);

  return cachedContent[idPostProcess] as SingleTabSubViewJson;
}
