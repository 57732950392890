import { styled } from '@mui/material/styles';
import TabList from '@mui/lab/TabList';

const CustomTabList = styled(TabList)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
        height: 3,
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(to right, #8A5FFD, #5EE0E6)',
            borderRadius: '20px',
        },
    },
}));

export default CustomTabList;