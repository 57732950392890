import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
import { RouteKey } from './App.Routes';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import { Box, CircularProgress } from '@mui/material';
import GradientCircularProgress from "./ui/organism/Dashboard.ReportTable/GradientCircularProgress";

const FullPageLoading: React.FC = () =>
  (
    <Box width={"100vw"} height={"100vh"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
      <GradientCircularProgress/>
    </Box>
  );

/**
 * Wraps pages that require authentication. If shows a looper while the authentication is
 * loaded from browser storage, or a newer token is exchagned with backend. If then the user results not
 * authenticated, it redirects him back to the home page
 * @param {React.PropsWithChildren<IAppProtectedRoute>} props
 * @return {JSX.Element}
 * @constructor
 */
const AppWithAuth: React.FC<IAppProtectedRoute> = (props) => {
  const { isAuthenticated, authStatusLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  // if not authenticated, returns the user to the auth page
  useEffect(() => {
    if (!authStatusLoading && !isAuthenticated) {
      navigate(RouteKey.Home);
    }
  }, [authStatusLoading, isAuthenticated]);

  // logic to render a looper or the final component while the auth is being loaded in memory
  const component = useMemo(() => {
    if (authStatusLoading) return <FullPageLoading/>;
    else {
      if (isAuthenticated) return props.children;
      else return <FullPageLoading/>;
    }
  }, [authStatusLoading, isAuthenticated, props.children]);

  return <>{component}</>;
};

export interface IAppProtectedRoute {
  children: ReactElement
}

export default AppWithAuth;
