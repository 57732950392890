import React, { useState } from 'react';
import { TabStructure } from '../../../utils/constants/ReportTabsType';
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select, styled, Switch,
    Typography
} from '@mui/material';
import { Metric1, Metric2, METRIC_TO_NAME, TabName } from '../../../utils/constants/ReportTabs';
import SubView from './SubView';
import { Target } from '../../../utils/graphql/generatedTS/graphql';
import ReportTargetUniverse from '../Report.TargetUniverse/Report.TargetUniverse';
import { useTheme } from '@mui/material/styles';
import {SwitchTextTrack} from "../../../mui-treasury/switch-lovely";

/**
 *
 * @param {React.PropsWithChildren<IReportSingleTabStructure>} props
 * @return {JSX.Element}
 * @constructor
 */
const ReportSingleTabStructure: React.FC<IReportSingleTabStructure> = ({idReport, reportTarget, tabStructure}) => {
  const [selectedMetric1, setSelectedMetric1] = useState<Metric1>(tabStructure.metric1[0]);
  const [selectedMetric2, setSelectedMetric2] = useState<Metric2 | undefined>(tabStructure.metric2.length>0 ? tabStructure.metric2[0] : undefined);
  const [targetGroupPos, setTargetGroupPos] = useState<number | undefined>(0);
  const theme = useTheme();

    const RoundedSelect = styled(Select)(({ theme }) => ({
        borderRadius: '10px',
        '& .MuiSelect-select': {
            borderRadius: '10px',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px',
        },
    }));

  return (
    <Box>

      {/* Row to select values*/}
      <Box display={"flex"}
           alignItems={"center"}
           paddingX={2}
           paddingY={2}
           sx={{
             border: "1px #dfe2e6 solid",
               borderRadius: '10px'
           }}
      >
        <Typography variant={"subtitle2"}>
          VIEW
        </Typography>
        <ButtonGroup size="small" sx={{ml: 2}}>
          {
            tabStructure.metric1.map(m =>
              <Button key={m}
                      onClick={() => setSelectedMetric1(m)}
                      sx={{textTransform: "none", borderRadius: '20px'}}
                      variant={m === selectedMetric1 ? "contained" : "outlined"}>
                {METRIC_TO_NAME[tabStructure.name]?.[m] ? METRIC_TO_NAME[tabStructure.name]?.[m] : m}
              </Button>
            )
          }
        </ButtonGroup>

          {/* show selection of second metric */}
          {
              tabStructure.metric2.length > 0 ?
                  <Box ml={2} display="flex" alignItems="center">
                      <Divider orientation="vertical" variant="middle" flexItem />
                      <Box ml={2} display="flex" alignItems="center">
                          {
                              tabStructure.metric2.map(m =>
                                      m === Metric2._30Eq && (
                                          <FormControlLabel
                                              key={m}
                                              control={
                                                  <SwitchTextTrack
                                                      checked={selectedMetric2 === m}
                                                      onChange={() => setSelectedMetric2(selectedMetric2 === m ? Metric2.Raw : m)}
                                                      name="30eq-switch"
                                                  />
                                              }
                                              label={METRIC_TO_NAME[tabStructure.name]?.[m] || m}
                                              sx={{ml:'-8px',
                                                  '& .MuiFormControlLabel-label': {
                                                      color: selectedMetric2 === m ? theme.palette.primary.main : 'inherit',
                                                      fontWeight: selectedMetric2 === m ? 'bold' : 'normal'
                                                  }

                                              }}
                                          />
                                      )
                              )
                          }
                      </Box>
                  </Box>
                  : ""
          }

        {/* show menu to select target */}
        {
          reportTarget && reportTarget.length > 0 && tabStructure.name !== TabName.TabContacts ?
            <Box display={"flex"} alignItems={"center"} ml={4} >
              <Typography variant="body1" fontWeight={"bold"}>Target Group</Typography>
              <FormControl size={"small"} sx={{ml: 1}}>
                <Select
                  size={"small"}
                  value={targetGroupPos}
                  onChange={(e) => {setTargetGroupPos(e.target.value as number)}}
                  sx={{
                      borderRadius: '10px',
                      '& .MuiSelect-select': {
                          borderRadius: '10px',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '10px',
                      },
                  }}
                >
                  {
                    reportTarget.map( (t, pos) => (
                      <MenuItem key={t.targetName} value={pos} selected={targetGroupPos === pos}>{t.targetName}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Box>
            :
            ""
        }

        {/* show "Target Universe" in Summary tab */}
        {
          tabStructure.name === TabName.TabSummary ?
            <Box display="flex" alignItems="center" ml={4} >
              <Typography variant="body1"
                          fontWeight={"bold"}
              >
                Target Universe
              </Typography>
              <Box ml={2}
                   sx={{
                       '& .MuiOutlinedInput-root': {
                           borderRadius: '10px',
                       },
                       '& .MuiOutlinedInput-notchedOutline': {
                           borderRadius: '10px',
                       },
                       '&:hover .MuiOutlinedInput-notchedOutline': {
                           borderRadius: '10px',
                       },
                       '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                           borderRadius: '10px',
                       },
                       border: '0px solid',
                       borderColor: 'lightgray'
                   }}
              >
                <ReportTargetUniverse idReport={idReport}
                                      target={targetGroupPos!==undefined ? reportTarget?.[targetGroupPos]?.targetName : undefined}/>
              </Box>
            </Box>
            :
            ""
        }



      </Box>

      {
        tabStructure.subViews.map(subView =>
          <SubView subView={subView}
                   idReport={idReport}
                   key={subView.aggregation + subView.kpi + subView.type + subView.plotType}
                   tabName={tabStructure.name}
                   aggregation={subView.aggregation}
                   targetGroupName={
                     targetGroupPos!==undefined && tabStructure.name !== TabName.TabContacts
                       ? reportTarget?.[targetGroupPos]?.targetName
                       : undefined
                   }
                   metric1={selectedMetric1} metric2={selectedMetric2}/>
        )
      }
    </Box>
  );
};

export interface IReportSingleTabStructure {
  idReport: string;
  reportTarget: Target[] | undefined;
  tabStructure: TabStructure
}

export default ReportSingleTabStructure;
