import React from 'react';
import { CircularProgress, Box } from '@mui/material';

interface GradientCircularProgressProps {
    size?: number;
    backgroundThickness?: number;
    foregroundThickness?: number;
    backgroundColor?: string;
}

const GradientCircularProgress: React.FC<GradientCircularProgressProps> = ({
                                                                               size = 26,
                                                                               backgroundThickness = 4,
                                                                               foregroundThickness = 7,
                                                                               backgroundColor = '#e0e0e0', // Light grey background
                                                                           }) => {
    return (
        <Box position="relative" display="inline-flex">
            <svg width={0} height={0} style={{ position: 'absolute' }}>
                <defs>
                    <linearGradient id="spoton_gradient_background" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#8A5FFD" />
                        <stop offset="100%" stopColor="#5EE0E6" />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress
                variant="determinate"
                value={100}
                size={size}
                thickness={backgroundThickness}
                sx={{
                    color: backgroundColor,
                    position: 'absolute',
                    marginLeft: '10px',
                    marginTop: '5px',
                }}
            />
            <CircularProgress
                size={size}
                thickness={foregroundThickness}
                sx={{
                    'svg circle': {
                        stroke: 'url(#spoton_gradient_background)',
                        strokeLinecap: 'round', // This rounds the ends of the progress bar
                    },
                    marginLeft: '10px',
                    marginTop: '5px',
                    animationDuration: '6s'
                }}
            />
        </Box>
    );
};

export default GradientCircularProgress;