import React, { useCallback, useEffect, useState } from 'react';
import { Spot } from '../../../utils/graphql/generatedTS/graphql';
import {Alert, Avatar, Box, Button, FormControlLabel, FormGroup, IconButton, Switch, TextField, Tooltip} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parse } from 'date-fns';
import { useDebounce } from 'react-use';
import {Delete, InfoOutlined} from '@mui/icons-material';
import MandatoryAsterisk from '../../atom/MandatoryAsterisk/MandatoryAsterisk';
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {SwitchTextTrack} from "../../../mui-treasury/switch-lovely";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


/**
 *
 * @param {React.PropsWithChildren<ISpotRow>} props
 * @return {JSX.Element}
 * @constructor
 */
const SpotRow: React.FC<ISpotRow> = (props) => {

  const [startDateError, setStartDateError] = useState<string>("");
  const [endDateError, setEndDateError] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [idError, setIdError] = useState<string>("");
  const [] = useDebounce(
    () => {
      if (!!props.spot.id && (!/^[sS][gG]\d{1,10}$/.test(props.spot.id))) setIdError("Invalid Spotgate Code");
    },
    500,
    [props.spot.id]
  );

  // set the code
  const setCode = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    props.setSpot({
      ...props.spot,
      id: e.target.value.trim(),
    });
    setIdError("");
  }, [props.spot, props.setSpot]);

  // set the start date
  const setStartDate = useCallback((value: Date | null) => {
    if (!value) return;
    const date = format(value, "yyyy-MM-dd");
    const newSpot = {
      ...props.spot,
      periodStart: date
    };
    if (props.repeatDates) props.setSpotWithRepeatDate(newSpot);
    else props.setSpot(newSpot);
  }, [props.spot, props.setSpot, props.repeatDates, props.setSpotWithRepeatDate]);

  // set the end date
  const setEndDate = useCallback((value: Date | null) => {
    if (!value) return;
    const date = format(value, "yyyy-MM-dd");
    const newSpot = {
      ...props.spot,
      periodEnd: date
    };
    if (props.repeatDates) props.setSpotWithRepeatDate(newSpot);
    else props.setSpot(newSpot);
  }, [props.spot, props.setSpot, props.repeatDates, props.setSpotWithRepeatDate]);

  const calculateDaysDifference = (startDate: Date, endDate: Date): number => {
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
    return Math.ceil(timeDifference / (1000 * 3600 * 24));
  };

  const handleClickOpen = () => {
    function setOpen(b: boolean) {

    }

    setOpen(true);
  };

  const handleClose = () => {
    setShowAlert(false); // Chiudi la Dialog impostando showAlert su false
  };

  // listed for periodStart change and shows error if the date is invalid
  useEffect(() => {
    if (!!props.spot.periodStart && !!props.spot.periodEnd) {
      const dateStart = parse(props.spot.periodStart, 'yyyy-MM-dd', new Date());
      const dateEnd = parse(props.spot.periodEnd, 'yyyy-MM-dd', new Date());
      if (dateStart > dateEnd) {
        setStartDateError("Must be before End date");
        return;
      }
    }
    setStartDateError("");
    setEndDateError("");
  }, [props.spot.periodStart]);

  // listed for periodEnd change and shows error if the date is invalid
  useEffect(() => {
    if (!!props.spot.periodStart && !!props.spot.periodEnd) {
      const dateStart = parse(props.spot.periodStart, 'yyyy-MM-dd', new Date());
      const dateEnd = parse(props.spot.periodEnd, 'yyyy-MM-dd', new Date());
      if (dateStart > dateEnd) {
        setEndDateError("Must be after Start date");
        return;
      }

      const daysDifference = calculateDaysDifference(dateStart, dateEnd);
      if (daysDifference > 60) {
        setShowAlert(true);
        return;
      }
    }
    setStartDateError("");
    setEndDateError("");
  }, [props.spot.periodEnd, props.spot.periodEnd]);


  return (
    <Box display={"flex"} alignItems={"center"} paddingY={2}>
      <Avatar sx={{ width: 24, height: 24, fontSize: 14, backgroundColor: "#212529", fontWeight: "bold" }}>{props.position+1}</Avatar>
      <TextField label={!!props.spot.id ? <span>Spotgate Code<MandatoryAsterisk/></span> : ""}
                 placeholder={"Type Spotgate Code es: sg1234"}
                 variant="outlined"
                 size={"small"}
                 type={"text"}
                 value={props.spot.id}
                 onChange={setCode}
                 sx={{ml: 2, '& .MuiOutlinedInput-root': {'& fieldset': {borderRadius: '10px',}}}}
                 error={!!idError}
                 helperText={idError}
      />
      <DatePicker format={"dd/MM/yyyy"}
                  onChange={setStartDate}
                  //disabled={props.position > 0 && props.repeatDates}
                  value={!!props.spot.periodStart ? parse(props.spot.periodStart, 'yyyy-MM-dd', new Date()) : null}
                  slotProps={{
                    textField: {
                      size: "small",
                      sx: {width: 200, ml: 2, '& .MuiOutlinedInput-root': {'& fieldset': {borderRadius: '10px',}}},
                      label: !!props.spot.periodStart ? <span>Start Date<MandatoryAsterisk/></span> : "",
                      error: !!startDateError,
                      helperText: startDateError,
                    }
                  }}
      />
      <DatePicker format={"dd/MM/yyyy"}
                  onChange={setEndDate}
                  //disabled={props.position > 0 && props.repeatDates}
                  value={!!props.spot.periodEnd ? parse(props.spot.periodEnd, 'yyyy-MM-dd', new Date()) : null}
                  slotProps={{
                    textField: {
                      size: "small",
                      sx: {width: 200, ml: 2, '& .MuiOutlinedInput-root': {'& fieldset': {borderRadius: '10px',}}},
                      label: !!props.spot.periodEnd ? <span>End Date<MandatoryAsterisk/></span> : "",
                      error: !!endDateError,
                      helperText: endDateError,
                    }
                  }}
      />

      <Dialog
          open={showAlert}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Alert severity={"info"}><b>Period exceeds 60 days.</b> <br/> Running can take longer than 3 minutes  </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center', marginTop: -15 }}>
          <Button
              variant={"outlined"}
              onClick={handleClose}
              style={{ color: '#455a64', borderRadius:15, borderColor: '#455a64'}}>
            Ok, got it!
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete row only if it's not the first row - otherwise buttons to replicate same date */}
      {
        props.position > 0 ?
          <Box ml={2}>
            <IconButton color={"error"} onClick={props.deleteSpot}>
              <Delete/>
            </IconButton>
          </Box>
          :
          <Box display={"flex"} flexDirection={"column"} ml={4} alignSelf={"center"}>
            <FormGroup>
              <FormControlLabel control={<SwitchTextTrack size={"small"}
                                                 checked={props.drilldownBySpotgate}
                                                 onChange={() => props.setDrilldownBySpotgate(!props.drilldownBySpotgate)} />}
                                label={
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>Drill down by spotgate</span>
                                    <Tooltip
                                        title="In case of multi spotgate selection, the excel file contains also the figures split by each spotgate code"
                                        placement="top">
                                      <IconButton size="small" style={{ marginLeft: '4px', padding: '2px' }}>
                                        <InfoOutlinedIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                }
                                />
              <FormControlLabel control={<SwitchTextTrack size={"small"}
                                                 checked={props.repeatDates}
                                                 onChange={() => props.toggleRepeatDate()} />}
                                label="Repeat dates" />
            </FormGroup>
          </Box>
      }
    </Box>
  );
};

export interface ISpotRow {
  position: number;
  spot: Spot;
  setSpot: (spot: Spot) => void;
  setSpotWithRepeatDate: (spot: Spot) => void;
  deleteSpot: () => void;
  repeatDates: boolean;
  drilldownBySpotgate: boolean | undefined;
  setDrilldownBySpotgate: (value: boolean) => void;
  toggleRepeatDate: () => void;
}

export default SpotRow;
