import React from 'react';
import { ReportStatus } from '../../../utils/graphql/generatedTS/graphql';
import {Chip, CircularProgress} from '@mui/material';
import GradientCircularProgress from "./GradientCircularProgress";

/**
 *
 * @param {React.PropsWithChildren<ISingleRowStatusChip>} props
 * @return {JSX.Element}
 * @constructor
 */
const SingleRowStatusChip: React.FC<ISingleRowStatusChip> = (props) => {
  switch (props.status) {
    case ReportStatus.Created:
    case ReportStatus.EngineDownloadData:
    case ReportStatus.EnginePending:
    case ReportStatus.EngineSucceeded:
    case ReportStatus.PostprocessPending:
      if (props.isScheduled)
        return <Chip size={'small'} label="Scheduled" color={'warning'} sx={{fontSize: 12}} />
      //else return <Chip size={'small'} label="In progress" color={'primary'} sx={{fontSize: 12, color: 'white'}} />
      else return <GradientCircularProgress/>
    case ReportStatus.EngineFailed:
      return <Chip size={'small'} label="Failed" color={'error'} sx={{fontSize: 12}} />
    case ReportStatus.PostprocessFailed:
      return <Chip size={'small'} label="Postprocess failed" color={'error'} sx={{fontSize: 12}} />
    case ReportStatus.Succeeded:
      return <Chip size={'small'} label="Done" color={'success'} sx={{fontSize: 12}} />
  }
};

export interface ISingleRowStatusChip {
  status: ReportStatus,
  isScheduled?: boolean,
}

export default SingleRowStatusChip;
