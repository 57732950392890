import {Info, Visibility, VisibilityOff} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UserMutations } from '../../../utils/graphql/mutations/user';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import CommonSelectTeam from '../Common.SelectTeam/Common.SelectTeam';
import { Link, useNavigate } from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import PrivacyPolicies from "./PrivacyPolicies";
//import _default from "chart.js/dist/plugins/plugin.tooltip";
//import backgroundColor = _default.defaults.backgroundColor;

/**
 *
 * @param {React.PropsWithChildren<IHomeLoginBoard>} props
 * @return {JSX.Element}
 * @constructor
 */
const HomeLoginBoard: React.FC<IHomeLoginBoard> = (props) => {

  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rememberUser, setRememberUser] = useState<boolean>(true);
  const [authenticationError, setAuthenticationError] = useState<string>('');
  const [showPickTeamDialog, setShowPickTeamDialog] = useState<boolean>(false);

  const { setToken, isAuthenticated, authStatusLoading, authenticatedIdUser } = useAuthContext();

  const [login, {loading, error}] = useMutation(UserMutations.LOGIN, {
    variables: {
      loginInput: {
        email,
        password,
      },
    },
    context: generateClientContextWithHeaders()
  });

  const [bannerVisible, setBannerVisible] = useState<boolean>(false);

    const handleBannerClick = () => {
        setBannerVisible(true);
    };

    const handleBannerDismiss = () => {
        setBannerVisible(false);
    };


  const doLogin = useCallback(async () => {
    let response;
    try {
      response = await login();
    } catch (e) {
      return;
    }
    if (setToken && response.data?.login) {
      // store the auth token
      await setToken(response.data?.login.jwtToken, response.data?.login.refreshToken,
        response.data?.login.user, response.data?.login.expiresIn, true);
      setShowPickTeamDialog(true);
    }
  }, [setToken]);

  // set a human readable message on auth error
  useEffect(() => {
    if (error?.message === "idUser doesn't exist" || error?.message === "Invalid username or password") {
        setAuthenticationError("Invalid credentials");
    }
    else if(error?.message === "email parameter is mandatory") {
        setAuthenticationError("Email is required");
    }
    else if (error?.message === "password parameter is mandatory") {
        setAuthenticationError("Password is required");
    }
  }, [error]);

  // reset the auth error when input happens on email or passwower
  useEffect(() => {
    setAuthenticationError("");
  }, [email, password]);

  // show / hide the dialog to pick user team
  useEffect(() => {
    if (isAuthenticated && !authStatusLoading) setShowPickTeamDialog(true);
    else setShowPickTeamDialog(false);
  }, [authStatusLoading, isAuthenticated]);

  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"} >
      <Typography variant={"h1"} color={"#FFFFFF"}>
        Log in to your account
      </Typography>
      <Typography variant={"h1"} fontSize={15} color={"#FFFFFF"} mt={1}>
        Welcome back! Please enter your details.
      </Typography>

      {/* Text field section*/}
      <TextField label="Email"
                 variant="outlined"
                 size={"small"}
                 type={"email"}
                 onChange={(e) => setEmail(e.target.value)}
                 onKeyDown={(e) => {
                     if (e.key === 'Enter') {
                         doLogin();
                     }
                 }}
                 sx={{
                     minWidth: 295,
                     mt: 4,
                     input: { color: 'white' },
                     label: { color: 'white' },
                     '& .MuiInputLabel-root': {
                         color: 'white',                             // Initial label color
                         '&.Mui-focused': {
                             color: 'white',                           // Label color when focused
                         },
                     },
                     '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                             borderColor: 'white',
                             borderRadius: '10px',
                         },
                         '&:hover fieldset': {
                             borderColor: 'white',
                         },
                         '&.Mui-focused fieldset': {
                             borderColor: 'white',
                         },
                     }
                 }}
      />
      <TextField label="Password"
                 variant="outlined"
                 size={"small"}
                 type={showPassword? "text" : "password"}
                 sx={{
                     minWidth: 295,
                     mt: 2,
                     input: { color: 'white' },
                     label: { color: 'white' },
                     '& .MuiInputLabel-root': {
                         color: 'white',
                         '&.Mui-focused': {
                             color: 'white',
                         },
                     },
                     '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                             borderColor: 'white',
                             borderRadius: '10px',
                         },
                         '&:hover fieldset': {
                             borderColor: 'white',
                         },
                         '&.Mui-focused fieldset': {
                             borderColor: 'white',
                         },
                     }
                 }}
                 value={password}
                 onChange={(e) => setPassword(e.target.value)}
                 InputProps={{
                   endAdornment: <InputAdornment position={"end"}><IconButton
                                   onClick={() =>{setShowPassword(!showPassword)}}
                                   onMouseDown={() =>{setShowPassword(!showPassword)}}
                                   edge="end"
                                   sx={{ color: 'white' }}
                   >
                                   { showPassword ? <VisibilityOff /> : <Visibility />}
                   </IconButton></InputAdornment>
                 }}
                 onKeyDown={(e) => {
                     if (e.key === 'Enter') {
                         doLogin();
                     }
                 }}
      />

      {
        !!authenticationError ?
          <Box mt={2}><Alert severity="error">{authenticationError}</Alert></Box> : ""
      }

      {/* Remember me and login buttons*/}
      <Box display={"flex"} alignItems={"center"} mt={2} justifyContent={"space-between"}>
        <FormControlLabel control={<Checkbox size={"small"}
                                             sx={{
                                                 padding: "8px",
                                                 color: "white",
                                                 '&.Mui-checked': {
                                                     color: 'white',
                                                 },
                                             }}
        />}
                          onMouseDown={() => setRememberUser(!rememberUser)}
                          disabled={loading}
                          checked={rememberUser}
                          label={
                              <Typography sx={{ color: 'white' }} variant={"h1"} fontSize={14}>
                                  Remember me
                              </Typography>
                          }
        />
          <Typography variant={"h1"} fontSize={15} sx={{ color: 'white', ml: 2 }}>
              <Link
                  to={RouteKey.ResetPasswordRequest}
                  style={{ color: 'white', textDecoration: 'none' }}
              >
                  Forgot password?
              </Link>
          </Typography>
      </Box>
      <Button variant={"contained"}
              sx={{width: 295, mt: 2, backgroundColor: 'white', color: '#8A5FFD',
                  '&:hover': {
                      backgroundColor: '#5EE0E6',
                      color: 'white'
                  },
              }}
              style={{borderRadius:"20px", fontWeight:"bold"}} onClick={doLogin} disabled={loading}>
        Sign in
      </Button>

      {/* Show the pick team dialog if the user is authenticated */}
      {
        isAuthenticated && authenticatedIdUser ?
          <CommonSelectTeam idUser={authenticatedIdUser} show={showPickTeamDialog} onClose={() => setShowPickTeamDialog(false)}/>
          :
          ""
      }

        <Mailto email="spotonvideo.support@databreeders.com" subject="Assistance Needed" body="Hello, I need assistance with my account">
            <Button variant={"text"}
                    sx={{width: 290, mt: 6, color: '#FFFFFF',
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                    startIcon={<Info/>}
                    style={{borderRadius:"10px", textTransform: 'lowercase'}}>
                Having troubles? Contact assistance
            </Button>
        </Mailto>
        <Typography variant={"h5"}
                    align={"center"}
                    fontSize={12}
                    sx={{color: '#FFFFFF'}}
        >
            spotonvideo.support@databreeders.com
        </Typography>

        <Typography variant={"subtitle1"}
                    mt={6} ml={5} mr={5}
                    maxWidth={'400px'}
                    align={"center"}
                    fontSize={12}
                    onClick={handleBannerClick}
                    style={{ textDecoration: 'underline', cursor: 'pointer', color: '#FFFFFF' }}>
            Personal data will be processed in compliance with EU Regulation 2016/679 “GDPR” and as provided
            in the privacy policy.
        </Typography>


        {bannerVisible && (
            <div
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999,
                    textAlign: 'center',
                    maxHeight: "80vh",
                    overflowY: "auto",
                    width: '80%',
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    padding: '16px',
                    borderRadius: '15px',
                }}
            >
                
                    <Box mt={2}>
                        <PrivacyPolicies onClose={() => handleBannerDismiss()} />
                    </Box>


            </div>
        )}

    </Box>
  );
};

export interface IHomeLoginBoard {

}

export default HomeLoginBoard;


const Mailto = ({ email, subject, body, children }: { email: string; subject?: string; body?: string; children: React.ReactNode }) => {
    return (
        <a href={`mailto:${email}?subject=${encodeURIComponent(subject ?? '')}&body=${encodeURIComponent(body ?? '')}`}>
            {children}
        </a>
    );
};

export { Mailto };

