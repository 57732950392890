import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ClearRounded, InputRounded } from '@mui/icons-material';
import { useMutation, useQuery } from '@apollo/client';
import { TeamQueries } from '../../../utils/graphql/queries/team';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import ShareReportDialogSingleUser from './ShareReportDialogSingleUser';
import { Report, TeamUser } from '../../../utils/graphql/generatedTS/graphql';
import { ReportMutations } from '../../../utils/graphql/mutations/report';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';

/**
 *
 * @param {React.PropsWithChildren<IShareReportDialog>} props
 * @return {JSX.Element}
 * @constructor
 */
const ShareReportDialog: React.FC<IShareReportDialog> = (props) => {
  const [filterValue, setFilterValue] = useState<string>("");
  // list of idUsers selected to share the report with
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const { authToken, authenticatedIdUser } = useAuthContext();
  const {idTeam} = useParams();
  const { enqueueSnackbar } = useSnackbar();

  // set the initial list of users that have already access to the report
  useEffect(() => {
    if (!props.report?.sharedWith) setSelectedUsers([]);
    else {
      const selectedUsers = props.report.sharedWith.
                                                  map(u => u?.idUser).
                                                  filter(u => u !== undefined) as string[];
      setSelectedUsers(selectedUsers);
    }
  }, [props.report]);

  // get the list of team members
  const {data: teamUsers, loading} = useQuery(TeamQueries.GET_TEAM_USERS, {
    variables: {
      idTeam: idTeam as string,
    },
    fetchPolicy: 'network-only',
    context: generateClientContextWithHeaders(authToken),
    skip: !authToken || !idTeam
  });

  // mutation to share the report
  const [shareReportMutation, {}] = useMutation(ReportMutations.SHARE_REPORT, {
    context: generateClientContextWithHeaders(authToken)
  });

  // mutation to remove the share of the report
  const [deleteShareReportMutation, {}] = useMutation(ReportMutations.DELETE_SHARE_REPORT, {
    context: generateClientContextWithHeaders(authToken)
  });

  // remove the current user from the list of users that have access
  const teamUsersWithoutCurrentUser = useMemo(() => {
    if (!teamUsers) return [];
    else return teamUsers.getTeamUsers
    // else return teamUsers.getTeamUsers?.filter(u => u?.idUser !== authenticatedIdUser)
  }, [teamUsers, authenticatedIdUser]);

  // list of users filtered by the search of the user
  const filteredUsers = useMemo(() => {
    if (!teamUsers?.getTeamUsers || !teamUsersWithoutCurrentUser) return [];
    const filterValueLower = filterValue.toLowerCase();
    return teamUsersWithoutCurrentUser
              .filter(u => u?.firstName.toLowerCase().includes(filterValueLower) || u?.lastName.toLowerCase().includes(filterValueLower))
  }, [teamUsers?.getTeamUsers, teamUsersWithoutCurrentUser, filterValue]);

  // adds/remove the user from the temporary list of users that have access to it
  const toggleUserShare = useCallback((idUser: string) => {
    let sharedWith = [...selectedUsers];
    if (sharedWith.includes(idUser)) sharedWith = sharedWith.filter(s => s !== idUser);
    else sharedWith = [...sharedWith, idUser];
    setSelectedUsers(sharedWith);
  }, [selectedUsers]);

  // enable the share button only if we have to remove or add users to the share
  const enableShareButton = useMemo(() => {
    // get the list of idUsers currently having access to the report
    const currentShares = props.report?.sharedWith?. map(u => u?.idUser). filter(u => u !== undefined) as string[];
    if (!currentShares) return false;
    // result contains any element that figures in currentShares or selectedUsers, but not in both at the same time
    // in this way we know which users needs to be added / removed
    const result = new Set(currentShares).size === new Set(selectedUsers).size && currentShares.every(val => selectedUsers.includes(val))
      ? []
      : Array.from(new Set([...currentShares, ...selectedUsers]));
    return result.length > 0;
  }, [props.report?.sharedWith, selectedUsers]);

  /**
   * Share the report, by adding and removing users selected
   */
  const shareReport = async () => {
    setUpdateInProgress(true);
    const currentShares = props.report?.sharedWith?. map(u => u?.idUser). filter(u => u !== undefined) as string[];
    const currentSharesSet = new Set(currentShares);
    const selectedUsersSet = new Set(selectedUsers);
    // find users to share with
    const toShare = selectedUsers.filter(s => !currentSharesSet.has(s) );
    const toRemove = currentShares.filter(s => !selectedUsersSet.has(s));
    // start by doing the shares
    for (let tos of toShare) {
      await shareReportMutation({
        variables: {
          idUser: tos,
          idReport: props.report.idReport,
        },
      });
    }
    // then remove the shares to remove
    for (let tor of toRemove) {
      await deleteShareReportMutation({
        variables: {
          idUser: tor,
          idReport: props.report.idReport,
        },
      });
    }
    setUpdateInProgress(false);
    enqueueSnackbar("Share Report updated correctly", {variant: 'success'});
    props.onClose();
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={"xs"} fullWidth>
      <DialogContent>
        <Box display={"flex"} mb={2}>
          <TextField placeholder={"Find team member"}
                     variant="standard"
                     color={"secondary"}
                     size={"small"}
                     type={"text"}
                     value={filterValue}
                     fullWidth
                     onChange={(e) => setFilterValue(e.target.value)}
                     InputProps={{
                       endAdornment: !!filterValue ?
                        <InputAdornment position={"end"}>
                          <IconButton size={"small"} onClick={() => setFilterValue("")}>
                            <ClearRounded/>
                          </IconButton>
                        </InputAdornment> : undefined
                     }}
          />
          <Tooltip title={"Share"}>
            <span>
              {
                updateInProgress?
                  <LoadingButton loading variant="contained" size={"small"} sx={{minWidth: 32, ml: 1}}>
                    <InputRounded sx={{fontSize: 18}}/>
                  </LoadingButton>
                  :
                  <Button variant={"contained"} size={"small"} color={"secondary"}
                          disabled={!enableShareButton}
                          onClick={shareReport}
                          sx={{minWidth: 32, ml: 1}} disableRipple>
                    <InputRounded sx={{fontSize: 18}}/>
                  </Button>
              }
            </span>
          </Tooltip>
        </Box>
        <Divider/>
        <Box mt={2}>
          {
            filteredUsers?.map(t =>
              <ShareReportDialogSingleUser user={t as TeamUser} key={t?.idUser}
                                           onClick={() => toggleUserShare(t?.idUser as string)}
                                           selected={selectedUsers.includes(t?.idUser as string)}
                                           isReportCreator={t?.idUser === props.report.userCreator.idUser}
              />
            )
          }
        </Box>
      </DialogContent>

    </Dialog>
  );
};

export interface IShareReportDialog {
  report: Report;
  open: boolean;
  onClose: () => void;
}

export default ShareReportDialog;
