import React, { createContext, useCallback, useContext, useState } from 'react';
import { NewReportContextValue } from './NewReportContextType';
import {
  AddReportInput,
  Filter,
  Report,
  ReportSchedule,
  Spot,
  Target,
  TargetInput
} from '../utils/graphql/generatedTS/graphql';
import {OnlineVideoFilter} from "../utils/constants/OnlineVideoFilter";

// Create Context
export const NewReportContext =
  createContext<NewReportContextValue>({
    newReportInput: undefined,
    initializeNewEmptyReport: () => {},
    initializeNewReportFromReport: () => {},
    setCampaignName: () => {},
    setSpotList: () => {},
    setSpotListWithRepeatDate: () => {},
    deleteSpotFromList: () => {},
    setTargetList: () => {},
    deleteTargetFromList: () => {},
    setFilter: () => {},
    setDrilldownBySpotgate: () => {},
    setSchedule: () => {},
  });

interface NewReportProviderProps {
  children: React.ReactNode;
}

// Provider
export const NewReportProvider: React.FC<NewReportProviderProps> = ({ children }) => {
  const [newReportInput, setNewReportInput] = useState<AddReportInput | undefined>(undefined);

  /**
   * Sets a new empty report
   * @param idTeam
   */
  const initializeNewEmptyReport = (idTeam: string) => {
    setNewReportInput({
      campaignName: "",
      idTeam,
      spotList: [],
      targetList: [],
      filter: {
        broadcaster: [],
        channel: [],
        deviceType: [],
        onlineVideo: [OnlineVideoFilter.IS_PLATFORM]
      },
      drillDownBySpotgate: false,
      schedule: undefined,
    })
  }

  /**
   * Initialize a new report starting from an existing one
   * @param idTeam
   * @param report
   */
  const initializeNewReportFromReport = (idTeam: string, report: Report) => {
    const spotList = report.spotList?.
      map(s => ({id: s?.id as string, periodEnd: s?.periodEnd as string, periodStart: s?.periodStart as string}));
    const targetList= report.targetList?.
      map(t => ({targetName: t?.targetName, sex: t?.sex, ageBreak: t?.ageBreak})) as TargetInput[];
    setNewReportInput({
      campaignName: report.campaignName,
      idTeam,
      spotList,
      targetList,
      filter: {
        deviceType: report.filter?.deviceType,
        channel: report.filter?.channel,
        broadcaster: report.filter?.broadcaster,
        onlineVideo: report.filter?.onlineVideo,
      },
      drillDownBySpotgate: report.drillDownBySpotgate,
      schedule: report.scheduled,
    })
  }

  const setCampaignName = useCallback((newName: string) => {
    if (!newReportInput) return;
    setNewReportInput({
      ...newReportInput,
      campaignName: newName,
    });
  }, [newReportInput, setNewReportInput]);

  /**
   * Adds a spot to the list, understanding if it's a new element to append or a value to update
   */
  const setSpotList = useCallback((position: number, value: Spot) => {
    if (!newReportInput?.spotList) return;
    if (position > newReportInput?.spotList?.length) return;
    let spotList = [...newReportInput.spotList];
    if (spotList.length > position) spotList[position] = value;
    else spotList.push(value);
    setNewReportInput({
      ...newReportInput,
      spotList,
    })
  }, [newReportInput, setNewReportInput]);

  /**
   * Changes a date to all the elements in the spotlist, setting the dates of the given element
   */
  const setSpotListWithRepeatDate = useCallback((value: Spot) => {
    setNewReportInput((prevReportInput) => {
      if (!prevReportInput?.spotList) return prevReportInput; // Return the current state if spotList is not defined

      // Create a new spot list by updating periodStart and periodEnd
      let newSpotList = prevReportInput.spotList.map(spot => ({
        ...spot,
        periodEnd: value.periodEnd,
        periodStart: value.periodStart
      }));

      // Check if the new spot list is different from the current one to avoid unnecessary state updates
      const isSpotListChanged = !prevReportInput.spotList.every((spot, index) =>
          spot.periodStart === newSpotList[index].periodStart &&
          spot.periodEnd === newSpotList[index].periodEnd
      );

      // Only update state if the spot list has changed
      if (isSpotListChanged) {
        return {
          ...prevReportInput,
          spotList: newSpotList
        };
      }

      // Return the previous state if there is no change
      return prevReportInput;
    });
  }, [setNewReportInput]);


  /**
   * Removes an element from the spot list
   */
  const deleteSpotFromList = useCallback((position: number) => {
    if (!newReportInput?.spotList) return;
    let spotList = [...newReportInput.spotList];
    spotList.splice(position, 1);
    setNewReportInput({
      ...newReportInput,
      spotList,
    })
  }, [newReportInput, setNewReportInput]);

  /**
   * Adds a target to the list, understanding if it's a new element to append or a value to update
   */
  const setTargetList = useCallback((position: number, value: Target) => {
    if (!newReportInput?.targetList) return;
    if (position > newReportInput?.targetList?.length) return;
    let targetList = [...newReportInput.targetList];
    if (targetList.length > position) targetList[position] = value;
    else targetList.push(value);
    setNewReportInput({
      ...newReportInput,
      targetList,
    })
  }, [newReportInput, setNewReportInput]);

  /**
   * Removes an element from the target list
   */
  const deleteTargetFromList = useCallback((position: number) => {
    if (!newReportInput?.targetList) return;
    let targetList = [...newReportInput.targetList];
    targetList.splice(position, 1);
    setNewReportInput({
      ...newReportInput,
      targetList,
    })
  }, [newReportInput, setNewReportInput]);

  /**
   * Set the filter field
   */
  const setFilter = useCallback((newFilter: Filter) => {
    if (!newReportInput) return;
    setNewReportInput({
      ...newReportInput,
      filter: newFilter,
    });
  }, [newReportInput, setNewReportInput]);

  /**
   * Set the filter field
   */
  const setDrilldownBySpotgate = useCallback((value: boolean) => {
    if (!newReportInput) return;
    setNewReportInput({
      ...newReportInput,
      drillDownBySpotgate: value,
    });
  }, [newReportInput, setNewReportInput]);

  /**
   * Set the schedule, or remove it if passed the undefined
   */
  const setSchedule = useCallback((value: ReportSchedule | undefined) => {
    if (!newReportInput) return;
    setNewReportInput({
      ...newReportInput,
      schedule: value,
    });
  }, [newReportInput, setNewReportInput]);

  // generate the context for the provider
  const contextValue: NewReportContextValue = {
    newReportInput,
    initializeNewEmptyReport,
    initializeNewReportFromReport,
    setCampaignName,
    setSpotList,
    setSpotListWithRepeatDate,
    deleteSpotFromList,
    setTargetList,
    deleteTargetFromList,
    setFilter,
    setDrilldownBySpotgate,
    setSchedule
  };

  return (
    <NewReportContext.Provider value={contextValue}>
      {children}
    </NewReportContext.Provider>
  );
};

export const useNewReportContext = () => useContext(NewReportContext);
