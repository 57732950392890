import React, {useEffect, useMemo, useState} from 'react';
import { Box, Container, Typography, Badge } from '@mui/material';
import UserSection from './UserSection';
import { Link, useMatch, useNavigate, useParams } from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import { useQuery } from '@apollo/client';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import { UserTeam, UserTeamRole } from '../../../utils/graphql/generatedTS/graphql';
import { UserQueries } from '../../../utils/graphql/queries/user';
import serviceStatusData from '../../page/serviceStatus/service_status.json';


/**
 *
 * @param {React.PropsWithChildren<ICommonHeader>} props
 * @return {JSX.Element}
 * @constructor
 */
const CommonHeader: React.FC<ICommonHeader> = (props) => {
  const {authenticatedIdUser, authToken, deleteAuthToken} = useAuthContext();
  const { data, loading } = useQuery(UserQueries.GET_USER_BY_ID, {
    variables: {
      idUser: authenticatedIdUser as string
    },
    context: generateClientContextWithHeaders(authToken),
    skip: !authenticatedIdUser,
  });


  const {idTeam} = useParams();
  const navigate = useNavigate();
  const matchDashboard = useMatch(RouteKey.Dashboard.replace(":idTeam", idTeam as string));
  const matchUserManagement = useMatch(RouteKey.UserManagement.replace(":idTeam", idTeam as string));
  const matchServiceStatus = useMatch(RouteKey.ServiceStatus.replace(":idTeam", idTeam as string));


  const currentTeam: UserTeam | undefined = useMemo(() => {
    const team = data?.getUserById?.teams?.filter(t => t?.team.idTeam === idTeam);
    if (!team || team.length === 0) return undefined;
    else return team[0] as UserTeam;
  }, [data?.getUserById?.teams, idTeam]);


/*  // Notification badge for new service status updates
  const now = new Date();
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const oneDayAgo = new Date(now.getTime() - oneDayInMilliseconds);

  const hasNewServiceStatus = useMemo(() => {
    return serviceStatusData.some(status => {
      const statusDate = new Date(status.date);
      return statusDate > oneDayAgo;
    });
  }, [serviceStatusData, oneDayAgo]);*/




  return (
      <Box sx={{backgroundColor: "#212529"}}>
        <Container>
          <Box width={"100%"}
               display={"flex"} alignItems={"center"}
               paddingY={1.5}>
            {/*<img src={"/imgs/horse-white.svg"} height={43} alt={"Databreeders Logo"}/>*/}
            <Link to={RouteKey.Dashboard.replace(":idTeam", idTeam as string)}>
              <img
                  src={"/imgs/gradient_logo.png"}
                  style={{ marginRight: "50px", marginTop: "5px",cursor: 'pointer' }}
                  height={43}
                  alt={"SpotOn-Logo"}
              />
            </Link>
            <Box display={"flex"} alignItems={"center"}  flexGrow={10}>
              <Typography
                  variant={"h6"}
                  color={matchDashboard ? "white" : "#9b9d9e"}
                  //paddingX={4}
                  sx={{ cursor: "pointer"}}
                  component={Link}
                  to={RouteKey.Dashboard.replace(":idTeam", idTeam as string)}
              >
                Reports
              </Typography>
              <Typography
                  variant={"h6"}
                  color={matchServiceStatus ? "white" : "#9b9d9e"}
                  paddingX={4}
                  sx={{ cursor: "pointer" }}
                  component={Link}
                  to={RouteKey.ServiceStatus.replace(":idTeam", idTeam as string)}
              >
                Service Status
              </Typography>
              {!loading && currentTeam?.role === UserTeamRole.Admin && (
                  <>
                    <Typography
                        variant={"h6"}
                        color={matchUserManagement ? "white" : "#9b9d9e"}
                        sx={{ cursor: "pointer", marginRight: '30px' }}
                        component={Link}
                        to={RouteKey.UserManagement.replace(":idTeam", idTeam as string)}
                    >
                      Users Management
                    </Typography>

                  </>
              )}
            </Box>

            {/* User section */}
            <Box>
              <UserSection/>
            </Box>


          </Box>
        </Container>
      </Box>
  );
};


export interface ICommonHeader {


}


export default CommonHeader;
