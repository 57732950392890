import {ArrowBack, Info} from '@mui/icons-material';
import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import { useMutation } from '@apollo/client';
import { UserMutations } from '../../../utils/graphql/mutations/user';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useSnackbar } from 'notistack';

/**
 *
 * @param {React.PropsWithChildren<IHomeResetPasswordRequest>} props
 * @return {JSX.Element}
 * @constructor
 */
const HomeResetPasswordRequest: React.FC<IHomeResetPasswordRequest> = (props) => {
  const [email, setEmail] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const [resetUserPasswordMutation, {loading, error}] = useMutation(UserMutations.RESET_USER_PASSWORD, {
    context: generateClientContextWithHeaders(),
  })

  const navigate = useNavigate();

  const resetPassword = async () => {
    if (email) {
      const response = await resetUserPasswordMutation({
        variables: {
          email
        }
      });
      if (response.data) {
        enqueueSnackbar("Email correctly sent", {variant: 'success'});
        setEmail("");
      } else {
        enqueueSnackbar("An error occurred, please try again", {variant: 'error'});
      }
    }
  };

  const returnToLogin = () => {
    navigate(RouteKey.Home);
  }

  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
      {/* return to login row*/}
      <Box alignSelf={"start"}  display={"flex"} alignItems={"center"} mb={3} ml={3} sx={{cursor: "pointer"}} onClick={returnToLogin}>
        <ArrowBack fontSize={'small'} sx={{ color: 'white' }}/>
        <Typography variant={"h1"} fontSize={15} ml={1} color={"white"}>
          Return to login
        </Typography>
      </Box>

      <Typography variant={"h1"} color={"white"} textAlign={"center"} sx={{ width: '100%' }}>
        Reset your password
      </Typography>
      <Typography variant={"h1"} fontSize={15} color={"white"} mt={1}>
        We'll email you a password reset link.
      </Typography>

      <TextField label="Email"
                 variant="outlined"
                 size={"small"}
                 type={"email"}
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 sx={{
                     minWidth: 295,
                     mt: 4,
                     input: { color: 'white' },
                     label: { color: 'white' },
                     '& .MuiInputLabel-root': {
                         color: 'white',                             // Initial label color
                         '&.Mui-focused': {
                             color: 'white',                           // Label color when focused
                         },
                     },
                     '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                             borderColor: 'white',
                             borderRadius: '10px',
                         },
                         '&:hover fieldset': {
                             borderColor: 'white',
                         },
                         '&.Mui-focused fieldset': {
                             borderColor: 'white',
                         },
                     }
                 }}
      />

        <Button variant={"contained"}
                sx={{width: 295, mt: 4, backgroundColor: 'white', color: '#8A5FFD',
                    '&:hover': {
                        backgroundColor: '#5EE0E6',
                        color: 'white'
                    },
                }}
                style={{borderRadius:"20px", fontWeight:"bold"}} onClick={resetPassword}>
        Reset password
      </Button>

        <Mailto email="spotonvideo.support@databreeders.com" subject="Assistance Needed" body="Hello, I need assistance with my account">
            <Button variant={"text"}
                    sx={{width: 290, mt: 5, color: '#FFFFFF'}}
                    startIcon={<Info/>}
                    style={{borderRadius:"10px", textTransform: 'lowercase'}}>
                Having troubles? Contact assistance
            </Button>
        </Mailto>

        <Typography variant={"h5"}
                    align={"center"}
                    fontSize={12}
                    sx={{color: '#FFFFFF'}}>
            spotonvideo.support@databreeders.com
        </Typography>

    </Box>
  );
};

export interface IHomeResetPasswordRequest {

}

export default HomeResetPasswordRequest;


const Mailto = ({ email, subject, body, children }: { email: string; subject?: string; body?: string; children: React.ReactNode }) => {
    return (
        <a href={`mailto:${email}?subject=${encodeURIComponent(subject ?? '')}&body=${encodeURIComponent(body ?? '')}`}>
            {children}
        </a>
    );
};

export { Mailto };