import { graphql } from "../generatedTS";

const GET_USER_BY_ID = graphql(`
    query GetUserById($idUser: String!) {
        getUserById(idUser: $idUser) {
            ...UserFull
        }
    }
`);

export const UserQueries = {
  GET_USER_BY_ID,
}
