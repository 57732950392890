import React from 'react';
import { CssBaseline, IconButton, ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes } from './App.Routes';
import { AuthProvider } from './context/AuthContext';
import { theme } from './GlobalStyles';
import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { InMemoryCacheOptions } from './utils/graphql/InMemoryCacheOptions';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { CloseRounded } from '@mui/icons-material';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import QueryParamProvider from 'use-query-params/dist/QueryParamProvider';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';


// ----------------
// CHART.JS
// ----------------
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
  BarElement,
  zoomPlugin
);

// ----------------
// APOLLO CLIENT
// ----------------
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});
const link = from([httpLink]);

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: InMemoryCacheOptions
  }),
  link: link,
});

function App() {
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}
                              action={(snackbarId) => (
                                <IconButton onClick={() => closeSnackbar(snackbarId)}>
                                  <CloseRounded/>
                                </IconButton>
                              )}
                              autoHideDuration={3000}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline />
                <AuthProvider>
                  <Routes>
                    {
                      routes.map(r => {
                        return <Route key={r.key} path={r.path} element={r.component} />
                      })
                    }
                  </Routes>
                </AuthProvider>
              </LocalizationProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </ApolloProvider>
      </QueryParamProvider>
    </BrowserRouter>
  );
}

export default App;
