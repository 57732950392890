import React from 'react';

/**
 *
 * @param {React.PropsWithChildren<IMandatoryAsterisk>} props
 * @return {JSX.Element}
 * @constructor
 */
const MandatoryAsterisk: React.FC<IMandatoryAsterisk> = (props) => {
  return (
    <span style={{ color: 'red' }}>*</span>
  );
};

export interface IMandatoryAsterisk {

}

export default MandatoryAsterisk;
