import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { ControlPointOutlined, FilterAltOutlined } from '@mui/icons-material';
import DashboardReportTable from '../../organism/Dashboard.ReportTable/Dashboard.ReportTable';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import CommonPageBaseLayout from '../../organism/Common.PageBaseLayout/Common.PageBaseLayout';

/**
 *
 * @param {React.PropsWithChildren<IDashboard>} props
 * @return {JSX.Element}
 * @constructor
 */
const Dashboard: React.FC<IDashboard> = (props) => {
    const navigate = useNavigate();
    const {idTeam} = useParams();
    return (
        <CommonPageBaseLayout>
            <Typography variant={"h1"} fontWeight={600}>
                Reports
            </Typography>
            <Typography variant={"subtitle1"} mt={1}>
                Consult and filter the list of reports. Process a new report through the <strong>New report</strong> button. Explore all the available actions by clicking on the three horizontal dots into the <strong>Actions</strong> column.
            </Typography>


            {/* row of filter / new report button*/}
            <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                <Button variant={"contained"} color={"secondary"}
                        startIcon={<FilterAltOutlined/>}
                        style={{ fontWeight: 'bold', fontSize: '14px' }}
                >
                    Show filter
                </Button>
                <Button variant={"contained"}
                        onClick={() => navigate(RouteKey.NewReport.replace(":idTeam", idTeam as string))}
                        startIcon={<ControlPointOutlined/>}
                        style={{ fontWeight: 'bold', fontSize: '14px', backgroundImage: 'linear-gradient(to right, #8A5FFD, #5EE0E6)'}}
                >
                    New report
                </Button>
            </Box>


            {/* Table section */}
            <Box mt={4}>
                <DashboardReportTable/>
            </Box>
        </CommonPageBaseLayout>
    );
};


export interface IDashboard {


}


export default Dashboard;
