import { Box } from '@mui/material';
import React from 'react';
import CommonHeader from '../Common.Header/Common.Header';
import CommonAuthPagesBodyContainer from '../Common.AuthPagesBodyContainer/Common.AuthPagesBodyContainer';
import CommonFooter from "../Common.Footer/Common.Footer";

/**
 *
 * @param {React.PropsWithChildren<ICommonPageBaseLayout>} props
 * @return {JSX.Element}
 * @constructor
 */
const CommonPageBaseLayout: React.FC<ICommonPageBaseLayout> = (props) => {
  return (
    <Box sx={{backgroundColor: "#F8F9FA"}}>
      <CommonHeader/>
      <CommonAuthPagesBodyContainer>
        {props.children}
      </CommonAuthPagesBodyContainer>
        <CommonFooter/>
    </Box>
  );
};

export interface ICommonPageBaseLayout {
  children: React.ReactNode | string
}

export default CommonPageBaseLayout;
