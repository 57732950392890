import React from 'react';
import { Report } from '../../../utils/graphql/generatedTS/graphql';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { theme } from '../../../GlobalStyles';
import { useSnackbar } from 'notistack';
import { useCopyToClipboard } from 'react-use';

/**
 *
 * @param {React.PropsWithChildren<ISingleRowInfoDialog>} props
 * @return {JSX.Element}
 * @constructor
 */
const SingleRowInfoDialog: React.FC<ISingleRowInfoDialog> = (props) => {

  const { enqueueSnackbar } = useSnackbar();
  const [state, copyToClipboard] = useCopyToClipboard();

  const copyS3Link = () => {
    if (!props.report.engineUuid) return;
    copyToClipboard(`https://s3.console.aws.amazon.com/s3/buckets/${process.env.REACT_APP_ENV}-ttvam-results?region=eu-central-1&prefix=${props.report.engineUuid}/analysis/`);
    enqueueSnackbar("Link copied!", {
      variant: "success"
    });
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        Info - {props.report.campaignName}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <strong>Repotr ID:</strong> {props.report.idReport}<br/>
          <strong>Engine UUID:</strong> {props.report.engineUuid}<br/>
          <strong>Raw files:</strong> <span onClick={copyS3Link} style={{cursor: "pointer", color: theme.palette.primary.main}}>Copy Link</span>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export interface ISingleRowInfoDialog {
  onClose: () => void;
  open: boolean;
  report: Report
}

export default SingleRowInfoDialog;
