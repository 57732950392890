// PrivacyPolicies.tsx
import React from 'react';
import { Alert } from '@mui/material';

const PrivacyPolicies: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    return (
        <Alert severity="info" onClose={onClose} style={{ textAlign: 'left', backgroundColor: 'rgba(232,232,232,0.5)', borderRadius: '15px' }}>
            <br />
            <span style={{fontSize:"18px", fontWeight:"bold"}}>PRIVACY POLICY</span>
            <br />
            <strong>(art. 13 of EU Regulation 679/2016)</strong>
            <br />
            <br />

            <strong>1. Data controller</strong>
            <br />
            The Data Controller is dataBreeders S.r.l. (C.F &amp; P.IVA 10201420964) with registered office in Milan,
            viale Lunigiana 40, e-mail info@databreeders.com (hereinafter "Owner").
            <br />
            <br />
            <strong>2. Object of the treatment</strong>
            <br />
            The object of the processing is the User's common identification data such as name, surname and e-mail address.
            <br />
            <br />
            <strong>3. Purpose and legal basis of the processing</strong>
            <br />
            Personal data is collected and processed by the Data Controller for the following purposes:
            <br />
            • registration and profile creation;
            <br />
            • execution of the services and functionality of the APP;
            <br />
            • fulfillment of legal obligations;
            <br />
            • process any requests for assistance;
            <br />
            • for the pursuit of the legitimate interest of the Data Controller provided that the interests or interests do not prevail
            fundamental rights and freedoms of the interested party, taking into account the reasonable expectations entertained
            by the interested party based on his relationship with the data controller and in particular for prevention
            of fraud, to a strictly necessary and proportionate extent, to guarantee the security of the networks and
            of information.
            <br />
            <br />
            The legal bases of the processing are: the execution of a contract of which the interested party is a party (art.6 letter b
            GDPR), fulfillment of legal obligations (art. 6 letter c GDPR), pursuit of the legitimate interest of
            Owner (art.6 letter f GDPR).
            <br />
            <br />
            <strong>4. Provision of data</strong>
            <br />
            The provision of data for the purposes referred to in point 3 is necessary to use the requested services and to
            pursue what is indicated therein.
            <br />
            <br />
            <strong>5. Treatment methods</strong>
            <br />
            The processing of personal data will take place with or without the aid of IT systems. The Owner undertakes to
            guarantee the logical and physical security and confidentiality of the personal data processed, implementing all the
            technical and organizational measures appropriate for processing.
            <br />
            <br />
            <strong>6. Data retention</strong>
            <br />
            Personal data, for the purposes referred to in point 3, will be processed and stored for the time necessary to
            pursue the purposes for which they were collected and in any case within the times established by current laws.
            Once the retention period has expired, the data will be deleted and/or anonymized.
            <br />
            <br />
            <strong>7. Categories of recipients</strong>
            <br />
            The User's personal data is accessible to:
            <br />
            i) employees and/or collaborators of the Data Controller in their capacity as persons authorized to process data or
            system administrators;
            <br />
            ii) service providers who carry out outsourced activities on behalf of the Owner in their capacity as
            specially designated external data controllers who carry out related, instrumental activities
            or in support of those of the Owner, e.g. technical assistance. The complete list of Managers is
            available at the Data Controller's headquarters.
            <br />
            <br />
            <strong>8. Data transfer</strong>
            <br />
            There are no transfers of data to non-EU countries or to international organisations. If, for
            the execution of the functions and services of the APP, the Owner should transfer the User's personal data to
            non-EU countries, will adopt all the adequate guarantees provided for in the articles. 44 et seq. of the GDPR, including the decisions of
            adequacy and standard contractual clauses approved by the European Commission.
            <br />
            <br />
            <strong>9. Rights of the interested party</strong>
            <br />
            In compliance with the provisions of articles 15 to 21 of the GDPR, the User, in relation to the personal data that
            concern him, he may, at any time, exercise the rights indicated therein and in particular:
            <br />
            • Right of access (art. 15 GDPR)
            <br />
            • Right of rectification (art. 16 GDPR)
            <br />
            • Right to cancellation (art. 17 GDPR)
            <br />
            • Right to limit processing (art. 18 GDPR)
            <br />
            • Right to portability (art. 20 GDPR)
            <br />
            • Right to object (art. 21 GDPR).
            <br />
            <br />
            The aforementioned rights can be exercised by contacting the Data Controller at the addresses referred to in point 1 or by contacting the
            Data Protection Officer, designated pursuant to art. 37 GDPR, at address
            dpo.databreeders@sgsolution.eu.
            <br />
            <br />
            For the treatments referred to in this information it is always possible to lodge a complaint with the Guarantor for the
            Protection of Personal Data (www.garanteprivacy.it).
            <br />
            <br />
        </Alert>
    );
};

export default PrivacyPolicies;
