import React from 'react';
import { Box, Button } from '@mui/material';
import ExcelIcon from '../../../icons/ExcelIcon';
import { useDownloadExcelExport } from '../../../hook/useDownloadExcelExport';

/**
 *
 * @param {React.PropsWithChildren<IReportDownloadExcelButton>} props
 * @return {JSX.Element}
 * @constructor
 */
const ReportDownloadExcelButton: React.FC<IReportDownloadExcelButton> = (props) => {

  const { getFile } = useDownloadExcelExport({
    idReport: props.idReport
  });

  return (
    <Box>
      <Button variant={"contained"} startIcon={<ExcelIcon/>} onClick={getFile}>
        Export
      </Button>
    </Box>
  );
};

export interface IReportDownloadExcelButton {
  idReport: string;
}

export default ReportDownloadExcelButton;
