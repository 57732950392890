import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React from 'react';
import { Report, Target } from '../../../utils/graphql/generatedTS/graphql';
import ReportTabInfo from '../Report.TabInfo/Report.TabInfo';
import ReportSingleTabStructure from '../Report.SingleTabStructure/Report.SingleTabStructure';
import { TAB_STRUCTURE, TabName } from '../../../utils/constants/ReportTabs';
import ReportTabGlossary from "../Report.TabGlossary/Report.TabGlossary";
import CustomTabPanel from "./CustomTabPanel";
import CustomTabList from "./CustomTabPanel";

/**
 *
 * @param {React.PropsWithChildren<IReportTabSelector>} props
 * @return {JSX.Element}
 * @constructor
 */
const ReportTabSelector: React.FC<IReportTabSelector> = (props) => {
  const [value, setValue] = React.useState(TabName.TabSummary);

  const handleChange = (event: React.SyntheticEvent, newValue: TabName) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <CustomTabList onChange={handleChange}>
            <Tab label="Summary" value={TabName.TabSummary} />
            <Tab label="Contacts" value={TabName.TabContacts} />
            <Tab label="Build-Up contacts" value={TabName.TabContactsBU} />
            <Tab label="Reach 1+" value={TabName.TabR1} />
            <Tab label="Reach & Frequency" value={TabName.TabRF} />
            <Tab label="Build-Up R1+" value={TabName.TabR1BU} />
            <Tab label="Info" value="TabInfo" />
            <Tab label="Definitions" value="TabGlossary" />
          </CustomTabList>
        </Box>
        <TabPanel value={TabName.TabSummary}>
          <ReportSingleTabStructure idReport={props.report.idReport}
                                    reportTarget={props.report.targetList as Target[]}
                                    tabStructure={TAB_STRUCTURE.SUMMARY}/>
        </TabPanel>
        <TabPanel value={TabName.TabContacts}>
          <ReportSingleTabStructure idReport={props.report.idReport}
                                    reportTarget={props.report.targetList as Target[]}
                                    tabStructure={TAB_STRUCTURE.CONTACTS}/>
        </TabPanel>
        <TabPanel value={TabName.TabContactsBU}>
          <ReportSingleTabStructure idReport={props.report.idReport}
                                    reportTarget={props.report.targetList as Target[]}
                                    tabStructure={TAB_STRUCTURE.CONTACTSBU}/>
        </TabPanel>
        <TabPanel value={TabName.TabR1}>
          <ReportSingleTabStructure idReport={props.report.idReport}
                                    reportTarget={props.report.targetList as Target[]}
                                    tabStructure={TAB_STRUCTURE.R1}/>
        </TabPanel>
        <TabPanel value={TabName.TabRF}>
          <ReportSingleTabStructure idReport={props.report.idReport}
                                    reportTarget={props.report.targetList as Target[]}
                                    tabStructure={TAB_STRUCTURE.RF}/>
        </TabPanel>
        <TabPanel value={TabName.TabR1BU}>
          <ReportSingleTabStructure idReport={props.report.idReport}
                                    reportTarget={props.report.targetList as Target[]}
                                    tabStructure={TAB_STRUCTURE.R1BU}/>
        </TabPanel>
        <TabPanel value="TabInfo"><ReportTabInfo report={props.report}/></TabPanel>
        <TabPanel value="TabGlossary"><ReportTabGlossary report={props.report}/></TabPanel>
      </TabContext>
    </Box>
  );
};

export interface IReportTabSelector {
  report: Report
}

export default ReportTabSelector;
