import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, TextField } from '@mui/material';
import { useQuery } from '@apollo/client';
import { ReportQueries } from '../../../utils/graphql/queries/report';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import axios from 'axios';
import { SummaryTargetUniversal } from '../../../utils/constants/ReportTabsType';
import { useReportFileCacheContext } from '../../../context/ReportFileCacheContext';
import GradientCircularProgress from "../Dashboard.ReportTable/GradientCircularProgress";

/**
 *
 * @param {React.PropsWithChildren<IReportTargetUniverse>} props
 * @return {JSX.Element}
 * @constructor
 */
const ReportTargetUniverse: React.FC<IReportTargetUniverse> = (props) => {

  const {authToken} = useAuthContext();
  const {setSingleFileCache, cachedContent} = useReportFileCacheContext();
  const [errorFetchingFile, setErrorFetchingFile] = useState<boolean>(false);

  const idPostProcessFile = "standard_tabsummary_table_universe_target_abs.json";

  const {data: fileUrlQuery} = useQuery(ReportQueries.REPORT_POSTPROCESS_FILE, {
    variables: {
      idReport: props.idReport,
      idPostProcess: idPostProcessFile,
    },
    fetchPolicy: 'network-only',
    context: generateClientContextWithHeaders(authToken),
    skip: !authToken || !props.idReport,
  });

  const fileUrl: string | undefined = useMemo(() => {
    return fileUrlQuery?.getReportPostprocessFile;
  }, [fileUrlQuery]);


  useEffect(() => {
    if (fileUrl && !cachedContent[idPostProcessFile]) {
      axios.get(fileUrl)
        .then(response => {
          setSingleFileCache(idPostProcessFile, response.data);
        })
        .catch(error => {
          setErrorFetchingFile(true);
          console.error('Error fetching data: ', error);
        });
    }
  }, [fileUrl]);

  const targetUniverseValue = useMemo(() => {
    if (!cachedContent[idPostProcessFile]) return undefined;
    return cachedContent[idPostProcessFile] as SummaryTargetUniversal;
  }, [cachedContent[idPostProcessFile]]);

  return (
    !errorFetchingFile && !targetUniverseValue?
      <GradientCircularProgress size={20}/>
      :
      <TextField variant="outlined"
                 value={errorFetchingFile ? "Error" : (props.target && targetUniverseValue) ? targetUniverseValue[props.target] : ""}
                 size={"small"}
                 onChange={() => {}}
                 InputProps={{
                   sx: {
                     width: 120
                   }
                 }}
                 inputProps={{
                   style: {
                     textAlign: "center"
                   }
                 }}
                 focused={false}
      />
  );
};

export interface IReportTargetUniverse {
  idReport: string;
  target: string | undefined;
}

export default ReportTargetUniverse;
