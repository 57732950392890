import { graphql } from "../generatedTS";


const LOGIN = graphql(`
  mutation Login($loginInput: LoginInput!) {
      login(loginInput: $loginInput) {
          expiresIn
          jwtToken
          refreshToken
          user {
              ...UserFull
          }
    }
  }
`);

const RESET_USER_PASSWORD = graphql(`
  mutation ResetUserPassword($email: String!) {
      resetUserPassword(email: $email) 
  }
`);

const ADD_USER_PASSWORD = graphql(`
  mutation AddUserPassword($addUserPasswordInput: AddUserPasswordInput!) {
      addUserPassword(addUserPasswordInput: $addUserPasswordInput)
  }
`);

export const UserMutations = {
  ADD_USER_PASSWORD,
  LOGIN,
  RESET_USER_PASSWORD
}
