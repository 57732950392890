import React, { useState } from 'react';
import { Report, ReportStatus } from '../../../utils/graphql/generatedTS/graphql';
import {
  ContentCopyRounded,
  DeleteOutlineOutlined,
  EditOutlined,
  FileDownloadOutlined,
  HistoryToggleOffRounded,
  InfoOutlined,
  MoreHoriz,
  QueryBuilderRounded,
  ReplayRounded,
  ShareRounded
} from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { theme } from '../../../GlobalStyles';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ReportMutations } from '../../../utils/graphql/mutations/report';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import ShareReportDialog from './ShareReportDialog';
import { ReportQueries } from '../../../utils/graphql/queries/report';
import SingleRowInfoDialog from './SingleRowInfoDialog';
import {color} from "chart.js/helpers";

/**
 * Show the menu with all the actions connected to a Report
 * @param {React.PropsWithChildren<ISingleRowActionMenu>} props
 * @return {JSX.Element}
 * @constructor
 */
const SingleRowActionMenu: React.FC<ISingleRowActionMenu> = ({report, enableDeleteAction}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDeleteReportDialog, setShowDeleteReportDialog] = useState<boolean>(false);
  const [showShareReportDialog, setShowShareReportDialog] = useState<boolean>(false)
  const [showReportInfoDialog, setShowReportInfoDialog] = useState<boolean>(false)
  const open = Boolean(anchorEl);
  const {authToken} = useAuthContext();
  const {idTeam} = useParams();
  const navigate = useNavigate();

  const [rerunReportMutation, {}] = useMutation(ReportMutations.RERUN_REPORT, {
    context: generateClientContextWithHeaders(authToken),
  })

  const [deleteReportSchedule, {}] = useMutation(ReportMutations.DELETE_REPORT_SCHEDULE, {
    context: generateClientContextWithHeaders(authToken),
  })

  // query to get the file to donwload the report
  const [ getZipFileQuery] = useLazyQuery(ReportQueries.REPORT_POSTPROCESS_FILE, {
    variables: {
      idReport: report.idReport,
      idPostProcess: "raw_json_tables.zip",
    },
    context: generateClientContextWithHeaders(authToken)
  });

  const handleClickOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Duplicate the report
  const rerunReport = async () => {
    handleCloseMenu();
    await rerunReportMutation({
      variables: {
        idReport: report.idReport,
      }
    });
  };

  // get the file Url and download the report
  const downloadReport = async () => {
    const fileUrl = await getZipFileQuery();
    if (fileUrl.data?.getReportPostprocessFile) {
      window.open(fileUrl.data?.getReportPostprocessFile, "_blank");
    }
  }

  // cancel the scheduling of the report
  const deleteScheduleReport = async () => {
    handleCloseMenu();
    await deleteReportSchedule({
      variables: {
        idReport: report.idReport
      }
    });
    await rerunReport();
  }

  return (
    <>
      <IconButton size={"small"}
                  sx={{color: "#324158"}}
                  disabled={![
                    ReportStatus.Succeeded,
                    ReportStatus.EngineFailed,
                    ReportStatus.Created,
                    ReportStatus.PostprocessFailed
                  ].includes(report.status)} onClick={handleClickOpenMenu}>
        <MoreHoriz/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          dense: true,
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            borderRadius: '10px',
          },
        }}
      >
        <MenuItem onClick={rerunReport}>
          <ListItemIcon>
            <ReplayRounded fontSize={"small"}/>
          </ListItemIcon>
          <ListItemText>Rerun report
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => navigate(RouteKey.NewReport.replace(":idTeam", idTeam as string) + `?from=${report.idReport}&action=duplicate`)} >
          <ListItemIcon>
            <ContentCopyRounded fontSize={"small"}/>
          </ListItemIcon>
          <ListItemText>Duplicate report</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => navigate(RouteKey.NewReport.replace(":idTeam", idTeam as string) + `?from=${report.idReport}&action=edit`)}>
        <ListItemIcon>
            <EditOutlined fontSize={"small"}/>
          </ListItemIcon>
          <ListItemText>Edit report</ListItemText>
        </MenuItem>

        <MenuItem
            onClick={downloadReport}
            disabled={report.status !== ReportStatus.Succeeded}
            style={{ display: (idTeam === "team_1OGvPQDm3040GBb" || idTeam === "team_LIhbHzXqD7GgSEb" || idTeam === "team_QNQfl8aB2o8lMSP" ) ? 'flex' : 'none' }}
        >
          <ListItemIcon>
            <FileDownloadOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download report</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => {
          handleCloseMenu();
          setShowShareReportDialog(true);
        }} disabled={(report.status !== ReportStatus.Succeeded)}>
          <ListItemIcon>
            <ShareRounded fontSize={"small"}/>
          </ListItemIcon>
          <ListItemText>Share report</ListItemText>
        </MenuItem>

        {
          report.scheduled ?
            <MenuItem onClick={deleteScheduleReport}
                      disabled={(
                        ![
                          ReportStatus.Created,
                          ReportStatus.Succeeded,
                          ReportStatus.EngineFailed,
                          ReportStatus.PostprocessFailed
                        ].includes(report.status))}>
              <ListItemIcon>
                <HistoryToggleOffRounded fontSize={"small"}/>
              </ListItemIcon>
              <ListItemText>Disable scheduled report</ListItemText>
            </MenuItem>
            :
            ''
            // <MenuItem onClick={handleCloseMenu}
            //           disabled={(
            //             ![
            //               ReportStatus.Succeeded,
            //               ReportStatus.EngineFailed,
            //               ReportStatus.PostprocessFailed
            //             ].includes(report.status))}>
            //   <ListItemIcon>
            //     <QueryBuilderRounded fontSize={"small"}/>
            //   </ListItemIcon>
            //   <ListItemText>Schedule report</ListItemText>
            // </MenuItem>
        }

        <MenuItem onClick={() => {
          handleCloseMenu();
          setShowReportInfoDialog(true);
        }}>
          <ListItemIcon>
            <InfoOutlined fontSize={"small"}/>
          </ListItemIcon>
          <ListItemText>Info</ListItemText>
        </MenuItem>

        <Divider />

        <MenuItem disabled={!enableDeleteAction}
                  onClick={() => {
          handleCloseMenu();
          setShowDeleteReportDialog(true);

        }}>
          <ListItemIcon>
            <DeleteOutlineOutlined fontSize={"small"} color={"error"}/>
          </ListItemIcon>
          <ListItemText sx={{color: theme.palette.error.main}}>Delete report</ListItemText>
        </MenuItem>
      </Menu>

      <DeleteConfirmDialog open={showDeleteReportDialog} onClose={() => setShowDeleteReportDialog(false)} idReport={report.idReport}/>
      <ShareReportDialog open={showShareReportDialog} onClose={() => setShowShareReportDialog(false)} report={report}/>
      <SingleRowInfoDialog open={showReportInfoDialog} onClose={() => setShowReportInfoDialog(false)} report={report}/>
    </>
  );
};

export interface ISingleRowActionMenu {
  report: Report;
  enableDeleteAction: boolean;
}

export default SingleRowActionMenu;
