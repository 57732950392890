import { Avatar, Box, Button, Typography } from '@mui/material';
import React from 'react';
import { UserTeam } from '../../../utils/graphql/generatedTS/graphql';
import { useNavigate } from 'react-router-dom';

/**
 *
 * @param {React.PropsWithChildren<ITemaRow>} props
 * @return {JSX.Element}
 * @constructor
 */
const TemaRow: React.FC<ITemaRow> = (props) => {
  const navigate = useNavigate();

  return (
    <Box display={"flex"}
         flexDirection={"column"}
         minWidth={300}
         paddingX={4} paddingY={2}
         sx={{
           backgroundColor: "#f5f5f5",
           borderTop: props.position > 1 ? "1px solid #dee2e6" : undefined,
         }}
    >
      <Typography variant={"subtitle2"} color={"textSecondary"} sx={{fontFamily: 'Montserrat'}} fontWeight={600}>
        {
          props.team.team.name ? props.team.team.name : `Team ${props.position}`
        }
      </Typography>
      <Box display={"flex"} justifyContent={"space-between"} mt={0.5}>
        <Box display={"flex"}>
          <img src={"/imgs/user-icon.svg"} style={{width: 40}}/>
          {
            props.team.team.size > 1 ?
              <Avatar sizes={"small"} sx={{ml: -2}}>+{props.team.team.size-1}</Avatar> : ""
          }
        </Box>
        <Button variant={"contained"} onClick={() => props.onClick()}>
          Log in
        </Button>
      </Box>
    </Box>
  );
};

export interface ITemaRow {
  team: UserTeam;
  position: number;
  onClick: () => void;
}

export default TemaRow;
