import React from 'react';
import { TabSubView } from '../../../utils/constants/ReportTabsType';
import {
  Aggregation,
  Metric1,
  Metric2,
  ReportPlotType,
  ReportViewType,
  TabName
} from '../../../utils/constants/ReportTabs';
import { useGetPostprocessData } from '../../../hook/useGetPostprocessData';
import SubViewTable from './SubView.Table';
import { Box, Skeleton } from '@mui/material';
import SubViewLine from './SubView.Line';
import SubViewBarStacked from './SubView.BarStacked';
import SubViewBar from './SubView.Bar';

/**
 *
 * @param {React.PropsWithChildren<ISubView>} props
 * @return {JSX.Element}
 * @constructor
 */
const SubView: React.FC<ISubView> = ({idReport,
                                       metric1,
                                       metric2,
                                       subView,
                                       tabName,
                                       targetGroupName,
                                       aggregation}: React.PropsWithChildren<ISubView>): JSX.Element => {

  const pythonElementName = `standard_${tabName}_${subView.type}_${subView.kpi}_${aggregation}_${metric1}${metric2 ? "_"+metric2 : ""}`;

  const postprocessData = useGetPostprocessData({
    idReport: idReport,
    idPostProcess: pythonElementName
  });

  return (
    <Box mt={4}>
      {
        postprocessData ?
          <>
            {
              // Represent a Table. We show data connected to the Taget group selected, except for the Tab TabName.TabR1
              // where we show always all the rows
              subView.type === ReportViewType.Table ?
                <SubViewTable data={postprocessData}
                              pythonElementName={pythonElementName}
                              targetGroupName={tabName === TabName.TabR1 ? undefined : targetGroupName}/> : ""
            }
            {
              subView.type === ReportViewType.Plot && subView.plotType === ReportPlotType.Line ?
                <SubViewLine data={postprocessData}
                             pythonElementName={pythonElementName}
                             targetGroupName={targetGroupName}/> : ""
            }
            {
              subView.type === ReportViewType.Plot && subView.plotType === ReportPlotType.BarStacked ?
                <SubViewBarStacked data={postprocessData}
                                   pythonElementName={pythonElementName}
                                   targetGroupName={targetGroupName}/> : ""
            }
            {
              subView.type === ReportViewType.Plot && subView.plotType === ReportPlotType.Bar ?
                <SubViewBar data={postprocessData}
                            pythonElementName={pythonElementName}
                            targetGroupName={targetGroupName}/> : ""
            }
          </>
          :
          <Skeleton height={150}/>
      }
    </Box>
  );
};

export interface ISubView {
  idReport: string;
  tabName: TabName;
  aggregation: Aggregation;
  subView: TabSubView;
  metric1: Metric1;
  metric2?: Metric2;
  targetGroupName?: string | undefined;
}

export default SubView;
