import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { SingleTabSubViewJson } from '../../../utils/constants/ReportTabsType';
import { Line } from 'react-chartjs-2';
import { PLOT_TITLES } from '../../../utils/constants/ReportTabs';
import TitleWithHorizontalDivider from '../../atom/TitleWithHorizontalDivider/TitleWithHorizontalDivider';


/**
 *
 * @param {React.PropsWithChildren<ISubViewLine>} props
 * @return {JSX.Element}
 * @constructor
 */
const SubViewLine: React.FC<ISubViewLine> = ({data, targetGroupName, pythonElementName}) => {

  const labels = useMemo(() => {
    if (data.data.length === 0) return [];
    const dataKeys = Object.keys(data.data[0]);
    return dataKeys
              .filter(item => !["Target name", "Type"].includes(item))  // extract only values
  }, [data.data]);

  const datasets = useMemo(() => {
    if (data.data.length === 0) return [];
    return data.data
      .filter(line => line["Target name"] === targetGroupName)
      .map(singleLine => {
      let values = [];
      let target = "";
      let type = "";
      for (let key in singleLine) {
        if (key === "Target name") target = singleLine[key] as string;
        else if (key === "Type") type = singleLine[key] as string;
        else values.push(singleLine[key]);
      }
      let color = data.label_metadata[type]?.color;
      if (!color) {
        color = `#${Math.floor(Math.random()*0xFFFFFF).toString(16).padStart(6, '0')}`;
        console.error(`I've picked up the color cause no color were set for ${type}`);
      }
      return {
        label: type,
        data: values,
        borderColor: color
      }
    })
  }, [data.data, targetGroupName]);

  // Format your data to match the structure expected by Chart.js
  const chartData = {
    labels: labels,
    datasets: datasets
  };

  return (
    <Box>
      {
        PLOT_TITLES[pythonElementName] && PLOT_TITLES[pythonElementName].title ?
          <Box mb={1}>
            <TitleWithHorizontalDivider name={PLOT_TITLES[pythonElementName].title as string}/>
          </Box> : ""
      }
      <Line data={chartData}
            options={{
              responsive: true,
              animation: {
                duration: 200
              },
              interaction: {
                mode: 'index',
                intersect: false,
              },
              scales: {
                x: {
                  title: {
                    display: !!PLOT_TITLES[pythonElementName].axis_x,
                    text: PLOT_TITLES[pythonElementName].axis_x as string,
                    font: {
                      size: 16
                    }
                  },
                  min: data.zoom?.min ? data.zoom.min : undefined,
                  max: data.zoom?.max ? data.zoom.max : undefined,
                },
                y: {
                  title: {
                    display: !!PLOT_TITLES[pythonElementName].axis_y,
                    text: PLOT_TITLES[pythonElementName].axis_y as string,
                    font: {
                      size: 16
                    }
                  }
                }
              },
              plugins: {
                legend: {
                  position: 'right',
                  labels: {
                    font: {
                      size: 14,
                    },
                    padding: 10,
                    boxWidth: 17,
                    boxHeight: 1,
                  },
                },
                zoom: {
                  zoom: {
                    wheel: {
                      enabled: true,
                    },
                    pinch: {
                      enabled: true,
                    },
                    mode: "x",
                  },
                },
              },
            }}
      />
    </Box>
  );
};

export interface ISubViewLine {
  data: SingleTabSubViewJson;
  pythonElementName: string;  // represent the python_element name that identifies uniquely a plot/table among all dashboards - like in element_config.json
  targetGroupName?: string | undefined;
}

export default SubViewLine;
