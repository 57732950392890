import {Avatar, Box, Collapse, IconButton, styled, TableCell, TableRow, Tooltip} from '@mui/material';
import React, { useMemo, useState } from 'react';
import {
  DeleteOutlineOutlined,
  EventAvailableRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
  WarningAmberRounded
} from '@mui/icons-material';
import { Report, ReportStatus, UserTeamRole } from '../../../utils/graphql/generatedTS/graphql';
import { format } from 'date-fns';
import SingleRowStatusChip from './SingleRowStatusChip';
import SingleRowReportPreview from './SingleRowReportPreview';
import SingleRowActionMenu from './SingleRowActionMenu';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import { useAuthContext } from '../../../context/AuthContext';
import { useGetCurrentUserTeam } from '../../../hook/useGetCurrentUserTeam';
import { theme } from '../../../GlobalStyles';
import {AvatarGroup} from "@mui/lab";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

/**
 *
 * @param {React.PropsWithChildren<ISingleRow>} props
 * @return {JSX.Element}
 * @constructor
 */

const SingleRow: React.FC<ISingleRow> = ({report}) => {
  const [open, setOpen] = React.useState(false);
  const [showDeleteReportDialog, setShowDeleteReportDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const {idTeam} = useParams();
  const {authenticatedIdUser} = useAuthContext();
  const currentUserTeam = useGetCurrentUserTeam();

  const goToReport = () => {
    if (!idTeam) return;
    if (report.status !== ReportStatus.Succeeded) return;
    navigate(RouteKey.Report.replace(":idTeam", idTeam).replace(":idReport", report.idReport));
  }

  /**
   * Check conditions to enable / disable delete icon
   */


  const enableDeleteReportAction: boolean = useMemo(() => {
    if (!report?.sharedWith) return false;
    if (report.status === ReportStatus.Succeeded || report.status === ReportStatus.EngineFailed || report.status === ReportStatus.PostprocessFailed || report.status.toLowerCase().includes("error")) {
      // report is able to be deleted
      if (currentUserTeam?.role === UserTeamRole.Admin) return true;
      for (let usw of report?.sharedWith) {
        if (usw?.idUser === authenticatedIdUser && authenticatedIdUser !== report.userCreator.idUser)  return false;
      }
      return true;
    } else return false;
  }, [currentUserTeam]);

  /*/ Custom Avatars/*/
  const maxAvatars = 1;

  const CustomAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
    '& .MuiAvatar-root': {
      border: '1px solid white', // Add border to make overlapping clearer
    },
    '& .MuiAvatar-root:not(:first-of-type)': {
      marginLeft: -8, // Adjust the negative margin to control the overlap
    },
  }));

  //backgroundColor: "#ffffff", borderBottom: "6px solid #f8f9fa", borderTop: 'none !important',

  return (
    <React.Fragment>
      <TableRow sx={{  borderTop: '6px solid #f8f9fa', backgroundColor: "#ffffff"   }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row"
                   sx={{
                     cursor: report.status === ReportStatus.Succeeded ? "pointer" : ""
                   }}
                   onClick={goToReport}>
          <Box display={"flex"} alignItems={"flex-start"}>
            {report.campaignName}
            {
              report.hasWarnings &&
                <Tooltip title={"This report contains warnings"} placement={"top"}>
                  <WarningAmberRounded sx={{fontSize: 20, ml: 1, color: theme.palette.warning.main}}/>
                </Tooltip>
            }

          </Box>
        </TableCell>
        <TableCell>
          <Box display={"flex"} alignItems={"center"}>
            {format(new Date(report.updatedAt*1000), 'dd/MM/yyyy HH:mm')}
            {
              !!report.scheduled &&
              <Tooltip title={`@ ${report.scheduled.atHour} until ${report.scheduled.runEverydayUntil}`}>
                <EventAvailableRounded sx={{fontSize: 20, ml: 1}}/>
              </Tooltip>
            }
          </Box>
        </TableCell>
        <TableCell>
          <Box display={"flex"} alignItems={"center"}>
            {report.userCreator.firstName + " " + report.userCreator.lastName}
            {
              report.sharedWith && report.sharedWith.length > 0 ?
                  <Tooltip title={"This report is shared with other people"} placement={"top"}>

                    <CustomAvatarGroup max={maxAvatars}>
                      {report.sharedWith.slice(0, maxAvatars).map((user, index) => (
                          <Avatar
                              key={index}
                              src={"/imgs/user-icon.svg"}
                              style={{
                                width: 22,
                                height: 22,
                                marginLeft: 8,
                                backgroundColor: "white",
                                zIndex: 0,
                                border: "2px solid white" }}
                          />
                      ))}
                      {report.sharedWith.length > maxAvatars && (
                          <Avatar style={{
                            width: 21,
                            height: 21,
                            marginLeft: -12,
                            fontSize: 13,
                            backgroundColor: "gray",
                            zIndex: 1,
                            border: "3px solid white",
                            textAlign: 'center',
                          }}>
                            +{report.sharedWith.length - maxAvatars}
                          </Avatar>
                      )}
                    </CustomAvatarGroup>
                  </Tooltip> : ""

            }
          </Box>
        </TableCell>
        <TableCell><SingleRowStatusChip status={report.status} isScheduled={!!report.scheduled}/></TableCell>

        <TableCell>

          <SingleRowActionMenu report={report} enableDeleteAction={enableDeleteReportAction}/>

          <Tooltip title={showDeleteReportDialog ? "" : enableDeleteReportAction ? "Delete" : "You cannot delete this report"}
                   placement={"top"}>
            <span>
              <IconButton size={"small"}
                          sx={{color: theme.palette.error.main }}
                          onClick={() => setShowDeleteReportDialog(true)}
                          disabled={!enableDeleteReportAction}>
                <DeleteOutlineOutlined/>
              </IconButton>
              <DeleteConfirmDialog open={showDeleteReportDialog} onClose={() => setShowDeleteReportDialog(false)} idReport={report.idReport}/>
            </span>
          </Tooltip>

        </TableCell>
      </TableRow>

      {/* Row where we show the result preview */}
      <TableRow sx={{backgroundColor: "#f8f9fa"}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <SingleRowReportPreview report={report}/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export interface ISingleRow {
  report: Report
}


export default SingleRow;
