import React, { useMemo } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';

import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import { useQuery } from '@apollo/client';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';

import { UserQueries } from '../../../utils/graphql/queries/user';
import {mt} from "date-fns/locale";
import {Info} from "@mui/icons-material";

/**
 *
 * @param {React.PropsWithChildren<ICommonFooter>} props
 * @return {JSX.Element}
 * @constructor
 */
const CommonFooter: React.FC<ICommonFooter> = (props) => {


    const {idTeam} = useParams();
    const navigate = useNavigate();
    const matchDashboard = useMatch(RouteKey.Dashboard.replace(":idTeam", idTeam as string));
    const matchUserManagement = useMatch(RouteKey.UserManagement.replace(":idTeam", idTeam as string));



    return (
        <Box sx={{backgroundImage: 'linear-gradient(to right, #8A5FFD, #5EE0E6)', mt:'30px'}}>
            <Container>
                <Box width={"100%"}
                     display={"flex"} alignItems={"center"}
                     paddingY={1}>
                    <Mailto email="spotonvideo.support@databreeders.com" subject="Assistance Needed" body="Hello, I need assistance with my account">
                        <Button variant={"text"}
                                sx={{width: 290, color:"#FFFFFF", textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                                startIcon={<Info/>}
                                style={{borderRadius:"10px", textTransform: 'lowercase'}}>
                            Having troubles? Contact assistance:
                        </Button>
                    </Mailto>

                    <Typography variant={"h5"}
                                align={"center"}
                                fontSize={12}
                                color={'#FFFFFF'}>
                        spotonvideo.support@databreeders.com
                    </Typography>

                    <Box ml="auto" paddingX={1}> {/* This pushes it to the left */}
                        <Typography
                            variant={"h5"}
                            align={"right"}
                            fontSize={12}
                            color={'#FFFFFF'}
                        >
                            Powered by dataBreeders
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};



export interface ICommonFooter {


}


export default CommonFooter;

const Mailto = ({ email, subject, body, children }: { email: string; subject?: string; body?: string; children: React.ReactNode }) => {
    return (
        <a href={`mailto:${email}?subject=${encodeURIComponent(subject ?? '')}&body=${encodeURIComponent(body ?? '')}`}>
            {children}
        </a>
    );
};

export { Mailto };