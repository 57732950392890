// @ts-ignore
import { Sign } from "../../context/Auth/AuthProvider";


/**
 * Returns the "context" object to pass to the Apollo client while performing a call to the graphQL endpoint
 * @param token
 */
export const generateClientContextWithHeaders = (token?: string) => {
  if (!token) {
    return {
      headers: {
        'authorization': '-'
      }
    }
  } else {
    return {
      headers: {
        'authorization': token
      }
    }
  }
}
