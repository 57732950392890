import React, { useMemo } from 'react';
import { SingleTabSubViewJson } from '../../../utils/constants/ReportTabsType';
import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { PLOT_TITLES } from '../../../utils/constants/ReportTabs';
import TitleWithHorizontalDivider from '../../atom/TitleWithHorizontalDivider/TitleWithHorizontalDivider';

/**
 *
 * @param {React.PropsWithChildren<ISubViewBar>} props
 * @return {JSX.Element}
 * @constructor
 */
const SubViewBar: React.FC<ISubViewBar> = ({data, targetGroupName, pythonElementName}) => {

  const labels = useMemo(() => {
    if (data.data.length === 0) return [];
    const dataKeys = Object.keys(data.data[0]);
    return dataKeys
      .filter(item => !["Target name"].includes(item))  // extract only values
  }, [data.data]);

  const datasets = useMemo(() => {
    if (data.data.length === 0) return [];
    const lineToTakeArray = data.data
      .filter(line => line["Target name"] === targetGroupName);
    if (lineToTakeArray.length !== 1) throw new Error("The dataset has multiple rows with the same Target Name. That's not possible for Bar plots");
    const lineToTake = lineToTakeArray[0];
    let datasets = [{
      label: targetGroupName,
      data: labels.map(label => lineToTake[label]),
      backgroundColor: labels.map(label => {
        let color = data.label_metadata[label]?.color;
        if (!color) {
          color = `#${Math.floor(Math.random()*0xFFFFFF).toString(16).padStart(6, '0')}`;
          console.error(`I've picked up the color cause no color were set for ${label}`);
        }
        return color;
      })
    }];
    return datasets;
  }, [data.data, targetGroupName, labels]);

  // Format your data to match the structure expected by Chart.js
  const chartData = {
    labels: labels, // assuming this is an array of dates
    datasets: datasets // assuming this is an array of datasets
  };

  return (
    <Box>
      {
        PLOT_TITLES[pythonElementName] && PLOT_TITLES[pythonElementName].title ?
          <Box mb={1}>
            <TitleWithHorizontalDivider name={PLOT_TITLES[pythonElementName].title as string}/>
          </Box> : ""
      }
      <Bar data={chartData}
           options={{
             responsive: true,
             interaction: {
               mode: 'index',
               intersect: false,
             },
             scales: {
               x: {
                 title: {
                   display: !!PLOT_TITLES[pythonElementName].axis_x,
                   text: PLOT_TITLES[pythonElementName].axis_x as string,
                   font: {
                     size: 16
                   }
                 }
               },
               y: {
                 title: {
                   display: !!PLOT_TITLES[pythonElementName].axis_y,
                   text: PLOT_TITLES[pythonElementName].axis_y as string,
                   font: {
                     size: 16
                   }
                 }
               }
             },
             plugins: {
               legend: {
                 display: false,
               },
               zoom: {
                 zoom: {
                   wheel: {
                     enabled: true,
                   },
                   pinch: {
                     enabled: true,
                   },
                   mode: "x"
                 },
               },
             },
           }}
      />
    </Box>
  );
};

export interface ISubViewBar {
  data: SingleTabSubViewJson;
  pythonElementName: string;  // represent the python_element name that identifies uniquely a plot/table among all dashboards - like in element_config.json
  targetGroupName?: string | undefined;
}

export default SubViewBar;
