import { Box, CircularProgress, Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { Close } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { UserQueries } from '../../../utils/graphql/queries/user';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import TemaRow from './TemaRow';
import { RouteKey } from '../../../App.Routes';
import { useNavigate } from 'react-router-dom';
import { UserTeam } from '../../../utils/graphql/generatedTS/graphql';
import GradientCircularProgress from "../Dashboard.ReportTable/GradientCircularProgress";

/**
 *
 * @param {React.PropsWithChildren<ICommonSelectTeam>} props
 * @return {JSX.Element}
 * @constructor
 */
const CommonSelectTeam: React.FC<ICommonSelectTeam> = (props) => {

  const auth = useAuthContext();
  const navigate = useNavigate();

  const { data, loading } = useQuery(UserQueries.GET_USER_BY_ID, {
    variables: {
      idUser: props.idUser
    },
    context: generateClientContextWithHeaders(auth.authToken),
    skip: !props.idUser,
  });

  // close the dialog
  const closeDialog = useCallback(() => {
    if (loading) return;
    else props.onClose();
  }, [loading]);

  /**
   * Go to the team page and close the dialog
   */
  const onTeamSelection = (idTeam: string) => {
    navigate(RouteKey.Dashboard.replace(":idTeam", idTeam));
    closeDialog();
  }

  return (
    <Dialog
        open={props.show}
        onClose={closeDialog}
        PaperProps={{
          style: {
            borderRadius: '10px',
            opacity: 0.9,

          },
        }}
    >
      {
        loading ?
          <DialogContent style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <GradientCircularProgress/>
          </DialogContent>
          :
          <>
            <IconButton
              onClick={closeDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
            <DialogContent style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
              <Box display={"flex"} mt={4}>
                <img src={"/imgs/user-icon.svg"} style={{width: 75}}/>
              </Box>
              <Typography variant={"h3"} mt={2} sx={{fontFamily: 'Montserrat'}}>
                Hi, {data?.getUserById?.firstName}!
              </Typography>
              <Typography variant={"subtitle2"} mt={1} color={"textSecondary"} sx={{fontFamily: 'Montserrat', maxWidth: '350px', textAlign: 'center'}} >
                Welcome back! Please choose the team you want to log in with
              </Typography>
              <Box sx={{overflowY: "auto", borderRadius: 2, width: '380px'}} mt={2}>
                {
                  data?.getUserById?.teams?.map((t, pos) =>
                    <TemaRow key={t?.team.idTeam} team={t as UserTeam} position={pos+1} onClick={() => onTeamSelection(t?.team.idTeam as string)}/>
                  )
                }
              </Box>
            </DialogContent>
          </>
      }
    </Dialog>
  );
};

export interface ICommonSelectTeam {
  idUser: string;
  show: boolean;
  onClose: () => void;
}

export default CommonSelectTeam;
