import React, { useCallback } from 'react';
import { Target, TargetSex } from '../../../utils/graphql/generatedTS/graphql';
import {
  Avatar,
  Box,
  Chip,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { ClearRounded, Delete } from '@mui/icons-material';
import { theme } from '../../../GlobalStyles';
import { AGE_BREAKS, AGE_BREAKS_LIST } from '../../../utils/constants/AgeBreaks';
import MandatoryAsterisk from '../../atom/MandatoryAsterisk/MandatoryAsterisk';

/**
 *
 * @param {React.PropsWithChildren<ITargetRow>} props
 * @return {JSX.Element}
 * @constructor
 */
const TargetRow: React.FC<ITargetRow> = (props) => {

  // set the target name
  const setTargetName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    props.setTarget({
      ...props.target,
      targetName: e.target.value
    });
  }, [props.target, props.setTarget]);

  // add target ageBreaks
  const addTargetAgeBreaks = useCallback((ab: AGE_BREAKS) => {
    if (!props.target.ageBreak) return;
    const currentAgeBreaks = [...props.target.ageBreak];
    currentAgeBreaks.push(ab);
    props.setTarget({
      ...props.target,
      ageBreak: currentAgeBreaks
    });
  }, [props.target, props.setTarget]);

  // remove target ageBreaks
  const removeTargetAgeBreaks = useCallback((ab: AGE_BREAKS) => {
    if (!props.target.ageBreak) return;
    const currentAgeBreaks = props.target.ageBreak.filter(ageBreak => ageBreak !== ab);
    props.setTarget({
      ...props.target,
      ageBreak: currentAgeBreaks
    });
  }, [props.target, props.setTarget]);

  // set target sex
  const setTargetSex = useCallback((sex: TargetSex) => {
    if (!props.target.sex) return;
    props.setTarget({
      ...props.target,
      sex: sex,
    });
  }, [props.target, props.setTarget]);

  // set default target sex (shortcut to set all ages)
  const setDefaultTarget = useCallback((defautlTarget: "A3+" | "M3+" | "F3+") => {
    let target: TargetSex;
    switch (defautlTarget) {
      case "A3+":
        target = TargetSex.Both;
        break;
      case "M3+":
        target = TargetSex.M;
        break;
      case "F3+":
        target = TargetSex.F;
        break;
    }
    if (!target) return;
    props.setTarget({
      ...props.target,
      ageBreak: Object.values(AGE_BREAKS),
      sex: target,
    });
  }, [props.target, props.setTarget]);

  return (
    <Box display={"flex"} alignItems={"flex-start"} paddingY={2}>
      <Avatar sx={{ width: 24, height: 24, fontSize: 14,
        backgroundColor: "#212529", fontWeight: "bold", mt: 1 }}>
        {props.position+1}
      </Avatar>
      <TextField label={!!props.target.targetName ? <span>Target Name<MandatoryAsterisk/></span> : ""}
                 placeholder={"Type Target Name"}
                 variant="outlined"
                 size={"small"}
                 type={"text"}
                 value={props.target.targetName}
                 onChange={setTargetName}
                 sx={{ml: 2, '& .MuiOutlinedInput-root': {'& fieldset': {borderRadius: '10px',}}}}
      />

      <Box display={"flex"} flexDirection={"column"}
           border={`1px solid ${theme.palette.text.disabled}`} borderRadius={5}
           marginX={2} padding={2}
      >
        <Typography variant="h6" fontWeight={600}>Segment</Typography>
        <Typography variant={"body1"}>Default target</Typography>
        <RadioGroup
          row
        >
          <FormControlLabel value="W3+"
                            control={<Radio color={"secondary"} size={"small"}
                                            onClick={() => setDefaultTarget("F3+")}
                                            checked={props.target.ageBreak?.length === Object.keys(AGE_BREAKS).length && props.target.sex === TargetSex.F}/>}
                            label="W3+"/>
          <FormControlLabel value="M"
                            control={<Radio color={"secondary"} size={"small"}
                                            onClick={() => setDefaultTarget("M3+")}
                                            checked={props.target.ageBreak?.length === Object.keys(AGE_BREAKS).length && props.target.sex === TargetSex.M}/>}
                            label="M3+"/>
          <FormControlLabel value="A3+"
                            control={<Radio color={"secondary"} size={"small"}
                                            onClick={() => setDefaultTarget("A3+")}
                                            checked={props.target.ageBreak?.length === Object.keys(AGE_BREAKS).length && props.target.sex === TargetSex.Both}/>}
                            label="A3+"/>
        </RadioGroup>

        <Box display={"flex"} mt={2}>
          {/* Age BReaks part*/}
          <Box display={"flex"} flexDirection={"column"} flexShrink={1}>
            <Typography variant={"body1"}>Age breaks<MandatoryAsterisk/></Typography>
            <Box display={"flex"} flexShrink={1} flexWrap={"wrap"}>
              {
                AGE_BREAKS_LIST.map(ab => (
                  <Chip label={ab.replace("_", "-")} size={"small"}
                        color={"primary"}
                        key={ab}
                        variant={props.target.ageBreak?.includes(ab) ? 'filled' : 'outlined'}
                        sx={{
                          color: props.target.ageBreak?.includes(ab) ? 'white' : undefined,
                          mr: 0.5,
                          mt: 1,
                          fontSize: 12
                        }}
                        onClick={() => {props.target.ageBreak?.includes(ab) ? removeTargetAgeBreaks(ab) : addTargetAgeBreaks(ab)}}
                        deleteIcon={<ClearRounded />}
                  />
                ))
              }
            </Box>
            <Typography variant={"body2"} sx={{color: theme.palette.text.secondary}} mt={0.5}>
              Please select one or more age breaks
            </Typography>
          </Box>
          {/*Sex part */}
          <Box display={"flex"} flexDirection={"column"} ml={2} flexShrink={1}>
            <Typography variant={"body1"}>Sex<MandatoryAsterisk/></Typography>
            <RadioGroup
              row
            >
              <FormControlLabel value="W"
                                control={<Radio color={"secondary"} size={"small"}
                                                onClick={() => setTargetSex(TargetSex.F)}
                                                checked={props.target.sex === TargetSex.F}/>}
                                label="W"/>
              <FormControlLabel value="M"
                                control={<Radio color={"secondary"} size={"small"}
                                                onClick={() => setTargetSex(TargetSex.M)}
                                                checked={props.target.sex === TargetSex.M}/>}
                                label="M"/>
              <FormControlLabel value="Both"
                                control={<Radio color={"secondary"} size={"small"}
                                                onClick={() => setTargetSex(TargetSex.Both)}
                                                checked={props.target.sex === TargetSex.Both}/>}
                                label="Both"/>
            </RadioGroup>
            <Typography variant={"body2"} sx={{color: theme.palette.text.secondary}} mt={0.5}>
              Please choose an option
            </Typography>
          </Box>

        </Box>

      </Box>

      {/* Delete row only if it's not the first row*/}
      {
        props.position > 0 ?
          <Box ml={2}>
            <IconButton color={"error"} onClick={props.deleteTarget}>
              <Delete/>
            </IconButton>
          </Box>
          :
          ""
      }
    </Box>
  );
};

export interface ITargetRow {
  position: number;
  target: Target;
  setTarget: (target: Target) => void;
  deleteTarget: () => void;
}

export default TargetRow;
