import { graphql } from "../generatedTS";

const GET_TEAM_USERS = graphql(`
    query GetTeamUsers($idTeam: String!) {
        getTeamUsers(idTeam: $idTeam) {
            ...TeamUserFull
        }
    }
`);

export const TeamQueries = {
  GET_TEAM_USERS,
}
