import { useLazyQuery } from '@apollo/client';
import { ReportQueries } from '../utils/graphql/queries/report';
import { generateClientContextWithHeaders } from '../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../context/AuthContext';

export type UseDownloadExcelExportType = {
  idReport: string;
}

export const useDownloadExcelExport = ({idReport}: UseDownloadExcelExportType): { getFile: () => Promise<void> } => {
  const {authToken} = useAuthContext();

  const [getFileQuery, {data: fileUrlQuery}] = useLazyQuery(ReportQueries.REPORT_POSTPROCESS_FILE, {
    variables: {
      idReport,
      idPostProcess: 'excel_output.xlsx',
    },
    fetchPolicy: 'network-only',
    context: generateClientContextWithHeaders(authToken),
  });

  const getFile = async () => {
    if (!authToken || !idReport) return;
    const fileUrl = await getFileQuery();
    if (fileUrl) {
      window.open(fileUrl.data?.getReportPostprocessFile, "_blank");
    }
  };

  return {
    getFile
  }
}
