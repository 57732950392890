import { TypePolicies } from "@apollo/client";

/**
 * Since we use custom IDs, for each object added, we define the custom ID here for that type of object, to avoid
 * issues with the cache. See https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
 */
export const InMemoryCacheOptions: TypePolicies | undefined = {
  Query: {
    fields: {
      getUserReportList: {
        keyArgs: false,
        merge: (existing, incoming, options) => {
          // return base object if another does not exist
          if (!existing) {
            return incoming;
          }
          if (!incoming) {
            return existing;
          }
          // if we have nextToken, then we have to append and update the nextToken
          let existingUpdated = { ...existing };
          if (!!options.args?.nextToken) {
            existingUpdated.reportList = [...existingUpdated.reportList, ...incoming.reportList];
            existingUpdated.nextToken = incoming.nextToken;
          } else {
            // if we don't have a nextToken in the call, this is a refetch for newest incoming transactions.
            // Let's put new values on top and remove duplicates
            existingUpdated.reportList = [...incoming.reportList, ...existingUpdated.reportList];
            // remove duplicates
            const seen = new Set();
            existingUpdated.reportList = existingUpdated.reportList.filter((i: {__ref: string}) => {
              const refValue = i.__ref;
              if (!seen.has(refValue)) {
                seen.add(refValue);
                return true;
              }
              return false;
            });
          }
          return existingUpdated;
        },
      },
      getTeamReportList: {
        keyArgs: false,
        merge: (existing, incoming, options) => {
          // return base object if another does not exist
          if (!existing) {
            return incoming;
          }
          if (!incoming) {
            return existing;
          }
          // if we have nextToken, then we have to append and update the nextToken
          let existingUpdated = { ...existing };
          if (!!options.args?.nextToken) {
            existingUpdated.reportList = [...existingUpdated.reportList, ...incoming.reportList];
            existingUpdated.nextToken = incoming.nextToken;
          } else {
            // if we don't have a nextToken in the call, this is a refetch for newest incoming transactions.
            // Let's put new values on top and remove duplicates
            existingUpdated.reportList = [...incoming.reportList, ...existingUpdated.reportList];
            // remove duplicates
            const seen = new Set();
            existingUpdated.reportList = existingUpdated.reportList.filter((i: {__ref: string}) => {
              const refValue = i.__ref;
              if (!seen.has(refValue)) {
                seen.add(refValue);
                return true;
              }
              return false;
            });
          }
          return existingUpdated;
        },
      }
    }
  },
  Report: {
    keyFields: ["idReport"]
  },
  Team: {
    keyFields: ["idTeam"]
  },
  User: {
    keyFields: ["idUser"]
  },
  UserPreview: {
    keyFields: ["idUser"]
  }
}
