import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNewReportContext } from '../../../context/NewReportContext';
import { useNavigate, useParams } from 'react-router-dom';
import { AddBoxOutlined, QueryBuilderRounded } from '@mui/icons-material';
import { theme } from '../../../GlobalStyles';
import SpotRow from './SpotRow';
import { AddReportInput, Filter, Spot, SpotInput, Target, TargetSex } from '../../../utils/graphql/generatedTS/graphql';
import TargetRow from './TargetRow';
import MandatoryAsterisk from '../../atom/MandatoryAsterisk/MandatoryAsterisk';
import { format, isValid, parse } from 'date-fns';
import { useMutation, useQuery } from '@apollo/client';
import { ReportMutations } from '../../../utils/graphql/mutations/report';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import { RouteKey } from '../../../App.Routes';
import { StringParam, useQueryParam } from 'use-query-params';
import { ReportQueries } from '../../../utils/graphql/queries/report';
import { useSnackbar } from 'notistack';
import FilterRow from './FilterRow';
import ScheduleReportDialog from './ScheduleReportDialog';
import GradientCircularProgress from "../Dashboard.ReportTable/GradientCircularProgress";

/**
 * Section where to add details for a new report (or editing an existing report)
 * @param {React.PropsWithChildren<INewReportForm>} props
 * @return {JSX.Element}
 * @constructor
 */
const NewReportForm: React.FC<INewReportForm> = (props) => {
  const [sendRequestEnabled, setSendRequestEnabled] = useState<boolean>(false);
  const [spotgateRepeatDates, setSpotgateRepeatDates] = useState<boolean>(false);
  const [showScheduleReportDialog, setShowScheduleReportDialog] = useState<boolean>(false);
  const newReport = useNewReportContext();
  const {authToken} = useAuthContext();
  const {idTeam} = useParams();
  const navigate = useNavigate();
  const [fromReport, setFromReport] = useQueryParam('from', StringParam);
  const [action, setAction] = useQueryParam('action', StringParam);
  const { enqueueSnackbar } = useSnackbar();

  const [addReportMutation, {}] = useMutation(ReportMutations.ADD_REPORT, {
    context: generateClientContextWithHeaders(authToken)
  });

  const [editReportMutation, {}] = useMutation(ReportMutations.EDIT_REPORT, {
    context: generateClientContextWithHeaders(authToken)
  });

  // query to get the report
  const {data: report, loading: reportLoading, error: errorReport} = useQuery(ReportQueries.GET_REPORT_BY_ID, {
    variables: {idReport: fromReport as string},
    context: generateClientContextWithHeaders(authToken),
    skip: !fromReport || !authToken,
  })

  useEffect(() => {
    if (errorReport) {
      enqueueSnackbar("Error getting the report", {variant: 'error'});
    }
  }, [errorReport]);

  // initialize the report
  useEffect(() => {
    if (!idTeam) return;
    if (report?.getReportById)
      newReport.initializeNewReportFromReport(idTeam, report.getReportById);
    else newReport.initializeNewEmptyReport(idTeam);
  }, [idTeam, report?.getReportById]);

  // if no spot are present, add one
  useEffect(() => {
    if (!!newReport.newReportInput && newReport.newReportInput?.spotList?.length === 0)
      updateSpot(0, {id: "", periodEnd: "", periodStart: ""});
  }, [newReport]);

  // if no target are present, add one
  useEffect(() => {
    if (!!newReport.newReportInput && newReport.newReportInput?.targetList?.length === 0)
      updateTarget(0, {targetName: "", sex: TargetSex.Both, ageBreak: []});
  }, [newReport]);

  // check if we have all the details and set/disable the button to send the request to generate a new report
  useEffect(() => {
    const r = newReport.newReportInput;
    console.log('r', r)
    // check the name
    if (!r?.campaignName) {setSendRequestEnabled(false); return;}
    // check spotgate
    if (!r?.spotList || r.spotList?.length === 0) {setSendRequestEnabled(false); return;}
    for(let s of r?.spotList) {
      if (!s.id || !s.periodStart || !s.periodEnd) {setSendRequestEnabled(false); return;}
      // check the name of spotgate code is valid
      if (!/^[sS][gG]\d{1,10}$/.test(s.id)) { setSendRequestEnabled(false); return; }
      // check if the start date or end date are valid dates
      const parsedStartDate = parse(s.periodStart, 'yyyy-MM-dd', new Date());
      if (!isValid(parsedStartDate) || format(parsedStartDate, 'yyyy-MM-dd') !== s.periodStart) {setSendRequestEnabled(false); return;}
      const parsedEndDate = parse(s.periodEnd, 'yyyy-MM-dd', new Date());
      if (!isValid(parsedEndDate) || format(parsedEndDate, 'yyyy-MM-dd') !== s.periodEnd) {setSendRequestEnabled(false); return;}
      // check if startDate is before EndDate
      if (parsedStartDate > parsedEndDate) {setSendRequestEnabled(false); return;}
    }
    //check target
    if (!r?.targetList || r.targetList?.length === 0) {setSendRequestEnabled(false); return;}
    for(let t of r?.targetList) {
      if (!t.targetName || !t.sex || !t.ageBreak || t.ageBreak.length === 0) {setSendRequestEnabled(false); return;}
    }
    setSendRequestEnabled(true);
  }, [newReport.newReportInput]);

  /**
   * Updates the given spot in the context
   * @param position
   * @param spot
   */
  const updateSpot = (position: number, spot: Spot) => {
    newReport.setSpotList(position, spot);
  }

  /**
   * Adds a new empty spotgate to the list
   */
  const addSpotgate = () => {
    if (!newReport.newReportInput?.spotList) return;
    newReport.setSpotList(newReport.newReportInput?.spotList?.length, {id: "", periodEnd: "", periodStart: ""})
  }

  /**
   * Deletes a spotgate from the context list
   */
  const deleteSpotgate = (position: number) => {
    if (!newReport.newReportInput?.spotList) return;
    newReport.deleteSpotFromList(position);
  }

  /**
   * Updates the given target in the context
   * @param position
   * @param target
   */
  const updateTarget = (position: number, target: Target) => {
    newReport.setTargetList(position, target);
  }

  /**
   * Adds a new empty target to the list
   */
  const addTarget = () => {
    if (!newReport.newReportInput?.targetList) return;
    newReport.setTargetList(newReport.newReportInput?.targetList?.length, {targetName: "", sex: TargetSex.Both, ageBreak: []})
  }

  /**
   * Deletes a target from the context list
   */
  const deleteTarget = (position: number) => {
    if (!newReport.newReportInput?.targetList) return;
    newReport.deleteTargetFromList(position);
  }

  /**
   * Updates the filters for this run
   * @param filter
   */
  const updateFilter = (filter: Filter) => {
    newReport.setFilter(filter);
  }

  /**
   * Turn on/off the repeat date feature, setting the dates of the other elements to the first one when turned off
   */
  const toggleRepeatDate = () => {
    setSpotgateRepeatDates(!spotgateRepeatDates);
    if (!spotgateRepeatDates && newReport.newReportInput?.spotList && newReport.newReportInput?.spotList?.length > 0) {
      newReport.setSpotListWithRepeatDate({
        ...newReport.newReportInput?.spotList[0]
      })
    }
  }
  useEffect(() => {
    if (spotgateRepeatDates && newReport.newReportInput?.spotList && newReport.newReportInput?.spotList?.length > 0) {
      newReport.setSpotListWithRepeatDate({
        ...newReport.newReportInput?.spotList[0]
      });
    }
  }, [newReport.newReportInput?.spotList, spotgateRepeatDates]);


  const trimTargetName = (name : String) => {
    return name.trim();
  };


  /**
   * Adds a report to the backend - or edits an existing one
   */
  const addReport = async () => {
    if (!newReport.newReportInput || !idTeam) return;

    // Check if targetList is present and not null
    if (!newReport.newReportInput.targetList) {
      enqueueSnackbar("Target list is empty.", { variant: 'warning' });
      return;
    }

    // Trim target names
    newReport.newReportInput.targetList.forEach((target) => {
      target.targetName = trimTargetName(target.targetName);
    });

    // Check for duplicate target names
    const targetNames = newReport.newReportInput.targetList.map((target) => target.targetName);
    const hasDuplicateTargetNames = new Set(targetNames).size !== targetNames.length;

    if (hasDuplicateTargetNames) {
      enqueueSnackbar("Duplicate target names are not allowed.", { variant: 'warning' });
      return;
    }

    let newReportToSend = newReport.newReportInput;
    // change the date format as expected by backend
    newReportToSend.spotList = newReportToSend.spotList?.map((s: SpotInput) : SpotInput => ({
      id: s.id,
      periodStart: s.periodStart,
      periodEnd: s.periodEnd,
    }));
    if (!action || action === 'duplicate') {
      // generate a new report
      await addReportMutation({
        variables: {
          addReportInput: newReport.newReportInput
        }
      });
    } else if (fromReport && action === 'edit') {
      // edit the report selected
      await editReportMutation({
        variables: {
          editReportInput: {
            idReport: fromReport,
            filter: newReport.newReportInput.filter,
            spotList: newReport.newReportInput.spotList,
            targetList: newReport.newReportInput.targetList,
            drillDownBySpotgate: newReport.newReportInput.drillDownBySpotgate,
            schedule: newReport.newReportInput.schedule,
          }
        }
      })
    }

    navigate(RouteKey.Dashboard.replace(":idTeam", idTeam));
  }


  return (
    !!fromReport && reportLoading ?
      <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"} height={200}>
        <GradientCircularProgress/>
      </Box>
      :
      <Box>
        {/* Campaign name row with schedule*/}
        <Box display={"flex"} justifyContent={"space-between"}>
          <TextField label={!!newReport.newReportInput?.campaignName ? <span>Campaign's name<MandatoryAsterisk/></span> : ""}
                     placeholder="Campaign's name"
                     variant="outlined"
                     size={"small"}
                     type={"text"}
                     disabled={action === 'edit'}
                     value={!newReport.newReportInput?.campaignName ? "" : newReport.newReportInput?.campaignName}
                     onChange={(e) => newReport.setCampaignName(e.target.value)}
                     sx={{
                       minWidth: 295, '& .MuiOutlinedInput-root': {'& fieldset': {borderRadius: '10px',}}}}
          />
          <Button startIcon={<QueryBuilderRounded/>}
                  variant={"contained"}
                  onClick={() => setShowScheduleReportDialog(true)}
                  style={{ fontWeight: 'bold', fontSize: '14px'}}
                  sx={{
                    color: newReport.newReportInput?.schedule? undefined : theme.palette.text.primary,
                    backgroundColor: newReport.newReportInput?.schedule ? theme.palette.success.main : "#FFC107",
                    ':hover': {
                      backgroundColor: newReport.newReportInput?.schedule ? theme.palette.success.main : "#FFC107"
                    }
                  }}>
            {
              newReport.newReportInput?.schedule ? "Scheduled" : "Schedule Report"
            }
          </Button>
        </Box>

        {/* Spot row */}
        <Box width={"100%"} mt={6}>
          <Typography variant={"h5"} fontWeight={600}>SPOT</Typography>
          <Box width={"100%"} sx={{backgroundColor: "#324158"}} height={2} mt={0.5}/>
          {
            newReport.newReportInput?.spotList?.map((s, pos) => (
              <SpotRow key={pos} position={pos} spot={s}
                       setSpot={(sTmp) => updateSpot(pos, sTmp)}
                       setSpotWithRepeatDate={(s: Spot) => newReport.setSpotListWithRepeatDate(s)}
                       deleteSpot={() => deleteSpotgate(pos)}
                       repeatDates={spotgateRepeatDates}
                       toggleRepeatDate={toggleRepeatDate}
                       drilldownBySpotgate={pos === 0 ? newReport.newReportInput?.drillDownBySpotgate : undefined}
                       setDrilldownBySpotgate={newReport.setDrilldownBySpotgate}
              />
            ))
          }
          <Button startIcon={<AddBoxOutlined/>} variant={"contained"} color={"secondary"} style={{ fontWeight: 'bold', fontSize: '14px'}} onClick={addSpotgate}>
            Add Spotgate code
          </Button>
        </Box>

        {/* Target row */}
        <Box mt={5}>
          <Typography variant={"h5"} fontWeight={600}>TARGET</Typography>
          <Box width={"100%"} sx={{backgroundColor: "#324158"}} height={2} mt={0.5}/>
          {
            newReport.newReportInput?.targetList?.map((t, pos) => (
              <TargetRow key={pos} position={pos} target={t}
                         setTarget={(tTmp) => updateTarget(pos, tTmp)}
                         deleteTarget={() => deleteTarget(pos)}
              />
            ))
          }
          <Button style={{ fontWeight: 'bold', fontSize: '14px'}} startIcon={<AddBoxOutlined/>} variant={"contained"} color={"secondary"} onClick={addTarget}>
            Add Target
          </Button>
        </Box>

        {/* Filters row */}
        <Box mt={5}>
          <Typography variant={"h5"} fontWeight={600}>FILTERS</Typography>
          <Box width={"100%"} sx={{backgroundColor: "#324158"}} height={2} mt={0.5}/>
          <FilterRow filter={newReport.newReportInput?.filter as Filter} updateFilter={updateFilter}/>
        </Box>

        {/* Send request button */}
        <Box mt={4} display={"flex"} justifyContent={"flex-end"}>
          <Button variant={"contained"} disabled={!sendRequestEnabled} style={{ fontWeight: 'bold', fontSize: '14px'}} onClick={addReport}>
            {action === 'edit' ? 'Edit' : 'Generate'} Report
          </Button>
        </Box>

        {/* Schedule Report Dialog */}
        <ScheduleReportDialog
          report={newReport.newReportInput as AddReportInput}
          open={showScheduleReportDialog}
          onClose={() => setShowScheduleReportDialog(false)}
        />
      </Box>
  );
};

export interface INewReportForm {

}

export default NewReportForm;
