import React, { useState } from 'react';
import { TeamUser, UserTeamRole } from '../../../utils/graphql/generatedTS/graphql';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { DeleteOutlineOutlined, ReplayRounded } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { UserMutations } from '../../../utils/graphql/mutations/user';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useSnackbar } from 'notistack';
import DeleteConfirmDialog from './DeleteConfirmDialog';

/**
 *
 * @param {React.PropsWithChildren<ISingleUserRow>} props
 * @return {JSX.Element}
 * @constructor
 */
const SingleUserRow: React.FC<ISingleUserRow> = ({user}) => {

  const { enqueueSnackbar } = useSnackbar();
  const [showDeleteConfirmUser, setShowDeleteConfirmUser] = useState<boolean>(false);

  const [resetUserPasswordMutation, {
    loading: loadingResetPassword,
    error: errorResetPassword
  }] = useMutation(UserMutations.RESET_USER_PASSWORD, {
    context: generateClientContextWithHeaders(),
  });

  /**
   * Function to call the mutation and request a password reset
   */
  const resetUserPassword = async () => {
    try {
      await resetUserPasswordMutation({
        variables: {
          email: user.email
        }
      });
      enqueueSnackbar(`User ${user.email} removed`, {variant: 'success'});
    } catch (e) {
      enqueueSnackbar(`An error has occurred when removing ${user.email} - please try again`, {variant: "error"});
    }
  }

  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: "#f2f2f2" }}>
      <TableCell>
        {user.firstName}
      </TableCell>
      <TableCell>
        {user.lastName}
      </TableCell>
      <TableCell>
        {user.email}
      </TableCell>
      <TableCell>
        {format(new Date(user.addedAt*1000), 'dd/MM/yyyy')}
      </TableCell>
      <TableCell sx={{textTransform: "capitalize"}}>
        {user.role.toLowerCase()}
      </TableCell>
      <TableCell>
        <Tooltip title={"Reset user password"}>
          <IconButton size={"small"} disabled={loadingResetPassword} onClick={resetUserPassword}>
            <ReplayRounded/>
          </IconButton>
        </Tooltip>
        <IconButton size={"small"} color={"error"} disabled={user.role === UserTeamRole.Admin} onClick={() => setShowDeleteConfirmUser(true)}>
          <DeleteOutlineOutlined/>
        </IconButton>
        <DeleteConfirmDialog idUser={user.idUser} userEmail={user.email} open={showDeleteConfirmUser} onClose={() => setShowDeleteConfirmUser(false)}/>
      </TableCell>
    </TableRow>
  );
};

export interface ISingleUserRow {
  user: TeamUser
}

export default SingleUserRow;
