import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { AuthContextValue } from './AuthContextType';
import { User } from '../utils/graphql/generatedTS/graphql';
import { useLocalStorage } from 'react-use';

// Create Context
export const AuthContext =
  createContext<AuthContextValue>({
    authToken: undefined,
    authExpiresAt: 0,
    authenticatedIdUser: undefined,
    isAuthenticated: false,
    authStatusLoading: true,
    setToken: undefined,
    deleteAuthToken: () => {}
  });

interface AuthProviderProps {
  children: ReactNode;
}

// Provider
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const [authExpiresAt, setAuthExpiresAt] = useState<number | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authStatusLoading, setAuthStatusLoading] = useState<boolean>(true);
  const [authenticatedIdUser, setAuthenticatedIdUser] = useState<string | undefined>(undefined);

  const [jwtTokenLs, setJwtTokenLs, removeJwtTokenLs] = useLocalStorage('jwtToken', '');
  const [refreshTokenLs, setRefreshTokenLs, removeRefreshTokenLs] = useLocalStorage('refreshToken', '');
  const [idUserLs, setIdUserLs, removeIdUserLs] = useLocalStorage('authIdUser', '');
  const [expiresTokenLs, setExpiresTokenLs, removeExpireTokenLs] = useLocalStorage('expiresToken', 0);

  // store the token after authentication
  const setToken = async (jwtToken: string, refreshToken: string, user: User, expiresIn: number, store: boolean) => {
    const expiresAt = (new Date().getTime() / 1000) + expiresIn;
    if (store) {
      setJwtTokenLs(jwtToken);
      setRefreshTokenLs(refreshToken);
      setIdUserLs(user.idUser);
      setExpiresTokenLs(expiresAt);
    }
    setAuthenticatedIdUser(user.idUser);
    setAuthToken(jwtToken);
    setAuthExpiresAt(expiresAt);
    setIsAuthenticated(true);
  }

  // reading from storage is sync, so the auth loading is considered complete when this component loads
  useEffect(() => {
    const currentTime = new Date().getTime() / 1000;
    if (!!jwtTokenLs && !!expiresTokenLs && expiresTokenLs > (currentTime + 3600)) {
      setAuthenticatedIdUser(idUserLs);
      setAuthToken(jwtTokenLs);
      setAuthExpiresAt(expiresTokenLs);
      setIsAuthenticated(true);
    }
    setAuthStatusLoading(false);
  }, []);

  /**
   * remove the auth token stored
   */
  const deleteAuthToken = () => {
    removeJwtTokenLs();
    removeRefreshTokenLs();
    removeIdUserLs();
    removeExpireTokenLs();
    setAuthenticatedIdUser(undefined);
    setAuthToken(undefined);
    setAuthExpiresAt(0);
    setIsAuthenticated(false);
  };

  // generate the context for the provider
  const contextValue: AuthContextValue = {
    authToken,
    authExpiresAt,
    authenticatedIdUser,
    isAuthenticated,
    authStatusLoading,
    setToken,
    deleteAuthToken
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
