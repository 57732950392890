import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { UserQueries } from '../utils/graphql/queries/user';
import { generateClientContextWithHeaders } from '../utils/graphql/graphQLsupportFunction';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { UserTeam } from '../utils/graphql/generatedTS/graphql';

/**
 * Returns the `UserTeam` object currently selected by the user
 */
export const useGetCurrentUserTeam = (): (UserTeam | undefined | null) => {

  const {idTeam} = useParams();
  const {authenticatedIdUser, authToken} = useAuthContext();
  const { data, loading } = useQuery(UserQueries.GET_USER_BY_ID, {
    variables: {
      idUser: authenticatedIdUser as string
    },
    context: generateClientContextWithHeaders(authToken),
    skip: !authenticatedIdUser,
  });

  // get the current UserTeam selected by the user, so we can get his role
  const currentUserTeam = useMemo(() => {
    return data?.getUserById?.teams?.find(t => t?.team.idTeam === idTeam);
  }, [data?.getUserById, idTeam]);

  return currentUserTeam;
}
