import React, { createContext, useContext, useState } from 'react';
import { ReportFileCacheContextValue } from './ReportFileCacheContextType';
import { SingleTabSubViewJson, SummaryTargetUniversal } from '../utils/constants/ReportTabsType';

// Create Context
export const ReportFileCacheContext =
  createContext<ReportFileCacheContextValue>({
    cachedContent: {},
    setSingleFileCache: () => {}
  });

interface ReportFileCacheProviderProps {
  children: React.ReactNode;
}

// Provider
export const ReportFileCacheProvider: React.FC<ReportFileCacheProviderProps> = ({ children }) => {
  const [cachedContent, setCachedContent] = useState<{[key: string]: SingleTabSubViewJson | SummaryTargetUniversal}>({});

  /**
   * Sets a new item
   * @param idPostProcess
   * @param item
   */
  const setSingleFileCache = (idPostProcess: string, item: SingleTabSubViewJson | SummaryTargetUniversal) => {
    setCachedContent(prevContent => ({
      ...prevContent,
      [idPostProcess]: prevContent[idPostProcess] ? prevContent[idPostProcess] : item
    }));
  }

  // generate the context for the provider
  const contextValue: ReportFileCacheContextValue = {
    cachedContent,
    setSingleFileCache
  };

  return (
    <ReportFileCacheContext.Provider value={contextValue}>
      {children}
    </ReportFileCacheContext.Provider>
  );
};

export const useReportFileCacheContext = () => useContext(ReportFileCacheContext);
