export enum AGE_BREAKS {
  "03_14" = "03_14",
  "15_24" = "15_24",
  "25_34" = "25_34",
  "35_44" = "35_44",
  "45_54" = "45_54",
  "55_64" = "55_64",
  "65_plus" = "65_plus",
}

export const AGE_BREAKS_LIST = [
  AGE_BREAKS['03_14'], AGE_BREAKS['15_24'], AGE_BREAKS['25_34'], AGE_BREAKS['35_44'], AGE_BREAKS['45_54'],
  AGE_BREAKS['55_64'], AGE_BREAKS['65_plus']
]
