import React from 'react';
import { Box, Typography } from '@mui/material';

/**
 * Element representing a title, with a divider right below
 * @param {React.PropsWithChildren<ITitleWithHorizontalDivider>} props
 * @return {JSX.Element}
 * @constructor
 */
const TitleWithHorizontalDivider: React.FC<ITitleWithHorizontalDivider> = (props) => {
  return (
    <>
      <Typography variant={"h5"} fontWeight={600}>{props.name}</Typography>
      <Box width={"100%"} sx={{backgroundColor: "#324158", borderRadius: '10px'}} height={2} mt={0.5} mb={3}/>
    </>
  );
};

export interface ITitleWithHorizontalDivider {
  name: string;
}

export default TitleWithHorizontalDivider;
