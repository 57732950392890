import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    customColors: {
      dark1: React.CSSProperties['color'];
      dark2: React.CSSProperties['color'];
      dark3: React.CSSProperties['color'];
      dark4: React.CSSProperties['color'];
      dark5: React.CSSProperties['color'];
    };
  }

  interface ThemeOptions {
    customColors: {
      dark1: React.CSSProperties['color'];
      dark2: React.CSSProperties['color'];
      dark3: React.CSSProperties['color'];
      dark4: React.CSSProperties['color'];
      dark5: React.CSSProperties['color'];
    };
  }
}

const dark1 = "#121417";
const dark2 = "#1B1D20";
const dark3 = "#21252C";
const dark4 = "#2D2E31";
const dark5 = "#697077";

export const theme = createTheme({
  customColors: {
    dark1: dark1,
    dark2: dark2,
    dark3: dark3,
    dark4: dark4,
    dark5: dark5,
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Arimo',
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 35,
      fontWeight: 400,
      fontFamily: "Montserrat",
      color: "#324158",
    },
    h2: {
      fontSize: 28,
      fontFamily: "Montserrat",
      color: "#324158",
    },
    h3: {
      fontSize: 24,
      fontFamily: "Montserrat",
      color: "#324158",
    },
    h4: {
      fontSize: 20,
      fontFamily: "Montserrat",
      color: "#324158",
    },
    h5: {
      fontSize: 16,
      fontFamily: "Montserrat",
      color: "#324158",
    },
    h6: {
      fontSize: 14,
      fontFamily: "Montserrat",
      color: "#324158",
    },
    body1: {
      fontSize: 15,
      fontFamily: 'Helvetica'
    },
    body2: {
      fontSize: 13,
      fontFamily: 'Helvetica'
    },
    subtitle1: {
      fontSize: 16,
      fontFamily: 'Helvetica'
    },
    subtitle2: {
      fontSize: 14,
      fontFamily: 'Helvetica'
    },
    caption: {
      fontSize: 14
    },
    overline: {
      fontSize: 14
    },
    button: {
      fontSize: 13
    }
  },
  palette: {
    text: {
      primary: "#212529",
      secondary: "#353838"
    },
    primary: {
      main: "#8A5FFD",
      dark: "#572cba",
      light: "#5EE0E6"
    },
    secondary: {
      main: "#324158",
      dark: "#232d3d",
      light: "#5b6779"
    },

    error: { main: '#F44336' },
    warning: { main: '#ffc41c' },
    success: {
      main: '#447B3B',
      light: "#4CAF50",
      dark: "#1B5E20"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: "white",
          borderRadius: "20px",
          fontWeight: "bold",
        }
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontFamily: 'Helvetica',
          padding: "6px 8px",
        },
        head: {
          fontFamily: 'Helvetica',
          padding: "6px 8px",
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {

        /* Customize link (without underline) */
        a: {
          textDecoration: "none",
          fontWeight: 500,
          color: "#218eda"
        },

        body: {
          overflowX: "hidden"
        },

        "&::-webkit-scrollbar": {
          width: 5,
        },

        /* Customize the scroll bar track */
        '&::-webkit-scrollbar-track': {
          boxShadow: `inset 0 0 1px rgba(0, 0, 0, 0.4)`,
          borderRadius: '10px',
        },

        /* Customize the scroll bar thumb */
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: "darkgrey",
          outline: `1px solid darkgrey`,
          borderRadius: '10px',
        },

      }
    }
  }
});
