import { graphql } from '../generatedTS';

const GET_TEAM_REPORT_LIST = graphql(`
    query GetTeamReportList($idTeam: String!, $nextToken: String) {
        getTeamReportList(idTeam: $idTeam, nextToken: $nextToken) {
            nextToken
            reportList {
                ...ReportFull
            }
        }
    }
`);

const GET_USER_REPORT_LIST = graphql(`
    query GetUserReportList($idUser: String!, $idTeam: String!, $nextToken: String) {
        getUserReportList(idUser: $idUser, idTeam: $idTeam, nextToken: $nextToken) {
            nextToken
            reportList {
                ...ReportFull
            }
        }
    }
`);

const GET_REPORT_BY_ID = graphql(`
    query GetReportById($idReport: String!) {
        getReportById(idReport: $idReport) {
            ...ReportFull
        }
    }
`);

const REPORT_POSTPROCESS_FILE = graphql(`
    query GetReportPostprocessFile($idReport: String!, $idPostProcess: String!) {
        getReportPostprocessFile(idReport: $idReport, idPostProcess: $idPostProcess)
    }
`);

const GET_REPORT_WARNINGS = graphql(`
    query GetReportWarnings($idReport: String!) {
        getReportWarnings(idReport: $idReport) {
            ...ReportWarningFull
        }
    }
`);


export const ReportQueries = {
  GET_REPORT_BY_ID,
  REPORT_POSTPROCESS_FILE,
  GET_USER_REPORT_LIST,
  GET_TEAM_REPORT_LIST,
  GET_REPORT_WARNINGS
}
