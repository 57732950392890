import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import MandatoryAsterisk from '../../atom/MandatoryAsterisk/MandatoryAsterisk';
import { useMutation } from '@apollo/client';
import { TeamMutations } from '../../../utils/graphql/mutations/team';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import { AddUserToTeamInput } from '../../../utils/graphql/generatedTS/graphql';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';

/**
 *
 * @param {React.PropsWithChildren<IUserManagementAddUserDialog>} props
 * @return {JSX.Element}
 * @constructor
 */
const UserManagementAddUserDialog: React.FC<IUserManagementAddUserDialog> = (props) => {
  const {authToken} = useAuthContext();
  const {idTeam} = useParams();
  const [newUser, setNewUser] = useState<AddUserToTeamInput>({
    idTeam: idTeam as string,
    email: "",
    firstName: "",
    lastName: ""
  });
  const [firstnameError, setFirstnameError] = useState<string>("");
  const [lastnameError, setLastnameError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [paramsValid, setParamsValid] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  // Debounce email state
  const [] = useDebounce(
    () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!!newUser.email && !emailRegex.test(newUser.email)) setEmailError("Invalid Email");
    },
    1000,
    [newUser.email]
  );

  const [addUserMutation, {loading}] = useMutation(TeamMutations.ADD_USER_TO_TEAM, {
    context: generateClientContextWithHeaders(authToken)
  });

  // check if there's an error in the fields
  useEffect(() => {
    if (!newUser.idTeam) { setParamsValid(false); return; }
    if (!newUser.firstName) { setParamsValid(false); return; }
    if (!newUser.lastName) { setParamsValid(false); return; }
    if (!newUser.email) { setParamsValid(false); return; }
    setParamsValid(true);
  }, [newUser]);

  const setFirstname = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser({
      ...newUser,
      firstName: e.target.value
    });
  }, [setNewUser, newUser]);

  const setLastname = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser({
      ...newUser,
      lastName: e.target.value
    });
  }, [setNewUser, newUser]);

  const setEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError("");
    setNewUser({
      ...newUser,
      email: e.target.value
    });
  }, [setNewUser, newUser]);

  /**
   * Save the user calling the mutation
   */
  const save = useCallback(async () => {
    if(!idTeam) return;
    await addUserMutation({
      variables: {
         addUserToTeamInput: {
           idTeam: idTeam,
           email: newUser.email,
           lastName: newUser.lastName,
           firstName: newUser.firstName,
         }
      }
    });
    enqueueSnackbar("User has been added!", {
      variant: 'success'
    });
    props.onClose();
  }, [newUser, addUserMutation, idTeam, props]);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth={'sm'}>
      <DialogTitle>
        Add User
      </DialogTitle>
      <DialogContent>
        <Box display={"flex"} flexDirection={"column"}>
          <TextField label={!!newUser.firstName? <span>Name<MandatoryAsterisk/></span> : ""}
                     placeholder={"Name"}
                     variant="outlined"
                     size={"small"}
                     type={"text"}
                     sx={{mt: 2}}
                     onChange={setFirstname}
                     error={!!firstnameError}
                     helperText={firstnameError}
          />
          <TextField label={!!newUser.lastName? <span>Lastname<MandatoryAsterisk/></span> : ""}
                     placeholder={"Surname"}
                     variant="outlined"
                     size={"small"}
                     type={"text"}
                     sx={{mt: 2}}
                     onChange={setLastname}
                     error={!!lastnameError}
                     helperText={lastnameError}
          />
          <TextField label={!!newUser.email? <span>Email<MandatoryAsterisk/></span> : ""}
                     placeholder={"Email"}
                     variant="outlined"
                     size={"small"}
                     type={"email"}
                     sx={{mt: 2}}
                     onChange={setEmail}
                     error={!!emailError}
                     helperText={emailError}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {
          loading ?
            <LoadingButton loading variant="outlined">
              Save
            </LoadingButton>
            :
            <Button variant={"contained"} onClick={save} disabled={!paramsValid}>
              Save
            </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export interface IUserManagementAddUserDialog {
  open: boolean;
  onClose: () => void;
}

export default UserManagementAddUserDialog;
