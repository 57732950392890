import React, { useState } from 'react';
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { ExitToApp, MoreVertRounded, PeopleOutlineRounded } from '@mui/icons-material';
import { useAuthContext } from '../../../context/AuthContext';
import { useQuery } from '@apollo/client';
import { UserQueries } from '../../../utils/graphql/queries/user';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useNavigate } from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import { theme } from '../../../GlobalStyles';
import CommonSelectTeam from '../Common.SelectTeam/Common.SelectTeam';
import { useGetCurrentUserTeam } from '../../../hook/useGetCurrentUserTeam';

/**
 *
 * @param {React.PropsWithChildren<IUserSection>} props
 * @return {JSX.Element}
 * @constructor
 */
const UserSection: React.FC<IUserSection> = (props) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showSwitchTeam, setShowSwitchTeam] = useState<boolean>(false);
  const {authenticatedIdUser, authToken, deleteAuthToken} = useAuthContext();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const currentUserTeam = useGetCurrentUserTeam();

  const { data, loading } = useQuery(UserQueries.GET_USER_BY_ID, {
    variables: {
      idUser: authenticatedIdUser as string
    },
    context: generateClientContextWithHeaders(authToken),
    skip: !authenticatedIdUser,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Show the dialog to switch accounts
   */
  const switchAccount = () => {
    setShowSwitchTeam(true);
    handleClose();
  }

  const logout = () => {
    deleteAuthToken();
    handleClose();
    navigate(RouteKey.Home);
  }

  return (
    <Box display={"flex"} alignItems={"center"}>
      <img src={"/imgs/user-icon.svg"} height={30} alt={"User logo"}/>
      <Box display={"flex"} flexDirection={"column"} paddingX={2}>
        <Typography variant={"h5"} fontWeight={"bold"} color={"white"}>
          {data?.getUserById?.firstName} {data?.getUserById?.lastName}
        </Typography>
        <Typography variant={"h6"} color={"white"} sx={{textTransform: "capitalize"}}>
          {currentUserTeam?.role.toLowerCase()} | {currentUserTeam?.team?.name}
        </Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          dense: true,
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            borderRadius: '10px',
          },
        }}
      >
        <MenuItem onClick={switchAccount}>
          <ListItemIcon>
            <PeopleOutlineRounded fontSize={"small"}/>
          </ListItemIcon>
          <ListItemText>Switch account</ListItemText>
        </MenuItem>

        <MenuItem onClick={logout}>
          <ListItemIcon>
            <ExitToApp fontSize={"small"} onClick={logout} color={"error"}/>
          </ListItemIcon>
          <ListItemText sx={{color: theme.palette.error.main}}>Logout</ListItemText>
        </MenuItem>
      </Menu>
      <IconButton size={"small"} onClick={handleClick}>
        <MoreVertRounded sx={{color: "white"}}/>
      </IconButton>

      {
        !!authenticatedIdUser ?
          <CommonSelectTeam idUser={authenticatedIdUser} show={showSwitchTeam} onClose={() => setShowSwitchTeam(false)}/>
          :
          ""
      }
    </Box>
  );
};

export interface IUserSection {

}

export default UserSection;
