import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogContentText } from '@mui/material';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { ReportMutations } from '../../../utils/graphql/mutations/report';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import LoadingButton from '@mui/lab/LoadingButton';

/**
 *
 * @param {React.PropsWithChildren<IDeleteConfirmDialog>} props
 * @return {JSX.Element}
 * @constructor
 */
const DeleteConfirmDialog: React.FC<IDeleteConfirmDialog> = (props) => {

  const {authToken} = useAuthContext();

  const [deleteReportMutation, {data, loading}] = useMutation(ReportMutations.DELETE_REPORT, {
    context: generateClientContextWithHeaders(authToken),
    update(cache, { data }) {
      cache.evict({ id: cache.identify({ __typename: 'Report', idReport: props.idReport }) });
      cache.gc();
    }
  });

  const deleteReport = async () => {
    await deleteReportMutation({
      variables: {
        idReport: props.idReport
      }
    });
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the selected report?
        </DialogContentText>
        <Box display={"flex"} justifyContent={"center"} mt={3}>
          {
            loading ?
              <LoadingButton loading variant="contained">
                Delete
              </LoadingButton>
              :
              <Button variant={"contained"} color={"error"} startIcon={<DeleteOutlineOutlined/>} onClick={deleteReport}>
                Delete
              </Button>
          }
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export interface IDeleteConfirmDialog {
  idReport: string;
  open: boolean;
  onClose: () => void;
}

export default DeleteConfirmDialog;
