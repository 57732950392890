import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogContentText } from '@mui/material';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { TeamMutations } from '../../../utils/graphql/mutations/team';
import { TeamQueries } from '../../../utils/graphql/queries/team';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

/**
 *
 * @param {React.PropsWithChildren<IDeleteConfirmDialog>} props
 * @return {JSX.Element}
 * @constructor
 */
const DeleteConfirmDialog: React.FC<IDeleteConfirmDialog> = (props) => {

  const { idTeam } = useParams();
  const { authToken } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteUserMutation, {
    loading: loadingDeleteUser,
    error: errorDeleteUser
  }] = useMutation(TeamMutations.DELETE_USER_FROM_TEAM, {
    context: generateClientContextWithHeaders(authToken),
  });

  const [getTeamUserQuery, { loading: loadingGetTeamUsers }] = useLazyQuery(TeamQueries.GET_TEAM_USERS, {
    variables: {
      idTeam: idTeam as string
    },
    fetchPolicy: 'network-only',
    context: generateClientContextWithHeaders(authToken),
  });

  /**
   * Function to delete the user from the team
   */
  const deleteUser = async () => {
    if (!idTeam) return;
    try {
      await deleteUserMutation({
        variables: {
          idTeam: idTeam,
          idUser: props.idUser
        }
      });
      enqueueSnackbar(`${props.userEmail} removed from team`, {variant: 'success'});
      // force the refresh of the list
      await getTeamUserQuery({
        variables: {
          idTeam
        }
      });
    } catch (e) {
      enqueueSnackbar(`An error has occurred when removing ${props.userEmail} - please try again`, {variant: "error"});
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the selected user from the current team?
        </DialogContentText>
        <Box display={"flex"} justifyContent={"center"} mt={3}>
          {
            loadingDeleteUser || loadingGetTeamUsers ?
              <LoadingButton loading variant="contained">
                Delete
              </LoadingButton>
              :
              <Button variant={"contained"} color={"error"} startIcon={<DeleteOutlineOutlined/>} onClick={deleteUser}>
                Delete
              </Button>
          }
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export interface IDeleteConfirmDialog {
  idUser: string;
  userEmail: string;
  open: boolean;
  onClose: () => void;
}

export default DeleteConfirmDialog;
