/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    fragment ReportFull on Report {\n        userCreator {\n            idUser\n            lastName\n            firstName\n            email\n            isDisabled\n        }\n        targetList {\n            targetName\n            sex\n            ageBreak\n        }\n        status\n        spotList {\n            periodStart\n            periodEnd\n            id\n        }\n        filter {\n            broadcaster\n            channel\n            deviceType\n            onlineVideo\n        }\n        sharedWith {\n            email\n            firstName\n            lastName\n            idUser\n            isDisabled\n        }\n        scheduled {\n            timezone\n            runEverydayUntil\n            atHour\n        }\n        idReport\n        errorMessage\n        campaignName\n        createdAt\n        hasWarnings\n        engineUuid\n        updatedAt\n        drillDownBySpotgate\n    }\n": types.ReportFullFragmentDoc,
    "\n    fragment ReportWarningFull on ReportWarning {\n        description\n        code\n        idReportWarning\n    }\n": types.ReportWarningFullFragmentDoc,
    "\n    fragment TeamUserFull on TeamUser {\n        addedAt\n        role\n        email\n        firstName\n        lastName\n        idUser\n    }\n": types.TeamUserFullFragmentDoc,
    "\n    fragment UserFull on User {\n        createdAt\n        email\n        firstName\n        lastName\n        teams {\n            addedAt\n            idUser\n            team {\n                createdAt\n                idTeam\n                idUserAdmin\n                name\n                size\n            }\n            role\n        }\n        idUser\n        isDisabled\n    }\n": types.UserFullFragmentDoc,
    "\n    mutation AddReport($addReportInput: AddReportInput!) {\n        addReport(addReportInput: $addReportInput) {\n            ...ReportFull\n        }\n    }\n": types.AddReportDocument,
    "\n    mutation RerunReport($idReport: String!) {\n        rerunReport(idReport: $idReport) {\n            ...ReportFull\n        }\n    }\n": types.RerunReportDocument,
    "\n    mutation EditReport($editReportInput: EditReportInput!) {\n        editReport(editReportInput: $editReportInput) {\n            ...ReportFull\n        }\n    }\n": types.EditReportDocument,
    "\n    mutation ShareReport($idReport: String!, $idUser: String!) {\n        shareReport(idReport: $idReport, idUser: $idUser) {\n            ...ReportFull\n        }\n    }\n": types.ShareReportDocument,
    "\n    mutation DeleteShareReport($idReport: String!, $idUser: String!) {\n        deleteShareReport(idReport: $idReport, idUser: $idUser) {\n            ...ReportFull\n        }\n    }\n": types.DeleteShareReportDocument,
    "\n    mutation DeleteReport($idReport: String!) {\n        deleteReport(idReport: $idReport) \n    }\n": types.DeleteReportDocument,
    "\n    mutation DeleteReportSchedule($idReport: String!) {\n        deleteReportSchedule(idReport: $idReport) {\n            ...ReportFull\n        }\n    }\n": types.DeleteReportScheduleDocument,
    "\n    mutation AddUserToTeam($addUserToTeamInput: AddUserToTeamInput!) {\n        addUserToTeam(addUserToTeamInput: $addUserToTeamInput) {\n            ...TeamUserFull\n        }\n    }\n": types.AddUserToTeamDocument,
    "\n    mutation DeleteUserFromTeam($idTeam: String!, $idUser: String!) {\n        deleteUserFromTeam(idTeam: $idTeam, idUser: $idUser)\n    }\n": types.DeleteUserFromTeamDocument,
    "\n  mutation Login($loginInput: LoginInput!) {\n      login(loginInput: $loginInput) {\n          expiresIn\n          jwtToken\n          refreshToken\n          user {\n              ...UserFull\n          }\n    }\n  }\n": types.LoginDocument,
    "\n  mutation ResetUserPassword($email: String!) {\n      resetUserPassword(email: $email) \n  }\n": types.ResetUserPasswordDocument,
    "\n  mutation AddUserPassword($addUserPasswordInput: AddUserPasswordInput!) {\n      addUserPassword(addUserPasswordInput: $addUserPasswordInput)\n  }\n": types.AddUserPasswordDocument,
    "\n    query GetTeamReportList($idTeam: String!, $nextToken: String) {\n        getTeamReportList(idTeam: $idTeam, nextToken: $nextToken) {\n            nextToken\n            reportList {\n                ...ReportFull\n            }\n        }\n    }\n": types.GetTeamReportListDocument,
    "\n    query GetUserReportList($idUser: String!, $idTeam: String!, $nextToken: String) {\n        getUserReportList(idUser: $idUser, idTeam: $idTeam, nextToken: $nextToken) {\n            nextToken\n            reportList {\n                ...ReportFull\n            }\n        }\n    }\n": types.GetUserReportListDocument,
    "\n    query GetReportById($idReport: String!) {\n        getReportById(idReport: $idReport) {\n            ...ReportFull\n        }\n    }\n": types.GetReportByIdDocument,
    "\n    query GetReportPostprocessFile($idReport: String!, $idPostProcess: String!) {\n        getReportPostprocessFile(idReport: $idReport, idPostProcess: $idPostProcess)\n    }\n": types.GetReportPostprocessFileDocument,
    "\n    query GetReportWarnings($idReport: String!) {\n        getReportWarnings(idReport: $idReport) {\n            ...ReportWarningFull\n        }\n    }\n": types.GetReportWarningsDocument,
    "\n    query GetTeamUsers($idTeam: String!) {\n        getTeamUsers(idTeam: $idTeam) {\n            ...TeamUserFull\n        }\n    }\n": types.GetTeamUsersDocument,
    "\n    query GetUserById($idUser: String!) {\n        getUserById(idUser: $idUser) {\n            ...UserFull\n        }\n    }\n": types.GetUserByIdDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment ReportFull on Report {\n        userCreator {\n            idUser\n            lastName\n            firstName\n            email\n            isDisabled\n        }\n        targetList {\n            targetName\n            sex\n            ageBreak\n        }\n        status\n        spotList {\n            periodStart\n            periodEnd\n            id\n        }\n        filter {\n            broadcaster\n            channel\n            deviceType\n            onlineVideo\n        }\n        sharedWith {\n            email\n            firstName\n            lastName\n            idUser\n            isDisabled\n        }\n        scheduled {\n            timezone\n            runEverydayUntil\n            atHour\n        }\n        idReport\n        errorMessage\n        campaignName\n        createdAt\n        hasWarnings\n        engineUuid\n        updatedAt\n        drillDownBySpotgate\n    }\n"): (typeof documents)["\n    fragment ReportFull on Report {\n        userCreator {\n            idUser\n            lastName\n            firstName\n            email\n            isDisabled\n        }\n        targetList {\n            targetName\n            sex\n            ageBreak\n        }\n        status\n        spotList {\n            periodStart\n            periodEnd\n            id\n        }\n        filter {\n            broadcaster\n            channel\n            deviceType\n            onlineVideo\n        }\n        sharedWith {\n            email\n            firstName\n            lastName\n            idUser\n            isDisabled\n        }\n        scheduled {\n            timezone\n            runEverydayUntil\n            atHour\n        }\n        idReport\n        errorMessage\n        campaignName\n        createdAt\n        hasWarnings\n        engineUuid\n        updatedAt\n        drillDownBySpotgate\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment ReportWarningFull on ReportWarning {\n        description\n        code\n        idReportWarning\n    }\n"): (typeof documents)["\n    fragment ReportWarningFull on ReportWarning {\n        description\n        code\n        idReportWarning\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment TeamUserFull on TeamUser {\n        addedAt\n        role\n        email\n        firstName\n        lastName\n        idUser\n    }\n"): (typeof documents)["\n    fragment TeamUserFull on TeamUser {\n        addedAt\n        role\n        email\n        firstName\n        lastName\n        idUser\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment UserFull on User {\n        createdAt\n        email\n        firstName\n        lastName\n        teams {\n            addedAt\n            idUser\n            team {\n                createdAt\n                idTeam\n                idUserAdmin\n                name\n                size\n            }\n            role\n        }\n        idUser\n        isDisabled\n    }\n"): (typeof documents)["\n    fragment UserFull on User {\n        createdAt\n        email\n        firstName\n        lastName\n        teams {\n            addedAt\n            idUser\n            team {\n                createdAt\n                idTeam\n                idUserAdmin\n                name\n                size\n            }\n            role\n        }\n        idUser\n        isDisabled\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation AddReport($addReportInput: AddReportInput!) {\n        addReport(addReportInput: $addReportInput) {\n            ...ReportFull\n        }\n    }\n"): (typeof documents)["\n    mutation AddReport($addReportInput: AddReportInput!) {\n        addReport(addReportInput: $addReportInput) {\n            ...ReportFull\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation RerunReport($idReport: String!) {\n        rerunReport(idReport: $idReport) {\n            ...ReportFull\n        }\n    }\n"): (typeof documents)["\n    mutation RerunReport($idReport: String!) {\n        rerunReport(idReport: $idReport) {\n            ...ReportFull\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation EditReport($editReportInput: EditReportInput!) {\n        editReport(editReportInput: $editReportInput) {\n            ...ReportFull\n        }\n    }\n"): (typeof documents)["\n    mutation EditReport($editReportInput: EditReportInput!) {\n        editReport(editReportInput: $editReportInput) {\n            ...ReportFull\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ShareReport($idReport: String!, $idUser: String!) {\n        shareReport(idReport: $idReport, idUser: $idUser) {\n            ...ReportFull\n        }\n    }\n"): (typeof documents)["\n    mutation ShareReport($idReport: String!, $idUser: String!) {\n        shareReport(idReport: $idReport, idUser: $idUser) {\n            ...ReportFull\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DeleteShareReport($idReport: String!, $idUser: String!) {\n        deleteShareReport(idReport: $idReport, idUser: $idUser) {\n            ...ReportFull\n        }\n    }\n"): (typeof documents)["\n    mutation DeleteShareReport($idReport: String!, $idUser: String!) {\n        deleteShareReport(idReport: $idReport, idUser: $idUser) {\n            ...ReportFull\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DeleteReport($idReport: String!) {\n        deleteReport(idReport: $idReport) \n    }\n"): (typeof documents)["\n    mutation DeleteReport($idReport: String!) {\n        deleteReport(idReport: $idReport) \n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DeleteReportSchedule($idReport: String!) {\n        deleteReportSchedule(idReport: $idReport) {\n            ...ReportFull\n        }\n    }\n"): (typeof documents)["\n    mutation DeleteReportSchedule($idReport: String!) {\n        deleteReportSchedule(idReport: $idReport) {\n            ...ReportFull\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation AddUserToTeam($addUserToTeamInput: AddUserToTeamInput!) {\n        addUserToTeam(addUserToTeamInput: $addUserToTeamInput) {\n            ...TeamUserFull\n        }\n    }\n"): (typeof documents)["\n    mutation AddUserToTeam($addUserToTeamInput: AddUserToTeamInput!) {\n        addUserToTeam(addUserToTeamInput: $addUserToTeamInput) {\n            ...TeamUserFull\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DeleteUserFromTeam($idTeam: String!, $idUser: String!) {\n        deleteUserFromTeam(idTeam: $idTeam, idUser: $idUser)\n    }\n"): (typeof documents)["\n    mutation DeleteUserFromTeam($idTeam: String!, $idUser: String!) {\n        deleteUserFromTeam(idTeam: $idTeam, idUser: $idUser)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Login($loginInput: LoginInput!) {\n      login(loginInput: $loginInput) {\n          expiresIn\n          jwtToken\n          refreshToken\n          user {\n              ...UserFull\n          }\n    }\n  }\n"): (typeof documents)["\n  mutation Login($loginInput: LoginInput!) {\n      login(loginInput: $loginInput) {\n          expiresIn\n          jwtToken\n          refreshToken\n          user {\n              ...UserFull\n          }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ResetUserPassword($email: String!) {\n      resetUserPassword(email: $email) \n  }\n"): (typeof documents)["\n  mutation ResetUserPassword($email: String!) {\n      resetUserPassword(email: $email) \n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddUserPassword($addUserPasswordInput: AddUserPasswordInput!) {\n      addUserPassword(addUserPasswordInput: $addUserPasswordInput)\n  }\n"): (typeof documents)["\n  mutation AddUserPassword($addUserPasswordInput: AddUserPasswordInput!) {\n      addUserPassword(addUserPasswordInput: $addUserPasswordInput)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetTeamReportList($idTeam: String!, $nextToken: String) {\n        getTeamReportList(idTeam: $idTeam, nextToken: $nextToken) {\n            nextToken\n            reportList {\n                ...ReportFull\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetTeamReportList($idTeam: String!, $nextToken: String) {\n        getTeamReportList(idTeam: $idTeam, nextToken: $nextToken) {\n            nextToken\n            reportList {\n                ...ReportFull\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetUserReportList($idUser: String!, $idTeam: String!, $nextToken: String) {\n        getUserReportList(idUser: $idUser, idTeam: $idTeam, nextToken: $nextToken) {\n            nextToken\n            reportList {\n                ...ReportFull\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetUserReportList($idUser: String!, $idTeam: String!, $nextToken: String) {\n        getUserReportList(idUser: $idUser, idTeam: $idTeam, nextToken: $nextToken) {\n            nextToken\n            reportList {\n                ...ReportFull\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetReportById($idReport: String!) {\n        getReportById(idReport: $idReport) {\n            ...ReportFull\n        }\n    }\n"): (typeof documents)["\n    query GetReportById($idReport: String!) {\n        getReportById(idReport: $idReport) {\n            ...ReportFull\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetReportPostprocessFile($idReport: String!, $idPostProcess: String!) {\n        getReportPostprocessFile(idReport: $idReport, idPostProcess: $idPostProcess)\n    }\n"): (typeof documents)["\n    query GetReportPostprocessFile($idReport: String!, $idPostProcess: String!) {\n        getReportPostprocessFile(idReport: $idReport, idPostProcess: $idPostProcess)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetReportWarnings($idReport: String!) {\n        getReportWarnings(idReport: $idReport) {\n            ...ReportWarningFull\n        }\n    }\n"): (typeof documents)["\n    query GetReportWarnings($idReport: String!) {\n        getReportWarnings(idReport: $idReport) {\n            ...ReportWarningFull\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetTeamUsers($idTeam: String!) {\n        getTeamUsers(idTeam: $idTeam) {\n            ...TeamUserFull\n        }\n    }\n"): (typeof documents)["\n    query GetTeamUsers($idTeam: String!) {\n        getTeamUsers(idTeam: $idTeam) {\n            ...TeamUserFull\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetUserById($idUser: String!) {\n        getUserById(idUser: $idUser) {\n            ...UserFull\n        }\n    }\n"): (typeof documents)["\n    query GetUserById($idUser: String!) {\n        getUserById(idUser: $idUser) {\n            ...UserFull\n        }\n    }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;