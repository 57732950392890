import { styled } from "@mui/material/styles";
import Switch, { switchClasses } from "@mui/material/Switch";

export const SwitchTextTrack = styled(Switch)({
  width: 55,
  height: 35,
  padding: 8,
  [`& .${switchClasses.switchBase}`]: {
    padding: 10,
    color: "#fff", // White circle when off
  },
  [`& .${switchClasses.thumb}`]: {
    width: 15,
    height: 15,
    backgroundColor: "#fff",
  },
  [`& .${switchClasses.track}`]: {
    background: "#9e9e9e", // Grey background when off
    opacity: "1 !important",
    borderRadius: 20,
    position: "relative",
  },
  [`& .${switchClasses.checked}`]: {
    [`&.${switchClasses.switchBase}`]: {
      color: "#5EE0E6", // Color of the circle when on (using the end color of the gradient)
      transform: "translateX(19px)",
      "&:hover": {
        backgroundColor: "rgba(94,224,230,0.08)",
      },
    },
    [`& .${switchClasses.thumb}`]: {
      backgroundColor: "#fff",
    },
    [`& + .${switchClasses.track}`]: {
      background: "linear-gradient(to right, #8A5FFD, #5EE0E6)", // Gradient when on
    },
  },
});