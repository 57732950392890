import React from 'react';
import { Container } from '@mui/material';

/**
 * This is the auth section of the pages
 * @param {React.PropsWithChildren<ICommonAuthPagesBodyContainer>} props
 * @return {JSX.Element}
 * @constructor
 */
const CommonAuthPagesBodyContainer: React.FC<ICommonAuthPagesBodyContainer> = (props) => {
  return (
    <Container sx={{pt: 6, backgroundColor: "#F8F9FA", minHeight: "100vh"}}>
      {props.children}
    </Container>
  );
};

export interface ICommonAuthPagesBodyContainer {
  children: React.ReactNode | string
}

export default CommonAuthPagesBodyContainer;
