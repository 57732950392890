import React, { ReactElement } from "react";
import Home from './ui/page/home/Home';
import Dashboard from './ui/page/dashboard/Dashboard';
import AppWithAuth from './App.WithAuth';
import NewReport from './ui/page/newReport/NewReport';
import UserManagement from './ui/page/userManagement/UserManagement';
import ServiceStatus from './ui/page/serviceStatus/ServiceStatus';
import Report from './ui/page/report/Report';

export enum RouteKey {
  Home = "/",
  ResetPasswordRequest = "/reset-password-request",
  ChangePassword = "/change-password",
  Dashboard = "/team/:idTeam",
  NewReport = "/team/:idTeam/report",
  Report = "/team/:idTeam/report/:idReport",
  UserManagement = "/team/:idTeam/user-management",
  ServiceStatus = "/team/:idTeam/service-status"
}

export type RouteDetails = {
  key: RouteKey;
  path: RouteKey;
  component: ReactElement;
}

// list of all the routes of the App
export const routes: RouteDetails[] = [ {
  key: RouteKey.Home,
  path: RouteKey.Home,
  component: <Home/>,
}, {
  key: RouteKey.ResetPasswordRequest,
  path: RouteKey.ResetPasswordRequest,
  component: <Home/>,
}, {
  key: RouteKey.ChangePassword,
  path: RouteKey.ChangePassword,
  component: <Home/>,
}, {
  key: RouteKey.Dashboard,
  path: RouteKey.Dashboard,
  component: <AppWithAuth><Dashboard/></AppWithAuth>,
}, {
  key: RouteKey.NewReport,
  path: RouteKey.NewReport,
  component: <AppWithAuth><NewReport/></AppWithAuth>,
}, {
  key: RouteKey.Report,
  path: RouteKey.Report,
  component: <AppWithAuth><Report/></AppWithAuth>,
}, {
  key: RouteKey.UserManagement,
  path: RouteKey.UserManagement,
  component: <AppWithAuth><UserManagement/></AppWithAuth>,
},{
  key: RouteKey.ServiceStatus,
  path: RouteKey.ServiceStatus,
  component: <AppWithAuth><ServiceStatus/></AppWithAuth>,
}]
