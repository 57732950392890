import { graphql } from "../generatedTS";

const ADD_REPORT = graphql(`
    mutation AddReport($addReportInput: AddReportInput!) {
        addReport(addReportInput: $addReportInput) {
            ...ReportFull
        }
    }
`);

const RERUN_REPORT = graphql(`
    mutation RerunReport($idReport: String!) {
        rerunReport(idReport: $idReport) {
            ...ReportFull
        }
    }
`);

const EDIT_REPORT = graphql(`
    mutation EditReport($editReportInput: EditReportInput!) {
        editReport(editReportInput: $editReportInput) {
            ...ReportFull
        }
    }
`);

const SHARE_REPORT = graphql(`
    mutation ShareReport($idReport: String!, $idUser: String!) {
        shareReport(idReport: $idReport, idUser: $idUser) {
            ...ReportFull
        }
    }
`);

const DELETE_SHARE_REPORT = graphql(`
    mutation DeleteShareReport($idReport: String!, $idUser: String!) {
        deleteShareReport(idReport: $idReport, idUser: $idUser) {
            ...ReportFull
        }
    }
`);

const DELETE_REPORT = graphql(`
    mutation DeleteReport($idReport: String!) {
        deleteReport(idReport: $idReport) 
    }
`);

const DELETE_REPORT_SCHEDULE = graphql(`
    mutation DeleteReportSchedule($idReport: String!) {
        deleteReportSchedule(idReport: $idReport) {
            ...ReportFull
        }
    }
`);

export const ReportMutations = {
  ADD_REPORT,
  DELETE_REPORT,
  DELETE_REPORT_SCHEDULE,
  DELETE_SHARE_REPORT,
  EDIT_REPORT,
  RERUN_REPORT,
  SHARE_REPORT
}
