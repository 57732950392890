import { useQuery } from '@apollo/client';
import { ReportQueries } from '../utils/graphql/queries/report';
import { generateClientContextWithHeaders } from '../utils/graphql/graphQLsupportFunction';
import { Report, UserTeamRole } from '../utils/graphql/generatedTS/graphql';
import { useAuthContext } from '../context/AuthContext';
import { UseGetDashboardReportListParams, UseGetDashboardReportListReturn } from './useGetDashboardReportListType';
import { useGetCurrentUserTeam } from './useGetCurrentUserTeam';
import { useMemo, useState } from 'react';

const REPORT_TABLE_LENGTH = 10;

export const useGetDashboardReportList = ({idTeam}: UseGetDashboardReportListParams): UseGetDashboardReportListReturn => {

  const { authenticatedIdUser, authToken } = useAuthContext();
  const currentUserTeam = useGetCurrentUserTeam();

  const [startIndex, setStartIndex] = useState<number>(0);

  const isAdmin = currentUserTeam?.role === UserTeamRole.Admin;
  const isUser = currentUserTeam?.role === UserTeamRole.User;

  // if it's a regular user, read the list as a regular user
  const {
    data: reportListUser,
    loading: rluLoading,
    error: rluError,
    fetchMore: rluFetchMore
  } = useQuery(ReportQueries.GET_USER_REPORT_LIST, {
    variables: {
      idUser: authenticatedIdUser as string,
      idTeam: idTeam as string,
      nextToken: undefined
    },
    fetchPolicy: 'network-only',
    context: generateClientContextWithHeaders(authToken),
    skip: !authenticatedIdUser || !idTeam || !currentUserTeam || !currentUserTeam || currentUserTeam.role !== UserTeamRole.User,
    pollInterval: 5000,
  });

  // if it's an admin, read the list as an admin
  const {
    data: reportListTeam,
    loading: rltLoading,
    error: rltError ,
    fetchMore: rltFetchMore
  } = useQuery(ReportQueries.GET_TEAM_REPORT_LIST, {
    variables: {
      idTeam: idTeam as string,
      nextToken: undefined
    },
    fetchPolicy: 'network-only',
    context: generateClientContextWithHeaders(authToken),
    skip: !authenticatedIdUser || !idTeam || !currentUserTeam || !currentUserTeam || currentUserTeam.role !== UserTeamRole.Admin,
    pollInterval: 5000,
  });

  // console.log('reportListTeam', reportListTeam?.getTeamReportList);

  const reportList: Report[] = useMemo(() => {
    if (!reportListUser || !reportListUser.getUserReportList) {
      if (!reportListTeam || !reportListTeam.getTeamReportList) return [];
      else return reportListTeam.getTeamReportList.reportList as Report[]
    } else return reportListUser.getUserReportList.reportList as Report[]
  }, [reportListUser, reportListTeam, startIndex]);

  const loading = useMemo(() => {
    return rltLoading || rluLoading;
  }, [rltLoading, rluLoading]);

  const error = useMemo(() => {
    if (rluError) return rluError;
    if (rltError) return rltError;
    return undefined;
  }, [rluError, rltError]);

  const hasMoreToLoad = useMemo(() => {
    const hasNextToken = (isAdmin && !!reportListTeam?.getTeamReportList.nextToken) || (isUser && !!reportListUser?.getUserReportList.nextToken);
    if (startIndex < reportList.length - (reportList.length % REPORT_TABLE_LENGTH)) return true;
    else if (hasNextToken) return true;
    else return false;
  }, [startIndex, isAdmin, reportListTeam, isUser, reportListUser, reportList]);


  /**
   * Load the previous batch of reports
   */
  const loadPrevious = async () => {
    if (startIndex === 0) return;
    setStartIndex(startIndex - REPORT_TABLE_LENGTH);
  }

  /**
   * Load the next batch of reports
   */
  const loadNext = async () => {
    if (startIndex + REPORT_TABLE_LENGTH >= reportList.length) {
      const fetchMoreOptions = {
        variables: {
          nextToken: isAdmin ? reportListTeam?.getTeamReportList.nextToken : reportListUser?.getUserReportList.nextToken,
        }
      };
      if (isAdmin) {
        await rltFetchMore(fetchMoreOptions);
      } else {
        await rluFetchMore(fetchMoreOptions);
      }
    }
    setStartIndex(startIndex + REPORT_TABLE_LENGTH);
  }

  return {
    reportList: reportList.slice(startIndex, startIndex+REPORT_TABLE_LENGTH),
    error,
    loading,
    loadPrevious,
    loadNext,
    canLoadPrevious: startIndex>0,
    canLoadNext: hasMoreToLoad,
  };
}
