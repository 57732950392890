import { graphql } from "../generatedTS";

const ADD_USER_TO_TEAM = graphql(`
    mutation AddUserToTeam($addUserToTeamInput: AddUserToTeamInput!) {
        addUserToTeam(addUserToTeamInput: $addUserToTeamInput) {
            ...TeamUserFull
        }
    }
`);

const DELETE_USER_FROM_TEAM = graphql(`
    mutation DeleteUserFromTeam($idTeam: String!, $idUser: String!) {
        deleteUserFromTeam(idTeam: $idTeam, idUser: $idUser)
    }
`);

export const TeamMutations = {
  ADD_USER_TO_TEAM,
  DELETE_USER_FROM_TEAM
}
