import {
  MetricToNameInTabs,
  PlotNameForReportPage,
  TabStructureConfig,
  TabStructureForReportPage
} from './ReportTabsType';
import elcjImport from '../element_config.json';

/**
 * This file is responsible for generating and exporting the TAB_STRUCTURE constant.
 * It uses the imported 'element_config.json' to create the structure of the dashboard tabs.
 *
 * Enums are used for ease of use and maintenance in the TypeScript environment.
 * Remember to update corresponding Enums if new values are added to the 'element_config.json'.
 */

// Typecasts the imported JSON data to match the TabStructureConfig interface.
let elementConfigJson = elcjImport as TabStructureConfig;

// Remove element not to display in the webapp
elementConfigJson = Object.fromEntries(
    Object.entries(elementConfigJson).filter(([key, value]) => value.display)
);


// Enums for various properties used in the tab structure.
export enum TabName {
  TabR1BU = "tabr1bu",
  TabRF = "tabrf",
  TabR1 = "tabr1",
  TabContactsBU = "tabcontactsbu",
  TabContacts = "tabcontacts",
  TabSummary = "tabsummary",
}
export enum ReportViewType {
  Table = "table",
  Plot = "plot",
}
export enum ReportPlotType {
  BarStacked = "bar_stacked",
  Bar = "bar",
  Line = "line",
}
export enum Kpi {
  Contact = "contact",
  Contactcum = "contactcum",
  ContactDaily = "contactdaily",
  ContactReach = "contactreach",
  Reach = "reach",
  Reach1Plus = "reach1plus",
  ReachFrequency = "reachfrequency",
}
export enum Aggregation {
  Sexage  = "sexage",
  Target = "target",
}
export enum Metric1 {
  Abs = "abs",
  Trp = "trp",
  Perc = "perc"
}
export enum Metric2 {
  Raw = "raw",
  _30Eq = "30eq",
}

// Function to map a string value to its corresponding Enum type.
const mapStringToEnum = (value: string, enumType: any): any => {
  const enumKey = Object.keys(enumType).find(key => enumType[key] === value);
  return enumType[enumKey as keyof typeof enumType];
};

// Function to check if a subView is unique in the array of subViews.
const isSubViewUnique = (subViews: any[], newSubView: any): boolean => {
  return !subViews.some(subView =>
    subView.aggregation === newSubView.aggregation &&
    subView.kpi === newSubView.kpi &&
    subView.type === newSubView.type &&
    subView.plotType === newSubView.plotType
  );
};

// Function to create the tab structure from the JSON configuration.
const createTabStructureFromJson = (jsonConfig: TabStructureConfig): TabStructureForReportPage => {
  const tabStructure: any = {};

  // Iterates over element in the JSON configuration to construct the tab structure.
  for (const el of Object.values(jsonConfig)) {
    // Destructures the key to extract relevant properties.
    const [standard, tab, viewType, kpi, aggregation, metric1, metric2] = el.python_element.split('_');

    // Maps the extracted properties to their respective Enums.
    const tabEnum: TabName = mapStringToEnum(tab.toLowerCase(), TabName);
    const viewTypeEnum = mapStringToEnum(viewType, ReportViewType);
    const kpiEnum = mapStringToEnum(kpi, Kpi);
    const aggregationEnum = mapStringToEnum(aggregation, Aggregation);
    const metric1Enum = metric1 ? mapStringToEnum(metric1, Metric1) : null;
    const metric2Enum = metric2 ? mapStringToEnum(metric2, Metric2) : null;

    const tabKeyName = tabEnum.replace('tab', '').toUpperCase();
    if (!tabStructure[tabKeyName]) {
      tabStructure[tabKeyName] = {
        name: tabEnum,
        metric1: [],
        metric2: [],
        subViews: []
      };
    }

    // Checks and adds unique metric1 and metric2 values to the tab structure.
    if (metric1Enum && !tabStructure[tabKeyName].metric1.includes(metric1Enum)) {
      tabStructure[tabKeyName].metric1.push(metric1Enum);
    }
    if (metric2Enum && !tabStructure[tabKeyName].metric2.includes(metric2Enum)) {
      tabStructure[tabKeyName].metric2.push(metric2Enum);
    }

    // Constructs a new subView object and adds it to the tab structure if unique.
    const newSubView = {
      aggregation: aggregationEnum,
      kpi: kpiEnum,
      type: viewTypeEnum,
      ...(el.plot_type ? { plotType: mapStringToEnum(el.plot_type, ReportPlotType) } : {})
    };

    if (isSubViewUnique(tabStructure[tabKeyName].subViews, newSubView)) {
      tabStructure[tabKeyName].subViews.push(newSubView);
    }
  }
  return tabStructure;
};

// Function to extract axes and plot name values from the JSON configuration.
const createPlotTitleAndAxesFromJson = (jsonConfig: TabStructureConfig): PlotNameForReportPage => {
  const plotTitles: PlotNameForReportPage = {};
  // Iterates over element in the JSON configuration to construct the tab structure.
  for (const el of Object.values(jsonConfig)) {
    // add details (title, and eventually also axes), add to the PLOT_TITLES constant
    plotTitles[el.python_element] = {
      title: el.title,
      axis_x: el.axis_x,
      axis_y: el.axis_y
    }
  }
  return plotTitles;
};

// Exports the generated tab structure for use in other parts of the application.
export const TAB_STRUCTURE: TabStructureForReportPage = createTabStructureFromJson(elementConfigJson);

export let PLOT_TITLES: PlotNameForReportPage = createPlotTitleAndAxesFromJson(elementConfigJson);

// make sure to store the name to show instead of Enum values in the selection buttons on top of a chart page
export const METRIC_TO_NAME: MetricToNameInTabs = {
  [TabName.TabSummary]: {
    abs: "(000)",
    perc: "TRPs/%"
  },
  [TabName.TabContacts]: {
    raw: "Contacts",
    abs: "Contacts (000)",
    trp: "TRPs",
    '30eq': "30” eq. contacts"
  },
  [TabName.TabContactsBU]: {
    abs: "Contacts (000)",
    trp: "TRPs",
  },
  [TabName.TabR1]: {
    abs: "Reach (000)",
    perc: "Reach (%)"
  },
  [TabName.TabRF]: {
    abs: "Reach (000)",
    perc: "Reach (%)"
  },
  [TabName.TabR1BU]: {
    abs: "Reach (000)",
    perc: "Reach (%)"
  }
}




